import { Link as MaterialLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../utils/theme';

const useStyles = makeStyles({
  linkStyle: {
    color: theme.colors.primary,
    cursor: 'pointer',
  },
});

export default function Link(props: any) {
  const classes = useStyles();

  return (
    <MaterialLink  onClick={props.onClick} className={classes.linkStyle}>{props.children}</MaterialLink>
  );
}
