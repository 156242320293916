/** @format */

import { RackContentStrategy, ColumnContent } from './RackContentStrategy'
import { PipeItemDto } from '../../models/dto/PipeItemDto'
import { buildRackIdColumn } from './columnBuilder/buildRackIdColumn'
import { TFunction } from 'react-i18next'
import { RackState } from '../../stores/RackStore'
import { UserState } from '../../stores/UserStore'
import { buildNonSpecificColumn } from './columnBuilder/buildNonSpecificColumn'
import { buildMetricColumn } from './columnBuilder/weldingLabColumn/buildMetricColumn'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'

export class WeldingLabRackContentStrategy implements RackContentStrategy {
	private readonly columnNames = [
		'rackId',
		'marker',
		'identificationId',
		'businessUnit',
		'project',
		'manufacturer',
		'outerDiameter',
		'outerDiameterInch',
		'internalDiameter',
		'wallThickness',
		'wallThicknessClad',
		'genericGrade',
		'pipeCount',
		'length',
		'jointNumber'
	]
	private readonly dataType = 'serimax'

	formatSubtitle() {
		return 'WTC stock'
	}

	formatDataType() {
		return 'serimax-data'
	}

	redirectionUrl(pipeId: string): string {
		return '/pipe/' + pipeId
	}

	getNumberOfItemsInRack(itemDataList: PipeItemDto[]): number {
		let sum: number = 0
		itemDataList.forEach(pipeItem => (sum += pipeItem.jointNumber != null && pipeItem.jointNumber !== '' ? 0.5 : 1))
		return sum
	}

	getPipeCount(jointNumber?: string): number {
		return jointNumber != null && jointNumber !== '' ? 1 / 2 : 1
	}

	createPipeDetail(rackState: RackState, pipeId: string): RackItemInTableModel[] {
		// Add the selected pipe
		let pipeDetails = rackState.rackData.filter(pipe => pipeId === pipe.identificationId)
		// Add the pipe in the welding
		const jointNumber = pipeDetails[0].jointNumber
		if (jointNumber) {
			pipeDetails = pipeDetails.concat(
				rackState!.rackData.filter(pipe => pipeId !== pipe.identificationId && jointNumber === pipe.jointNumber)
			)
		}
		return pipeDetails
	}

	isTail(): boolean {
		return false
	}

	isVam(): boolean {
		return false
	}

	getColumnNames(): string[] {
		return this.columnNames
	}

	buildColumns(
		t: TFunction<'translation'>,
		rackState: RackState,
		userState: UserState,
		onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
		chooseUnitLengthMmtoInch: string,
		headerBackgroundColor: () => string
	): ColumnContent[] {
		return this.columnNames.map(columnName => {
			if (columnName === 'rackId') {
				return buildRackIdColumn(this.dataType, t, rackState, onFilterChange)
			} else if (['internalDiameter', 'wallThickness', 'wallThicknessClad', 'length'].includes(columnName)) {
				return buildMetricColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					userState,
					onFilterChange,
					chooseUnitLengthMmtoInch
				)
			}
			return buildNonSpecificColumn(this.dataType, columnName, t, rackState, onFilterChange)
		})
	}
}
