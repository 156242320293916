/** @format */

import axios from 'axios'
import Cookies from 'js-cookie'
import BusinessErrorException from '../models/exception/BusinessErrorException'
import { config } from '../Properties'

export const instance = axios.create({
	baseURL: config.url.FIELD_APP_BACKEND_URL + 'api/',
	headers: {
		'Content-Type': 'application/json'
	}
})

export const getParamWithHeader = (params: any) => {
	return {
		headers: {
			Authorization: 'Bearer ' + Cookies.get('token')
		},
		params
	}
}

export const getHeader = () => {
	return {
		headers: {
			Authorization: 'Bearer ' + Cookies.get('token')
		}
	}
}

export const getSocketHeader = () => {
	return {
		Authorization: 'Bearer ' + Cookies.get('token')
	}
}

export const isSuccessfulResponse = (response: any) => response && response.data.resultCode === 'OK'

export const isCreationOrUpdateOK = (response: any) =>
	response &&
	(response.data.resultCode === 'insert.certificate.ok' ||
		response.data.resultCode === 'insert.certificate.for.sons.ok' ||
		response.data.resultCode === 'insert.document.for.sons.ok' ||
		response.data.resultCode === 'update.document.for.sons.ok' ||
		response.data.resultCode === 'update.certificate.ok' ||
		response.data.resultCode === 'insert.digital.log.sheet.ok' ||
		response.data.resultCode === 'update.digital.log.sheet.ok' ||
		response.data.resultCode === 'insert.digital.log.sheet.ok.for.sons' ||
		response.data.resultCode === 'update.digital.log.sheet.ok.for.sons' ||
		response.data.resultCode === 'insert.quality.certificate.ok' ||
		response.data.resultCode === 'update.quality.certificate.ok' ||
		response.data.resultCode === 'insert.quality.certificate.for.sons.ok' ||
		response.data.resultCode === 'update.quality.certificate.for.sons.ok' ||
		response.data.resultCode === 'insert.packing.list.ok' ||
		response.data.resultCode === 'update.packing.list.ok' ||
		response.data.resultCode === 'insert.packing.list.for.sons.ok' ||
		response.data.resultCode === 'update.packing.list.for.sons.ok' ||
		response.data.resultCode === 'insert.pipe.ends.measurement.report.ok' ||
		response.data.resultCode === 'update.pipe.ends.measurement.report.ok' ||
		response.data.resultCode === 'insert.pipe.ends.measurement.report.for.sons.ok' ||
		response.data.resultCode === 'update.pipe.ends.measurement.report.for.sons.ok' ||
		response.data.resultCode === 'insert.data.ok')

export const manageResponse = (response: any) => {
	if (isSuccessfulResponse(response)) {
		return response.data.body ? response?.data?.body : response.data
	} else {
		throw new BusinessErrorException(response.data.resultCode, response.data.extraData)
	}
}

export const manageUploadResponse = (response: any) => {
	if (isCreationOrUpdateOK(response)) {
		return response.data
	} else {
		console.log(response.data)
		throw new BusinessErrorException(response.data.resultCode, response.data.extraData)
	}
}
