/** @format */

import { PageDetailStrategy, ColumnContent } from './PageDetailStrategy'
import { TFunction } from 'react-i18next'
import { Action, UserState } from '../../stores/UserStore'
import { buildNonSpecificColumn } from './columnBuilder/buildNonSpecificColumn'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import DocumentService from '../../api/DocumentService'
import DimensionalCheckService from '../../api/DimensionalCheckService'
import CertificateService from '../../api/CertificateService'
import { FilesDownloadable } from '../../models/FilesDownloadable'
import { buildFilesDownloadColumn } from './columnBuilder/buildFilesDownloadColumn'
import { downloadPipeChecking, downloadDimensionalCheckReport, downloadCertificate } from '../DocumentBusiness'
import { buildVamLengthColumn } from './columnBuilder/vamColumn/buildVamLengthColumn'
import { GenericProductDto } from '../../models/dto/GenericProductDto'
import PipeDetailsCardInfo from '../../containers/common/PipeDetailsCardInfo'
import DoFactory from '../../models/DoFactory'

export class WeldingLabPageDetailStrategy implements PageDetailStrategy {
	private readonly columnNames = [
		'marker',
		'identificationId',
		'businessUnit',
		'project',
		'manufacturer',
		'prematerial',
		'heatNumber',
		'length',
		'jointNumber'
	]
	private readonly dataType = 'serimax'

	private downloadDocument = (
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		type: string,
		pipeDetails?: RackItemInTableModel
	) => {
		if (type === 'PIPE_CHECKING') {
			downloadPipeChecking(setPageLoading, pipeDetails?.identificationId, type, userDispatch, t)
		} else if (type === 'DimensionalCheckReport' && pipeDetails) {
			downloadDimensionalCheckReport(setPageLoading, pipeDetails, userDispatch, t)
		} else {
			downloadCertificate(setPageLoading, pipeDetails?.identificationId, userDispatch, t)
		}
	}

	private checkDownloadable = (
		setDownloadableState: (value: React.SetStateAction<undefined>) => void,
		pipeId: any,
		typeCheck: string,
		typeDoc?: string
	) => {
		if (pipeId !== undefined) {
			if (typeCheck === 'checkDocument' && typeDoc) {
				DocumentService.checkDocumentExist(pipeId, typeDoc).then(data => {
					setDownloadableState(data.currentValue)
				})
			} else if (typeCheck === 'checkDimensionalDocument') {
				DimensionalCheckService.checkDimensionalDocumentExist(pipeId).then(data => {
					setDownloadableState(data.currentValue)
				})
			} else {
				CertificateService.checkCertificateExist(pipeId).then(data => {
					setDownloadableState(data.currentValue)
				})
			}
		}
	}

	checkDownloadableLeft(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'checkDocument', 'PIPE_CHECKING')
	}

	checkDownloadableCenter(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'checkDimensionalDocument')
	}

	checkDownloadableRight(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'checkCertificate')
	}

	formatSubtitle() {
		return 'pipe_data'
	}

	formatDataType() {
		return 'serimax-data'
	}

	getCardInfo(pipeDetails: RackItemInTableModel): JSX.Element {
		return <PipeDetailsCardInfo pipe={pipeDetails} />
	}

	getConvertFromGenericProductoDto(
		genericProduct: GenericProductDto,
		identificationId?: string
	): RackItemInTableModel {
		return DoFactory.convertFromGenericProductoDtoToRackItemInTableModel(genericProduct, identificationId!)
	}

	isTail(): boolean {
		return false
	}

	isVam(): boolean {
		return false
	}

	getFilesDownloadColumns(
		className: string,
		pipeDetails: RackItemInTableModel,
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		downloadable1: any,
		downloadable2: any,
		downloadable3?: any
	): FilesDownloadable[] {
		return [
			buildFilesDownloadColumn(
				'download_pipe_checking',
				'downloadPipeChecking',
				downloadable1,
				className,
				'downloadPipeChecking',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'PIPE_CHECKING'
			),
			buildFilesDownloadColumn(
				'download_dimensional_check',
				'downloadDimensionalCheck',
				downloadable2,
				className,
				'downloadDimensionalCheck',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'DimensionalCheckReport'
			),
			buildFilesDownloadColumn(
				'Download Certificate',
				'downloadCertificate',
				downloadable3,
				className,
				'downloadCertificat',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'Certificate'
			)
		]
	}

	buildColumns(
		t: TFunction<'translation'>,
		userState: UserState,
		chooseUnitLengthMmtoFt?: string,
		chooseUnitLengthMmtoInch?: string,
		chooseUnitWeight?: string
	): ColumnContent[] {
		return this.columnNames.map(columnName => {
			if (columnName === 'length') {
				return buildVamLengthColumn(this.dataType, t, userState, chooseUnitLengthMmtoInch)
			}

			return buildNonSpecificColumn(this.dataType, columnName, t)
		})
	}
}
