/** @format */

export class TableUtils {
	static isTableFiltered = (table: any): boolean => {
		for (const row of table) {
			if (row['numberFilteredPipes']) {
				return true
			}
		}
		return false
	}

	static isInRack = (searchedText: string, child: any, isLbs: boolean = false, isInchOrFeet: boolean = false) => {
		for (const [key, value] of Object.entries(child)) {
			let formattedValue: any = null
			if(typeof value === 'string') {
				formattedValue = value
			} else if (typeof value === 'number') {
				formattedValue = key === 'weightLbs' ? Math.round(value) : value.toFixed(2)
			}
			if ((typeof formattedValue === 'string' ? formattedValue : String(formattedValue))?.includes(searchedText)) {
				switch (key) {
					case 'internalDiameterInch': 
						if(isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'length':
						if(!isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'lengthFeet':
						if(isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'outerDiameter':
						if(!isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'outerDiameterInch':
						if(isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'wallThickness':
						if(!isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'wallThicknessInch':
						if(isInchOrFeet) {
							return true
						} else {
							continue
						}
					case 'weight':
						if(!isLbs) {
							return true
						} else {
							continue
						}
					case 'weightLbs':
						if(isLbs) {
							return true
						} else {
							continue
						}
					default:
						return true
				}
			}
		}
		return false
	}
}
