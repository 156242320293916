/** @format */

import {
	FormControlLabel,
	IconButton,
	makeStyles,
	Popper,
	Radio,
	RadioGroup,
	Switch,
	ClickAwayListener,
	Paper
} from '@material-ui/core'
import FrenchFlag from '../../assets/icons/french-flag.svg'
import UsaFlag from '../../assets/icons/us-flag.svg'
import Settings from '@material-ui/icons/Settings'
import { useTranslation } from 'react-i18next'
import theme from '../../utils/theme'
import i18next from 'i18next'
import i18n from '../../i18n'
import Cookies from 'js-cookie'
import { useCallback, useContext, useState } from 'react'
import { UserContext } from '../../stores/UserStore'
import { AppContext } from '../../stores/AppStore'
import React from 'react'

const useStyles = makeStyles({
	flag: {
		margin: '0px 0px 0px 12px',
		height: '24px',
		width: '30px',
		borderRadius: '6px'
	},
	button: {
		display: 'block'
	},
	verticallyCentered: {
		display: 'flex',
		alignItems: 'center'
	},
	noBorder: {
		border: 'none'
	},
	label: {
		marginLeft: '10px',
		marginTop: '5px'
	},
	popover: {
		borderTop: '4px solid ' + theme.colors.darkBlue,
		width: '299px',
		padding: '32px 15px 23px 15px'
	},
	title: {
		textTransform: 'uppercase',
		color: theme.colors.darkBlue,
		fontWeight: 'bold',
		display: 'flex',
		alignItems: 'center',
		marginBottom: '7px',
		'&::after': {
			backgroundColor: theme.colors.lightGray,
			content: '""',
			flex: '1',
			height: '2px',
			margin: '0 1em'
		}
	},
	root: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		'& .MuiSwitch-track': {
			backgroundColor: theme.colors.gray
		},
		'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
			backgroundColor: theme.colors.gray
		}
	},
	divSwitch: {
		marginBottom: '32px'
	},
	radioButton: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiRadio-colorSecondary.Mui-checked': {
			color: theme.colors.darkBlue
		}
	},
	switchActive: {
		fontWeight: 'bold'
	},
	switchDesactive: {
		color: theme.colors.gray
	}
})

const label = { inputProps: { 'aria-label': 'change-length' } }

const SettingsHeader = (props: any) => {
	const classes = useStyles(props)
	const { t } = useTranslation()
	const { userDispatch } = useContext(UserContext)
	const { appState, appDispatch } = useContext(AppContext)
	const [toggleOpen, setToggleOpen] = React.useState<boolean>(false)

	const anchorRef = React.useRef<HTMLButtonElement>(null)

	const languages = [
		{
			value: 'en',
			flag: <img className={classes.flag} id={'us_flag'} src={UsaFlag} alt={t('American')} />,
			label: t('American')
		},
		{
			value: 'fr',
			flag: <img className={classes.flag} id={'french_flag'} src={FrenchFlag} alt={t('French')} />,
			label: t('French')
		}
	]

	const [language, setCountry] = useState(i18next.languages[0])

	const [checkedLength, setCheckedLength] = useState(false)
	const [checkedWeight, setCheckedWeight] = useState(false)

	const setPageLoading = useCallback(
		(pageLoading: boolean) => {
			appDispatch({ type: 'set_page_loading', pageLoading })
		},
		[appDispatch]
	)

	const handleChange = (event: any) => {
		i18n.changeLanguage(event.target.value)
		Cookies.set('language', event.target.value)
		setCountry(event.target.value)
	}

	const handleClose = () => {
		setToggleOpen(false)
	}

	const handleOpen = () => {
		setToggleOpen(true)
	}

	const handleChangeSwitchLength = (event: any) => {
		setPageLoading(true)
		setCheckedLength(event.target.checked)
		setTimeout(() => {
			userDispatch({ type: 'set_checked_length', checkedLength: event.target.checked })
			setPageLoading(false)
		})
	}

	const handleChangeSwitchWeight = (event: any) => {
		setPageLoading(true)
		setCheckedWeight(event.target.checked)
		setTimeout(() => {
			userDispatch({ type: 'set_checked_weight', checkedWeight: event.target.checked })
			setPageLoading(false)
		})
	}

	return (
		<div>
			<IconButton
				className={classes.button}
				size="medium"
				aria-label="display more actions"
				onClick={handleOpen}
				id="settings-icon"
				ref={anchorRef}
			>
				<Settings style={{ color: theme.colors.darkBlue }} />
			</IconButton>
			<Popper
				open={toggleOpen}
				id="settings"
				className={classes.verticallyCentered}
				anchorEl={anchorRef.current}
				transition
				role={undefined}
				style={{ zIndex: 99 }}
			>
				<Paper className={classes.popover}>
					<ClickAwayListener onClickAway={handleClose}>
						<div className={classes.root}>
							<div id="settings-length" className={classes.divSwitch}>
								<div className={classes.title}>{t('length')}</div>
								<div
									style={{ display: 'inline-block' }}
									className={checkedLength ? classes.switchDesactive : classes.switchActive}
								>
									{props.userStateLayout !== 'OCTG' ? (
										<div>
											{' '}
											{t('metric_mm.name')} <i>{t('metric_mm.unit')}</i>
										</div>
									) : (
										<div>
											{' '}
											{t('imperial_inch.name')} <i>{t('imperial_inch.unit')}</i>
										</div>
									)}
								</div>
								<Switch
									{...label}
									checked={checkedLength}
									style={{ color: theme.colors.blue }}
									onChange={handleChangeSwitchLength}
									value={checkedLength}
									id="switch-length"
									disabled={appState.pageLoading}
								/>
								<div
									style={{ display: 'inline-block' }}
									className={checkedLength ? classes.switchActive : classes.switchDesactive}
								>
									{props.userStateLayout !== 'OCTG' ? (
										<div>
											{' '}
											{t('imperial_inch.name')} <i>{t('imperial_inch.unit')}</i>
										</div>
									) : (
										<div>
											{t('metric_mm.name')} <i>{t('metric_mm.unit')}</i>
										</div>
									)}
								</div>
							</div>

							{(props.userStateLayout === 'YELLOWTAIL_ADMIN' || props.userStateLayout === 'WHIPTAIL') && (
								<div id="settings-weight" className={classes.divSwitch}>
									<div className={classes.title}>{t('weight')}</div>
									<div
										style={{ display: 'contents' }}
										className={checkedWeight ? classes.switchDesactive : classes.switchActive}
									>
										{t('metric_kg.name')} <i>{t('metric_kg.unit')}</i>
									</div>
									<Switch
										{...label}
										checked={checkedWeight}
										style={{ color: theme.colors.blue }}
										onChange={handleChangeSwitchWeight}
										id="switch-weight"
										disabled={appState.pageLoading}
									/>
									<div
										style={{ display: 'contents' }}
										className={checkedWeight ? classes.switchActive : classes.switchDesactive}
									>
										{t('imperial_lb.name')} <i>{t('imperial_lb.unit')}</i>
									</div>
								</div>
							)}
							<div id="settings-language">
								<div className={classes.title}>{t('language')}</div>
								{props.userStateLayout === 'OCTG' ? (
									<RadioGroup
										aria-labelledby="demo-controlled-radio-buttons-group"
										name={`controlled-radio-buttons-group-${0}`}
										value={language}
										color="primary"
										onChange={handleChange}
									>
										<div className={classes.radioButton}>
											<FormControlLabel
												id={`settings-langage-${languages[0].value}`}
												value={languages[0].value}
												control={<Radio />}
												label=""
												style={{ margin: '0' }}
											/>
											{languages[0].flag}
											<div style={{ display: 'flex', marginLeft: '8px' }}>{languages[0].label}</div>
										</div>
									</RadioGroup>
								) : (
									languages.map((option, key) => (
										<RadioGroup
											aria-labelledby="demo-controlled-radio-buttons-group"
											name={`controlled-radio-buttons-group-${key}`}
											value={language}
											color="primary"
											onChange={handleChange}
											key={option.value}
										>
											<div className={classes.radioButton}>
												<FormControlLabel
													id={`settings-language-${option.value}`}
													value={option.value}
													control={<Radio />}
													label=""
													style={{ margin: '0' }}
												/>
												{option.flag}
												<div style={{ display: 'flex', marginLeft: '8px' }}>{option.label}</div>
											</div>
										</RadioGroup>
									))
								)}
							</div>
						</div>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</div>
	)
}
export default SettingsHeader
