/** @format */

import React, { FunctionComponent } from 'react'
import { ClickAwayListener, makeStyles, MenuItem, MenuList, Paper, Popper, IconButton } from '@material-ui/core'
import theme from '../utils/theme'
import { useTranslation } from 'react-i18next'
import { RackItemInTableModel } from '../models/RackItemInTableModel'
import { exportDataTOExternalFile } from '../utils/exportDataTOExternalFile'

const useStyles = makeStyles(t => ({
	button: {
		color: theme.colors.gray,
		textTransform: 'none'
	},
	iconButton: {
		letterSpacing: '0.03px',
		color: theme.colors.white,
		background: 'transparent linear-gradient(99deg, #009EDE 0%, #1A2981 100%) 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		fontSize: '16px',
		padding: '10px 24px',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		position: 'absolute',
		right: 300,
		top: 150,
		width: '150px',
		zIndex: 1,
		fontWeight: 'bold'
	}
}))

type Props = {
	columns: any[]
	filtredData: RackItemInTableModel[]
	allData: RackItemInTableModel[]
	columnNames: string[]
	username: string
}

const ExportView: FunctionComponent<Props> = ({ columns, filtredData, allData, columnNames, username }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	const [toggleOpen, setToggleOpen] = React.useState<boolean>(false)
	const anchorRef = React.useRef<HTMLButtonElement>(null)

	const handleOpen = () => {
		setToggleOpen(true)
	}

	const handleClose = () => {
		setToggleOpen(false)
	}

	const onOptionClick = (onClick: any) => () => {
		handleClose()
		onClick()
	}
	return (
		<>
			<IconButton className={classes.iconButton} onClick={handleOpen} ref={anchorRef} id="export-icon">
				<div style={{ color: theme.colors.white, display: 'contents' }}>Export</div>
			</IconButton>
			<Popper open={toggleOpen} anchorEl={anchorRef.current} transition disablePortal style={{ zIndex: 99 }}>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<MenuList id="fade-menu" autoFocusItem={toggleOpen}>
							<MenuItem
								onClick={onOptionClick(() => exportDataTOExternalFile(allData, columns, 'xls'))}
								key="ExcelALL"
								id="ExcelALL"
							>
								{t('Excel All')}
							</MenuItem>
							<MenuItem
								onClick={onOptionClick(() => exportDataTOExternalFile(filtredData, columns, 'xls'))}
								key="ExcelFiltred"
							>
								{t('Excel Filtred')}
							</MenuItem>
							<MenuItem
								onClick={onOptionClick(() => exportDataTOExternalFile(allData, columns, 'csv'))}
								key="csvlALL"
							>
								{t('csv All')}
							</MenuItem>
							<MenuItem
								onClick={onOptionClick(() => exportDataTOExternalFile(filtredData, columns, 'csv'))}
								key="csvFiltred"
							>
								{t('csv Filtred')}
							</MenuItem>
							<MenuItem
								onClick={onOptionClick(() =>
									exportDataTOExternalFile(filtredData, columns, 'pdf', columnNames, username)
								)}
								key="PDF"
							>
								{t('pdf')}
							</MenuItem>
						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	)
}

export default ExportView
