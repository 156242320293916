import React, { ChangeEvent } from 'react';
import PropTypes from 'prop-types';
import { CheckboxProps, FormControlLabel, Checkbox as MaterialUICheckbox } from '@material-ui/core';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import theme from '../../utils/theme';

const ColoredCheckbox = withStyles({
  root: {
    width: 'fit-content',
    pointerEvents: 'none',
    margin: '16px 0px',
    display: 'block',
    textAlign: 'left',
    userSelect: 'none',
    cursor: 'pointer',
    '& span:nth-child(1)': {
      height: '25px',
      width: '25px',
      '& svg': {
        display: 'none',
      },

      paddingLeft: '25px',
      fontSize: theme.fontSizes.medium,
      fontWeight: 'normal',
      display: 'block',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      position: 'relative',
      '&:before': {
        content: '""',
        width: '25px',
        height: '25px',
        display: 'block',
        borderRadius: '20%',
        left: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        background: theme.colors.lightGray,
        transition:
          'background .2s ease, transform .4s cubic-bezier(.175, .885, .32, 1.4)',
      },
      '&:after': {
        content: '""',
        width: '25px',
        height: '25px',
        display: 'block',
        borderRadius: '20%',
        left: 0,
        top: 0,
        bottom: 0,
        position: 'absolute',
        background: '#fff',
        transform: 'scale(.86)',
        transition: 'transform .6s cubic-bezier(.175, .885, .32, 1.1)',
      },
    },
  },
  checked: {
    '& span:nth-child(1)': {
      '&:before': {
        transform: 'scale(1.04)',
        background: theme.colors.primary,
        color: theme.colors.primary,
      },
      '&:after': {
        transform: 'scale(0)',
        transition: 'transform 0.3s ease',
      },
    },
  },
})((props: CheckboxProps) => <MaterialUICheckbox color='default' {...props} />);

const useStyles = makeStyles({
  checkBoxParent: {
    float: 'left',
  },
});

const Checkbox = (param: any) => {
  const handleHTMLEventChange = (event: ChangeEvent<HTMLInputElement>) => {
    param.onChange && param.onChange(event.target.checked);
  };

  const classes = useStyles();

  return (
    <FormControlLabel
      className={classes.checkBoxParent}
      control={
        <ColoredCheckbox
          checked={param.checked}
          onChange={handleHTMLEventChange}
        />
      }
      label={param.children}
    />
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func,
  children: PropTypes.string,
};

export default React.memo(Checkbox);
