import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import { UserContext } from '../../stores/UserStore';

type PrivateRouteProps = {
  path: string;
  exact?: boolean;
  component: any;
};

const PrivateRoute = (props: PrivateRouteProps) => {
  const { userState } = useContext(UserContext);
  let isAuthenticated = userState.authState === 'SIGNED_IN';

  if (isAuthenticated) {
    return (
      <Route
        {...props}
        render={(rendererParams) => <props.component {...rendererParams} />}
      />
    );
  } else {
    return <Redirect to='/' />;
  }
};

export default PrivateRoute;
