import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import theme from '../../utils/theme';

type InputProps = {
  heigth?: string;
  width?: string;
  padding?: string;
  margin?: string;
  extraSmall?: boolean;
  small?: boolean;
  large?: boolean;
  extraLarge?: boolean;
  error?: boolean | string;
  success?: boolean;
  inline?: boolean;
  label?: string;
};

const useStyles = makeStyles({
  input: (props: InputProps) => ({
    height: props.heigth ? props.heigth : 'auto',
    width: props.width ? props.width : 'auto',
    margin: props.margin ? props.margin : '8px 0px',
    color: theme.colors.darkGray,
    fontWeight: 'bold',
    fontSize: props.extraLarge
      ? theme.fontSizes.big
      : props.large
      ? theme.fontSizes.medium
      : props.small
      ? theme.fontSizes.small
      : props.extraSmall
      ? theme.fontSizes.tiny
      : theme.fontSizes.medium,
    borderRadius: theme.borderRadius.card,
    '& label.Mui-focused': {
      color: theme.colors.gray,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.colors.primary,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.colors.gray,
      },
      '&:hover fieldset': {
        borderColor: theme.colors.primary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.colors.primary,
      },
    },
  }),
});

const Input = (props: any) => {
  const classes = useStyles(props);

  return (
    <TextField
      className={classes.input}
      label={props.placeholder}
      type={props.type}
      onChange={props.onChange}
      onBlur={props.onBlur}
      variant='outlined'
      style={props.style}
      error={props.error}
      value={props.value}
      required={props.required}
    >
      {props.children}
    </TextField>
  );
};

export default Input;
