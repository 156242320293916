import { Card as MaterialCard, makeStyles } from '@material-ui/core';
import theme from '../../utils/theme';


type CardProps = {
  borderRadius?: string
  padding?: string
  width?: string
  maxWidth?: string
  margin?: string
  justifyContent?: string
  alignItems?: string
  boxShadow?: string
  color?: string
  background?: string
}

const useStyles = makeStyles({
  card: (props: CardProps) => ({
    width: props.width ? props.width : '100%',
    maxWidth: props.maxWidth ? props.maxWidth : '100%',
    padding: props.padding ? props.padding : '',
    alignItems: props.alignItems ? props.alignItems : 'center',
    margin: props.margin ? props.margin : '0',
    justifyContent: props.justifyContent ? props.justifyContent : 'center',
    boxShadow: props.boxShadow ? props.boxShadow : theme.boxShadows.medium,
    borderRadius: props.borderRadius ? props.borderRadius : theme.borderRadius.card,
    color: props.color ? props.color : theme.colors.darkGray,
    background: props.background ? props.background : theme.colors.white,
  })
});

const Card = (props: any) => {
  const classes = useStyles(props);

  return (
    <MaterialCard className={classes.card} >
      {props.children}
    </MaterialCard>
  );

}

export default Card