/** @format */

import {
	AppBar,
	Box,
	Button,
	ClickAwayListener,
	Grow,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	SvgIcon,
	Toolbar,
	Typography
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Logo } from '../../../assets/images/logo-small.svg'
import { ReactComponent as Stock } from '../../../assets/icons/stock.svg'
import { ReactComponent as Upload } from '../../../assets/icons/upload.svg'
import theme from '../../../utils/theme'
import UserOptions from './UserOptions'
import { UserContext } from '../../../stores/UserStore'
import { useHistory } from 'react-router-dom'
import { CloudDownload } from '@material-ui/icons'
import SettingsHeader from '../../common/SettingsHeader'

const useStyles = makeStyles({
	appBar: {
		background: theme.colors.white,
		height: 80,
		boxShadow: '0px 2px 4px -1px rgba(0,0,0,0.2)'
	},
	title: {
		color: theme.colors.darkBlue,
		fontSize: theme.fontSizes.big,
		fontWeight: 'bold'
	},
	subtitle: {
		color: theme.colors.blue,
		fontSize: theme.fontSizes.big,
		fontWeight: 'bold',
		marginLeft: '10px'
	},
	logoStock: {
		height: '26px',
		minWidth: '26px',
		margin: '0px 12px 0px 12px'
	},
	logoUpload: {
		height: '26px',
		minWidth: '26px',
		margin: '0px 12px 0px 12px'
	},
	flag: {
		margin: '0px 12px 0px 12px',
		height: '42px',
		width: '42px'
	},
	buttonIcon: {
		margin: '32px 29px',
		padding: '0px',
		minWidth: 35,
		minHeight: 35,
		backgroundColor: 'rgba(0, 0, 0, 0)',
		'&:hover': {
			backgroundColor: 'rgba(0, 0, 0, 0)'
		}
	},
	toolBar: {
		paddingLeft: '0px'
	}
})

type HeaderProps = {
	title?: string
	subtitle?: string
	username?: string
	settings?: Array<OptionProps>
}

type OptionProps = {
	key?: string
	label?: string
	onClick?: () => void
}

const Header = (props: HeaderProps) => {
	const classes = useStyles()

	const { t } = useTranslation()
	let history = useHistory()
	const { userState } = useContext(UserContext)
	const [open, setOpen] = React.useState(false)
	const anchorRef = React.useRef<HTMLButtonElement>(null)

	const handleToggle = () => {
		setOpen(prevOpen => !prevOpen)
	}

	const handleClose = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return
		}
		setOpen(false)
	}

	const handleUploadCertificateClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/upload-certificate')
		handleClose(event)
	}

	const handleWTCStockClick = (event: React.MouseEvent<EventTarget>) => {
		const redirectRouteToGo =
			userState.user.layout === 'VAM_licensees'
				? '/rack-content-vam'
				: userState.user.layout === 'YELLOWTAIL_ADMIN'
				? '/rack-content-uaru'
				: userState.user.layout === 'WHIPTAIL'
				? '/rack-content-whiptail'
				: '/rack-content'
		history.push(redirectRouteToGo)
		handleClose(event)
	}

	const handleVamDataClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/rack-content-vam')
		handleClose(event)
	}

	const handleYellowtailDataClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/rack-content-uaru')
		handleClose(event)
	}

	const handleWhiptailDataClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/rack-content-whiptail')
		handleClose(event)
	}

	const handleUploadDocumentClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/upload-document')
		handleClose(event)
	}

	const handleJointDataClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/octg-content')
		handleClose(event)
	}

	const handleDownloadValidClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/download-valid')
		handleClose(event)
	}

	const handleUploadJointDataClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/upload-joint-data')
		handleClose(event)
	}

	const handleSlabsDataClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/slabs-content')
		handleClose(event)
	}

	const handleUploadPackingListClick = (event: React.MouseEvent<EventTarget>) => {
		history.push('/upload-packing-list')
		handleClose(event)
	}

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === 'Tab') {
			event.preventDefault()
			setOpen(false)
		}
	}

	return (
		<AppBar position="static" className={classes.appBar}>
			<Toolbar className={classes.toolBar}>
				<Box display="flex" alignItems="center" flexGrow={1}>
					{
						<Button
							className={classes.buttonIcon}
							ref={anchorRef}
							aria-controls={open ? 'menu-list-grow' : undefined}
							aria-haspopup="true"
							onClick={handleToggle}
						>
							<MenuIcon color="action" fontSize="large" />
						</Button>
					}
					<Popper
						open={open}
						anchorEl={anchorRef.current}
						role={undefined}
						transition
						disablePortal
						style={{ zIndex: 99 }}
					>
						{({ TransitionProps, placement }) => (
							<Grow
								{...TransitionProps}
								style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
							>
								<Paper>
									<ClickAwayListener onClickAway={handleClose}>
										<MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
											{userState.user.layout !== 'VAM_licensees' &&
												userState.user.layout !== 'YELLOWTAIL_ADMIN' &&
												userState.user.layout !== 'WHIPTAIL' &&
												!userState.user.layout.includes('OCTG') &&
												!userState.user.layout.includes('SLABS') && (
													<>
														<MenuItem onClick={handleWTCStockClick}>
															<Stock color="action" className={classes.logoStock} />
															{t('WTC stock')}
														</MenuItem>
														{userState.user.layout !== 'YELLOWTAIL_ADMIN' &&
															userState.user.layout !== 'WHIPTAIL' && (
																<MenuItem onClick={handleUploadCertificateClick}>
																	<Upload color="action" className={classes.logoUpload} />
																	{t('Load certificate')}
																</MenuItem>
															)}
													</>
												)}
											{userState.user.layout === 'VAM_licensees' && (
												<>
													<MenuItem onClick={handleVamDataClick}>
														<Stock color="action" className={classes.logoStock} />
														{t('vam_data')}
													</MenuItem>
													<MenuItem onClick={handleUploadDocumentClick}>
														<Upload color="action" className={classes.logoUpload} />
														{t('upload_document')}
													</MenuItem>
												</>
											)}
											{userState.user.layout === 'YELLOWTAIL_ADMIN' && (
												<>
													<MenuItem onClick={handleYellowtailDataClick}>
														<Stock color="action" className={classes.logoStock} />
														{t('yellowtail_data')}
													</MenuItem>
													<MenuItem onClick={handleUploadDocumentClick}>
														<Upload color="action" className={classes.logoUpload} />
														{t('upload_document')}
													</MenuItem>
												</>
											)}
											{userState.user.layout === 'WHIPTAIL' && (
												<>
													<MenuItem onClick={handleWhiptailDataClick}>
														<Stock color="action" className={classes.logoStock} />
														{t('whiptail_data')}
													</MenuItem>
													<MenuItem onClick={handleUploadDocumentClick}>
														<Upload color="action" className={classes.logoUpload} />
														{t('upload_document')}
													</MenuItem>
												</>
											)}
											{userState.user.layout.includes('OCTG') && (
												<>
													<MenuItem onClick={handleUploadJointDataClick}>
														<Upload color="action" className={classes.logoUpload} />
														{t('upload_joint_data')}
													</MenuItem>
													<MenuItem onClick={handleDownloadValidClick}>
														<CloudDownload color="action" className={classes.logoUpload} />
														{t('generate_batch_valid')}
													</MenuItem>
													<MenuItem onClick={handleJointDataClick}>
														<Stock color="action" className={classes.logoStock} />
														{t('view_joint_data')}
													</MenuItem>
												</>
											)}
											{userState.user.layout.includes('SLABS') && (
												<>
													<MenuItem onClick={handleUploadPackingListClick}>
														<Upload color="action" className={classes.logoUpload} />
														{t('upload_packing_list')}
													</MenuItem>
													<MenuItem onClick={handleSlabsDataClick}>
														<Stock color="action" className={classes.logoStock} />
														{t('view_slabs_data')}
													</MenuItem>
												</>
											)}
										</MenuList>
									</ClickAwayListener>
								</Paper>
							</Grow>
						)}
					</Popper>
					)
					<SvgIcon component={Logo} viewBox="0 0 67 174" className={classes.logoStock} />
					<Typography className={classes.title}>{props.title}</Typography>
				</Box>
				{userState.user.layout !== 'SLABS' && <SettingsHeader userStateLayout={userState.user.layout} />}
				{props.settings && <UserOptions username={props.username} settings={props.settings} />}
			</Toolbar>
		</AppBar>
	)
}

export default Header
