/** @format */

export class RackItemInTableModel {
	id: string = ''
	rackId?: string
	pipeCount: number = 0
	numberFilteredPipes: number = 0
	vsbId?: string
	identificationId?: string
	ippn?: string
	vsbOrder?: string
	ytCustomerItem?: string
	length?: number
	outerDiameter?: number
	outerDiameterInch?: number
	internalDiameter?: number
	internalDiameterInch?: number
	wallThickness?: number
	wallThicknessClad?: number
	wallThicknessInch?: number
	genericGrade?: string
	lengthFeet?: number
	weight?: number
	weightLbs?: number
	marker?: string
	businessUnit?: string
	prematerial?: string
	manufacturer?: string
	project?: string
	jointNumber?: string
	parentId?: string
	heatNumber?: string
	isDeletable: boolean = true
	pipeEndLastUpdateDate?: string
	serialNumber?: string
	accessoryType?: string
	client?: string
	workOrder?: string
	salesOrder?: string
	end1OuterDiameter?: number
	end1weight?: number
	end1ConnectionType?: string
	end1SurfaceTreatment?: string
	end1SteelGrade?: string
	end2OuterDiameter?: number
	end2weight?: number
	end2ConnectionType?: string
	end2SurfaceTreatment?: string
	end2SteelGrade?: string
	accessoryCount?: number = 0
	tableData?: {
		childRows: RackItemInTableModel[]
	}
}
