/** @format */

import { cloneDeep } from 'lodash'
import React, { useReducer, Dispatch } from 'react'
import { VamRackItemInTableModel } from '../models/VamRackItemInTableModel'
import { FilterUtils } from '../utils/FilterUtils'

type State = {
	rackData: VamRackItemInTableModel[]
	displayedPipeData: VamRackItemInTableModel[]
	selectedRacks: string[]
	pageNumberMap: Map<string, number>
	pageSizeMap: Map<string, number>
	expandSide: boolean
	pipeId: string
	accessoryDetails: VamRackItemInTableModel[]
	filteredColumnsMap: Map<string, any>
	vamRackColumns: any[]
	checkedLength: boolean
	searchedText: string
	openedRacks: string[]
	closedRacks: string[]
}

type Action =
	| { type: 'set_rack_data'; rackData: VamRackItemInTableModel[] }
	| { type: 'set_selected_racks'; selectedRacks: string[] }
	| { type: 'set_page_number'; pageNumber: number; reference: string }
	| { type: 'set_page_size'; pageSize: number; reference: string }
	| { type: 'set_expand_side'; expandSide: boolean }
	| { type: 'init_rack_properties' }
	| { type: 'set_pipe_id'; pipeId: string }
	| { type: 'set_accessory_details'; accessoryDetails: VamRackItemInTableModel[] }
	| {
			type: 'set_filtered_columns_map'
			filteredColumnsMap: Map<string, any>
			checkedLength: boolean
			checkedWeight: boolean
			columnsConvert: Map<string, any>
	  }
	| {
			type: 'remove_filter_from_columns_map'
			nameOfColumns: any
			checkedLength: boolean
			checkedWeight: boolean
			columnsConvert: Map<string, any>
	  }
	| { type: 'remove_all_filters' }
	| { type: 'set_vamRack_columns'; vamRackColumns: any[] }
	| { type: 'set_checked_length'; checkedLength: boolean }
	| { type: 'set_searched_text'; searchedText: string }
	| { type: 'set_opened_racks'; racks: string[] }
	| { type: 'set_closed_racks'; racks: string[] }
	| { type: 'log_out' }

const initialState: State = {
	rackData: [],
	displayedPipeData: [],
	selectedRacks: [],
	pageNumberMap: new Map<string, number>(),
	pageSizeMap: new Map<string, number>(),
	expandSide: false,
	pipeId: '',
	accessoryDetails: [],
	filteredColumnsMap: new Map(),
	vamRackColumns: [],
	checkedLength: false,
	searchedText: '',
	openedRacks: [],
	closedRacks: []
}

export const RackContext = React.createContext<{
	vamRackState: State
	vamRackDispatch: Dispatch<Action>
}>({
	vamRackState: initialState,
	vamRackDispatch: () => null
})

const reducer = (state: State, action: Action): State => {
	let filteredDataToDisplay: any

	switch (action.type) {
		case 'set_rack_data':
			const vamRackDataWithExpand = action.rackData.map((rack: any) => ({
				...rack,
				tableData: { ...rack.tableData, isTreeExpanded: state.selectedRacks.includes(rack.id) }
			}))
			return {
				...state,
				rackData: vamRackDataWithExpand,
				displayedPipeData: vamRackDataWithExpand
			}
		case 'set_selected_racks':
			localStorage.setItem('selected_racks', JSON.stringify(action.selectedRacks))
			state.selectedRacks = action.selectedRacks
			// Only return the state, but do not reload it
			return state
		case 'set_page_number':
			state.pageNumberMap.set(action.reference, action.pageNumber)
			localStorage.setItem('page_number', JSON.stringify(Object.fromEntries(state.pageNumberMap)))
			return {
				...state
			}
		case 'set_page_size':
			state.pageSizeMap.set(action.reference, action.pageSize)
			localStorage.setItem('page_size', JSON.stringify(Object.fromEntries(state.pageSizeMap)))
			return {
				...state
			}
		case 'set_expand_side':
			return {
				...state,
				expandSide: action.expandSide
			}
		case 'init_rack_properties':
			return {
				...state,
				selectedRacks: localStorage.getItem('selected_racks')
					? JSON.parse(localStorage.getItem('selected_racks')!)
					: [],
				pageNumberMap: localStorage.getItem('page_number')
					? new Map(Object.entries(JSON.parse(localStorage.getItem('page_number')!)))
					: new Map(),
				pageSizeMap: localStorage.getItem('page_size')
					? new Map(Object.entries(JSON.parse(localStorage.getItem('page_size')!)))
					: new Map()
			}
		case 'set_pipe_id':
			return {
				...state,
				pipeId: action.pipeId
			}
		case 'set_accessory_details':
			return {
				...state,
				accessoryDetails: action.accessoryDetails
			}
		case 'set_checked_length':
			return {
				...state,
				checkedLength: action.checkedLength
			}
		case 'set_filtered_columns_map':
			// apply all filtered to a buffer
			filteredDataToDisplay = updateFiltersForDisplayed(
				action.filteredColumnsMap,
				cloneDeep(state.rackData),
				action.checkedLength,
				action.checkedWeight,
				action.columnsConvert
			)
			filteredDataToDisplay.forEach((row: any) => {
				if (row.rackId) {
					filteredDataToDisplay = filteredDataToDisplay.concat(row.tableData?.childRows)
				}
			})
			return {
				...state,
				closedRacks: [],
				displayedPipeData: filteredDataToDisplay,
				filteredColumnsMap: action.filteredColumnsMap
			}
		case 'remove_filter_from_columns_map':
			const filteredColumnsMap = cloneDeep(state.filteredColumnsMap)
			filteredColumnsMap.delete(action.nameOfColumns)

			filteredDataToDisplay = updateFiltersForDisplayed(
				filteredColumnsMap,
				cloneDeep(state.rackData),
				action.checkedLength,
				action.checkedWeight,
				action.columnsConvert
			)
			return {
				...state,
				displayedPipeData: filteredDataToDisplay,
				filteredColumnsMap: filteredColumnsMap
			}
		case 'remove_all_filters':
			return {
				...state,
				displayedPipeData: state.rackData,
				filteredColumnsMap: new Map()
			}
		case 'set_vamRack_columns':
			return {
				...state,
				vamRackColumns: action.vamRackColumns
			}
		case 'set_searched_text':
			return {
				...state,
				searchedText: action.searchedText
			}
		case 'set_opened_racks':
			return {
				...state,
				openedRacks: action.racks
			}
		case 'set_closed_racks':
			return {
				...state,
				closedRacks: action.racks
			}
		case 'log_out':
			return initialState
	}
}

const updateFiltersForDisplayed = (
	filteredColumnMap: Map<string, any>,
	dataToDisplay: any[],
	checkedLength: boolean,
	checkedWeight: boolean,
	columnsConvert: Map<string, any>
) => {
	updateFilters(filteredColumnMap, dataToDisplay, checkedLength, checkedWeight, columnsConvert)
	filteredColumnMap.forEach((value: any, key: string) => {
		dataToDisplay = applyFilter(
			dataToDisplay,
			value.value,
			value.min,
			value.max,
			value.selectedValues,
			key,
			checkedLength,
			checkedWeight,
			columnsConvert
		)
	})
	return dataToDisplay
}

const updateFilters = (
	filteredColumnMap: Map<string, any>,
	pipesToDisplay: any[],
	checkedLength: boolean,
	checkedWeight: boolean,
	columnsConvert: Map<string, any>
) => {
	if (filteredColumnMap !== undefined) {
		FilterUtils.updateFilterWithConvert(filteredColumnMap, pipesToDisplay, checkedLength, checkedWeight, columnsConvert)
	}
}

const applyFilter = (
	tab: any,
	value: any,
	min: any,
	max: any,
	selectedValues: any,
	key: string,
	checkedLength: boolean,
	checkedWeight: boolean,
	columnsConvert: Map<string, any>
) => {
	tab.forEach((row: any) => {
		row['tableData']['childRows'] = tab.filter((child: any) => row.id === child.parentId)
	})
	return FilterUtils.tableFilter(
		tab,
		key,
		selectedValues,
		value,
		checkedLength,
		checkedWeight,
		min,
		max,
		columnsConvert
	)
}

export const VamRackProvider = (props: any) => {
	const [vamRackState, vamRackDispatch] = useReducer(reducer, initialState)

	return <RackContext.Provider value={{ vamRackState, vamRackDispatch }}>{props.children}</RackContext.Provider>
}
