/** @format */

import {
	createStyles,
	Icon,
	InputAdornment,
	makeStyles,
	Paper,
	Radio,
	TablePagination,
	TextField,
	Typography
} from '@material-ui/core'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { CloseRounded, SearchRounded } from '@material-ui/icons'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import ChevronRight from '@material-ui/icons/ChevronRight'
import Clear from '@material-ui/icons/Clear'
import FilterList from '@material-ui/icons/FilterList'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import Search from '@material-ui/icons/Search'
import MaterialTable, { Icons, MTableHeader } from 'material-table'
import { forwardRef, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import expandLeft from '../../assets/icons/expand_left.svg'
import expandRight from '../../assets/icons/expand_right.svg'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import { RackContext } from '../../stores/RackStore'
import { UserContext } from '../../stores/UserStore'
import { TableUtils } from '../../utils/TableUtils'
import theme from '../../utils/theme'
import ExportView from '../export-view'

const useStyles = makeStyles(() =>
	createStyles({
		'@global': {
			'*::-webkit-scrollbar': {
				height: '15px !important',
				scrollbarWidth: 'auto'
			}
		},
		root: {
			'& .MuiTable-root': {
				borderCollapse: 'inherit',
				padding: '0px 16px 0px 20px'
			},
			'& .MuiTableHead-root .MuiTableCell-root span div': {
				paddingLeft: '24px'
			},
			'& .MuiIconButton-root': {
				color: 'inherit'
			},
			'& .MuiTableCell-body ': {
				color: 'inherit'
			},
			'& .MuiToolbar-gutters': {
				paddingRight: '15px',
				paddingLeft: '0px'
			},
			'& .MuiInputBase-root': {
				color: 'inherit'
			},
			'& div.MuiFormControl-root': {
				// Display the filter cell with a 100% width
				width: '100%'
			},
			'& div.MuiFormControl-root:nth-child(3)': {
				background: '#F8F9FA 0% 0% no-repeat padding-box',
				borderRadius: '4px',
				border: '1px solid #CCCCCC',
				width: '200px',
				height: '36px',
				color: '#7F7F7F',
				// Center the "Search" text and icon of the top right search field
				display: 'grid'
			},
			'& div.MuiFormControl-root:nth-child(3) .MuiInput-underline::before': {
				// Do not display the bottom line of the top right search field
				borderBottom: '1px solid transparent'
			},
			'& div.MuiFormControl-root:nth-child(3) .MuiInput-underline::after': {
				// Do not display the bottom line of the top right search field
				borderBottom: '1px solid transparent'
			},
			'& .Component-horizontalScrollContainer-305': {
				padding: '0px 16px 0px 20px'
			},
			'& div.MuiFormControl-root:nth-child(3):hover': {
				border: '1px solid #666666'
			},
			'& .MuiIconButton-colorPrimary:hover': {
				backgroundColor: 'rgba(0, 0, 0, 0)'
			}
		},
		sideHeader: {
			padding: '4px',
			fontSize: '1rem',
			borderTop: 'solid 1px lightgrey',
			borderLeft: 'solid 1px lightgrey',
			borderRight: 'solid 1px lightgrey'
		},
		backgroundExtended: {
			background: '#F2FAFD',
			backgroundClip: 'padding-box'
		},
		noBackground: {
			background: '#FFFFFF',
			backgroundClip: 'padding-box'
		},
		vertical_align: {
			verticalAlign: 'middle'
		},
		expand_btn: {
			cursor: 'pointer',
			float: 'right'
		},
		collapse_btn: {
			cursor: 'pointer',
			float: 'left'
		},
		grey: {
			filter: 'invert(67%) sepia(57%) saturate(0%) hue-rotate(189deg) brightness(103%) contrast(109%)'
		},
		green: {
			filter: 'invert(53%) sepia(19%) saturate(1266%) hue-rotate(100deg) brightness(96%) contrast(87%)'
		},
		red: {
			filter: 'invert(41%) sepia(62%) saturate(804%) hue-rotate(316deg) brightness(96%) contrast(97%)'
		},
		title: {
			padding: '8px 0px 8px 11px',
			borderLeftWidth: '8px',
			borderLeftStyle: 'solid',
			borderLeftColor: theme.colors.darkBlue,
			textAlign: 'left',
			color: theme.colors.darkBlue,
			margin: '21px 0px',
			fontWeight: 'bold'
		},
		subtitle: {
			padding: '8px 0px 8px 11px',
			textAlign: 'left',
			color: theme.colors.darkBlue,
			margin: '21px 0px',
			fontWeight: 'bold'
		},
		div: {
			paddingTop: '80px'
		},
		tableHeader: {
			padding: '0px 16px 0px 20px'
		},
		tablePagination: {
			overflowX: 'hidden'
		},
		iconButton: {
			letterSpacing: '0.03px',
			color: theme.colors.white,
			background: 'transparent linear-gradient(99deg, #009EDE 0%, #1A2981 100%) 0% 0% no-repeat padding-box',
			borderRadius: '4px',
			fontSize: '16px',
			padding: '10px 24px',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			position: 'absolute',
			right: 300,
			top: 180,
			width: '150px',
			zIndex: 1,
			fontWeight: 'bold'
		}
	})
)

const PatchedPagination = (props: any) => {
	const { t } = useTranslation()
	const { ActionsComponent, onChangePage, onCustomChangePage, onChangeRowsPerPage, ...tablePaginationProps } = props
	const classes = useStyles()

	return props.displayPagination ? (
		<TablePagination
			{...tablePaginationProps}
			classes={{
				root: classes.tablePagination
			}}
			onPageChange={(event, page) => {
				onChangePage(event, page)
				onCustomChangePage(page)
			}}
			onRowsPerPageChange={onChangeRowsPerPage}
			labelDisplayedRows={({ from, to, count }) =>
				t('numberOfLines', { from, to, count, context: props.kindOfObjects })
			}
			ActionsComponent={subprops => {
				const { onPageChange, ...actionsComponentProps } = subprops
				return <ActionsComponent {...actionsComponentProps} onChangePage={onPageChange} />
			}}
		/>
	) : (
		// Return an empty div if displayPagination is false
		<div />
	)
}

const tableIcons: Icons = {
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />)
}

const cellStyle = {
	whiteSpace: 'pre-wrap'
}

interface TitleTableString {
	title: string
	subtitle: string
}

const TitleTable = (props: TitleTableString) => {
	const classes = useStyles()
	const { title, subtitle } = props
	return (
		<div>
			<Typography component={'div'} className={classes.title}>
				{title}
			</Typography>
			<Typography component={'div'} className={classes.subtitle}>
				{subtitle}
			</Typography>
		</div>
	)
}

const FilterableTable = (props: any) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { rackState, rackDispatch } = useContext(RackContext)
	const { userState } = useContext(UserContext)
	const [searchedText, setSearchedText] = useState(props.searchedText || '')
	const setCurrentPageNumber = props.setCurrentPageNumber
	const setOpenedRacks = props.setOpenedRacks
	const setClosedRacks = props.setClosedRacks
	const closedRacks = props.closedRacks
	const openedRacks = props.openedRacks
	const userLayout = userState.user.layout
	const username = userState.user.login
	const mustDisplayExportButton =
		userLayout.startsWith('WELDING_LAB') || userLayout === 'YELLOWTAIL_ADMIN' || userLayout === 'WHIPTAIL'
	const mustDisplayVam = userLayout === 'VAM_licensees'
	// Used to expand detailPanel automatically when data are filtered
	const customDetailPanel =
		!props.isPipeDetails && !props.octg && !props.slabs
			? (rowData: any) => {
					const childRows = rowData.tableData?.childRows || null
					return childRows?.length ? (
						<div
							style={{
								overflowX: 'hidden',
								boxShadow: '0px 4px 8px rgba(0,0,0, 0.3)'
							}}
						>
							<MaterialTable
								data={childRows}
								columns={props.columns.map((column: any) => ({
									...column,
									align: 'center',
									cellStyle: {
										...cellStyle,
										minWidth: '190px',
										maxWidth: '200px'
									}
								}))}
								style={{
									width: '100%',
									paddingLeft: '28px',
									borderRadius: 0
								}}
								icons={tableIcons}
								localization={{
									body: {
										filterRow: {
											filterPlaceHolder: t('filter')
										}
									},
									header: {
										actions: ''
									},
									toolbar: {
										searchPlaceholder: t('search')
									},
									pagination: {
										labelRowsSelect: t('rows'),
										labelRowsPerPage: ''
									}
								}}
								onChangeRowsPerPage={(pageSize: number) => {
									props.setCurrentPageSize(pageSize, rowData.rackId)
								}}
								options={{
									toolbar: false,
									header: false,
									initialPage: props.getCurrentPageNumber(rowData.rackId)
										? props.getCurrentPageNumber(rowData.rackId)
										: 0,
									paging: childRows.length > 5,
									pageSize: props.getCurrentPageSize(rowData.rackId),
									pageSizeOptions: [5, 10, 20, 30, 40, 50],
									emptyRowsWhenPaging: false,
									maxBodyHeight: 'calc(60vh - 250px)',
									paginationType: 'stepped',
									headerStyle: {
										whiteSpace: 'pre-wrap',
										fontWeight: 'bold',
										background: '#F8F9FA 0% 0% no-repeat padding-box',
										height: '50px'
									},
									rowStyle: (data: RackItemInTableModel) => {
										if (props.pipesBackgroundColor && props.pipesBackgroundColor.has(data.jointNumber)) {
											return {
												backgroundColor: props.pipesBackgroundColor.get(data.jointNumber)
											}
										}
										if (data.isDeletable !== undefined && !data.isDeletable) {
											return {
												backgroundColor: theme.colors.darkBlue,
												color: theme.colors.white
											}
										}
										return {}
									}
								}}
								components={{
									Pagination: (paginationProps: any) => (
										<PatchedPagination
											{...paginationProps}
											kindOfObjects={props.vam && mustDisplayVam ? 'accessories' : 'pipes'}
											onCustomChangePage={(page: number) => {
												setCurrentPageNumber(page, rowData.rackId)
											}}
											displayPagination={props.displayPagination}
										/>
									),
									Container: (props: any) => <Paper {...props} elevation={0} />
								}}
							/>
						</div>
					) : undefined
			  }
			: undefined

	useEffect(() => {
		setTimeout(() => {
			// CSS to stick header and filter
			const array = Array.from(document.getElementsByClassName('MuiTableRow-root'))
			array.forEach((lineElement, lineIndex) => {
				const isHeaderElement = lineElement.getElementsByTagName('th').length > 0
				const isFilterElement = props.vam && mustDisplayVam ? lineIndex === 2 : lineIndex === 1
				const rowElements = isHeaderElement
					? lineElement.getElementsByTagName('th')
					: lineElement.getElementsByTagName('td')

				if (isHeaderElement || isFilterElement) {
					Array.from(rowElements).forEach(element => {
						if (isHeaderElement || isFilterElement) {
							element.style.position = 'sticky'
							if (lineIndex === 0) {
								element.style.top = '0px'
							} else {
								element.style.top =
									lineIndex === 1
										? (array[0] as HTMLElement).offsetHeight + 'px'
										: (array[0] as HTMLElement).offsetHeight + (array[1] as HTMLElement).offsetHeight + 'px'
							}
							element.style.zIndex = '1'
						}
						if (isFilterElement) {
							element.style.background = '#FFFFFF'
						}
					})
				}
			})
		})
	})

	const RenderSideHeaderVam = () => {
		const { t } = useTranslation()
		return (
			<TableRow id="expandSideHeader">
				<th colSpan={8} className={classes.noBackground}></th>

				<th
					colSpan={props.expandSide2 ? 5 : 4}
					className={[
						classes.sideHeader,
						props.expandSide2 ? classes.backgroundExtended : classes.noBackground
					].join(' ')}
				>
					<span
						onClick={() => props.setExpandSide2(false)}
						className={[classes.collapse_btn, props.expandSide2 ? classes.red : classes.grey].join(' ')}
					>
						<Icon fontSize={'large'} className={classes.vertical_align}>
							<img src={expandLeft} height={36} alt="Logo" />
						</Icon>
						<span>{t('Collapse')}</span>
					</span>
					<span color="grey">
						<span>{t('Side 1')}</span>
					</span>
					{!props.expandSide2 && (
						<span
							onClick={() => props.setExpandSide2(true)}
							className={[classes.expand_btn, classes.green].join(' ')}
						>
							<span>{t('Extend')}</span>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandRight} height={36} alt="Logo" />
							</Icon>
						</span>
					)}
				</th>

				{props.expandSide2 && (
					<th
						colSpan={5}
						className={[
							classes.sideHeader,
							props.expandSide2 ? classes.backgroundExtended : classes.noBackground
						].join(' ')}
					>
						<span color="grey">
							<span>{t('Side 2')}</span>
						</span>
						<span className={[classes.expand_btn, classes.grey].join(' ')}>
							<span>{t('Extend')}</span>
							<Icon fontSize={'large'} className={classes.vertical_align}>
								<img src={expandRight} height={36} alt="Logo" />
							</Icon>
						</span>
					</th>
				)}

				<th colSpan={5} className={classes.noBackground}></th>
			</TableRow>
		)
	}

	const renderTableHeader = () => {
		if (props.vam && mustDisplayVam) return <RenderSideHeaderVam />
	}

	useEffect(() => {
		if (
			document.getElementsByTagName('thead') &&
			document.getElementsByTagName('thead')[0] &&
			document.getElementsByTagName('thead')[1]
		) {
			document.getElementsByTagName('thead')[0].appendChild(document.getElementsByTagName('thead')[1].children[0])
		}
	}, [props, t])

	const handleSelectPipe = (pipe: RackItemInTableModel) => {
		if (pipe !== rackState.pipeDetails[0]) {
			const pipes = [pipe, rackState.pipeDetails[0]]
			rackDispatch({ type: 'set_pipe_details', pipeDetails: pipes })
		}
	}

	let tableData: any = []
	if (!props.isPipeDetails && !props.octg && !props.slabs) {
		props.data.forEach((row: any) => {
			const childRows = row.tableData?.childRows?.filter((child: any) => {
				if (searchedText.length) {
					return TableUtils.isInRack(searchedText, child, userState.checkedWeight, userState.checkedLength)
				} else {
					return true
				}
			})
			let showDetailPanel
			showDetailPanel = openedRacks.includes(t(row.rackId)) ? customDetailPanel : undefined
			tableData.push({
				...row,
				tableData: {
					...row.tableData,
					childRows,
					isTreeExpanded: false,
					// If search isn't empty or table is filtered or the rack was closed by changing row per page, racks are expanded
					showDetailPanel
				}
			})
		})
		tableData = tableData
			.filter((row: any) => row.rackId)
			// To search on racks too, not only childRows
			.filter((row: any) => {
				if (searchedText.length) {
					return (
						row.rackId?.toUpperCase()?.includes(searchedText) ||
						String(row.pipeCount)?.includes(searchedText) ||
						row.tableData?.childRows?.length
					)
				} else {
					return true
				}
			})
	} else {
		tableData = props.data
	}

	// if table is filtered but user closed a rack, it not reopens on refresh
	useEffect(() => {
		if (TableUtils.isTableFiltered(props.data) || searchedText.length) {
			let openedFilteredRacks: any = []
			tableData.forEach((rack: any) => {
				if (!openedRacks.includes(t(rack.rackId)) && !closedRacks.includes(t(rack.rackId))) {
					openedFilteredRacks.push(t(rack.rackId))
				}
			})
			if (openedFilteredRacks.length) {
				props.setOpenedRacks(props.openedRacks.concat(openedFilteredRacks))
				props.setCurrentPageNumber(0, props.getTableName())
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData])

	return (
		<div className={classes.root} id="TableRoot">
			{!props.isPipeDetails && !props.octg && !props.slabs && (
				<>
					{mustDisplayExportButton && (
						<ExportView
							columns={props.columns}
							filtredData={props.data}
							allData={props.allData}
							columnNames={props.columnNames}
							username={username}
						/>
					)}
					<TextField
						id="outlined-search"
						variant="outlined"
						size="small"
						placeholder={t('search')}
						value={searchedText}
						style={{
							background: '#F8F9FA',
							position: 'absolute',
							right: 50,
							top: 150,
							width: '230px',
							zIndex: 1
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SearchRounded color="action" />
								</InputAdornment>
							),
							endAdornment: (
								<InputAdornment
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setSearchedText('')
										props.setSearchedText('')
										// When search is cleared, close all racks
										// props.setOpenedRacks([])
										props.setCurrentPageNumber(0, props.getTableName())
									}}
									position="end"
								>
									<CloseRounded color="action" />
								</InputAdornment>
							)
						}}
						onChange={(e: any) => {
							// On change, remove all racks from closed racks because maybe some of closed racks will have to be opened again on search result
							props.setClosedRacks([])

							setSearchedText(e.target.value?.toUpperCase())
							props.setSearchedText(e.target.value?.toUpperCase())
							props.setCurrentPageNumber(0, props.getTableName())
						}}
					/>
				</>
			)}
			<MaterialTable
				title={<TitleTable title={props.title} subtitle={props.subtitle} />}
				style={{
					tableLayout: 'fixed',
					height: '100%'
				}}
				icons={{
					...tableIcons,
					DetailPanel: forwardRef((detailPanelProps, ref) => (
						<ChevronRight
							{...detailPanelProps}
							ref={ref}
							onClick={event => {
								const rackId = event?.currentTarget?.closest('tr')?.childNodes[1].textContent
								if (rackId) {
									if (openedRacks.includes(rackId || t(rackId))) {
										let arrayWithItemRemoved = openedRacks
										arrayWithItemRemoved.splice(arrayWithItemRemoved.indexOf(rackId), 1)
										setOpenedRacks(arrayWithItemRemoved)
										setClosedRacks(closedRacks.concat([rackId]))
									} else {
										let arrayWithItemRemoved = closedRacks
										arrayWithItemRemoved.splice(arrayWithItemRemoved.indexOf(rackId), 1)
										setClosedRacks(arrayWithItemRemoved)
										setOpenedRacks(openedRacks.concat([rackId]))
									}
								}
							}}
						/>
					))
				}}
				data={tableData}
				columns={props.columns.map((column: any) => ({
					...column,
					align: 'center',
					cellStyle:
						!props.isPipeDetails && !props.octg && !props.slabs
							? { ...cellStyle, minWidth: '190px', maxWidth: '200px' }
							: cellStyle
				}))}
				localization={{
					body: {
						filterRow: {
							filterPlaceHolder: t('filter')
						}
					},
					header: {
						actions: ''
					},
					toolbar: {
						searchPlaceholder: t('search')
					},
					pagination: {
						labelRowsSelect: t('rows'),
						labelRowsPerPage: ''
					}
				}}
				detailPanel={customDetailPanel}
				options={{
					search: (props.isPipeDetails || props.octg || props.slabs) && tableData.length > 1 ? true : false,
					initialPage:
						props.getCurrentPageNumber && props.getCurrentPageNumber(props.getTableName())
							? props.getCurrentPageNumber(props.getTableName())
							: 0,
					pageSize: props.getCurrentPageSize(props.getTableName()),
					pageSizeOptions: [5, 10, 20, 30, 40, 50],
					emptyRowsWhenPaging: false,
					filtering: tableData.length > 1 ? true : false,
					paginationType: !props.isPipeDetails && !props.octg && !props.slabs ? 'stepped' : 'normal',
					headerStyle: {
						whiteSpace: 'pre-wrap',
						fontWeight: 'bold',
						background: '#F8F9FA 0% 0% no-repeat padding-box',
						height: '50px'
					},
					rowStyle: (data: RackItemInTableModel) => {
						if (props.pipesBackgroundColor && props.pipesBackgroundColor.has(data.jointNumber)) {
							return {
								backgroundColor: props.pipesBackgroundColor.get(data.jointNumber)
							}
						}
						if (data.isDeletable !== undefined && !data.isDeletable) {
							return {
								backgroundColor: theme.colors.darkBlue,
								color: theme.colors.white
							}
						}
						return {}
					},
					minBodyHeight: 'calc(10vh - 250px)',
					maxBodyHeight: 'calc(100vh - 250px)'
				}}
				onChangeRowsPerPage={(pageSize: number) => props.setCurrentPageSize(pageSize, props.getTableName())}
				onTreeExpandChange={props.setExpandRow ? props.setExpandRow : () => {}}
				actions={
					props.activeRadioButton && [
						{
							icon: '',
							tooltip: 'Select Pipe',
							onClick: (_event: any, rowData: any) => handleSelectPipe(rowData)
						}
					]
				}
				components={
					props.octg || props.slabs
						? {
								Pagination: (patchedPaginationProps: any) => (
									<PatchedPagination
										{...patchedPaginationProps}
										kindOfObjects={props.slabs ? 'slabs' : 'racks'}
										onCustomChangePage={(page: number) => {
											setCurrentPageNumber(page)
										}}
										displayPagination={props.displayPagination}
									/>
								)
						  }
						: {
								Pagination: (patchedPaginationProps: any) => (
									<PatchedPagination
										{...patchedPaginationProps}
										kindOfObjects="racks"
										onCustomChangePage={(page: number) => {
											setCurrentPageNumber(page, props.getTableName())
										}}
										displayPagination={props.displayPagination}
									/>
								),
								Header: (props: any) => (
									<TableHead>
										<MTableHeader {...props} />
										{renderTableHeader()}
									</TableHead>
								),
								Action: (props: any) => (
									<Radio
										onClick={event => props.action.onClick(event, props.data)}
										value={props.data.identificationId}
										checked={rackState.pipeDetails[0].identificationId === props.data.identificationId}
										name="radio-button-demo"
										style={{
											color: theme.colors.darkBlue
										}}
									/>
								)
						  }
				}
			/>
		</div>
	)
}
export default FilterableTable
