import React, { useEffect, SyntheticEvent, useContext } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import Button from '../../components/common/Button';
import Input from '../../components/form/Input';
import ErrorMessage from '../../components/form/ErrorMessage';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ForgotPasswordFormParam } from '../../models/ForgotPasswordFormParam';
import LoginService from '../../api/LoginService';
import { UserContext } from '../../stores/UserStore';
import { Box, makeStyles, Typography } from '@material-ui/core';
import theme from '../../utils/theme';

const useStyles = makeStyles({
  label: {
    color: theme.colors.darkGray,
    fontSize: theme.fontSizes.big,
    margin: '0px 5px 16px',
  },
  instruction: {
    color: theme.colors.gray,
    fontSize: theme.fontSizes.medium,
    margin: '0px 5px 24px',
  },
});

const ForgotPasswordForm = (param: ForgotPasswordFormParam) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { userDispatch } = useContext(UserContext);

  useEffect(() => {
    param.setValidation({
      ...param.validation,
      userError: ''
    });

    return () => {
      param.setValidation({
        ...param.validation,
        userError: ''
      })
    };
  }, []); // eslint-disable-line

  const onSubmit = (async (e: SyntheticEvent) => {
    e.preventDefault();
    param.setPageLoading(true);
    try {
      await LoginService.sendTemporaryPassword(param.email.toLowerCase());
      userDispatch({ type: 'reset_auth_state' });
      toast.success(t('login.messages.codeSent'));
      param.setPageLoading(false);
      param.setValidation({
        ...param.validation,
        userError: ''
      });
    }
    catch (e: any) {
      param.setPageLoading(false);
      console.log(e);
      if (e.resultCode === 'error.expired.token') {
        userDispatch({ type: 'sign_out' });
      } else {
        param.setValidation({
          ...param.validation,
          userError: e.resultCode || e
        });
      }
    }
  });

  return (
    <form data-cy="forgot-password-form" style={{ width: '100%'}} onSubmit={onSubmit} autoComplete="off">
      <input type="hidden" value="something" />
      <SwitchTransition>
        <CSSTransition
          key='sendTemporaryPassword'
          timeout={300}
          classNames="fade"
        >
          <Box display='flex' flexDirection='column' alignItems="flex-start" width='100%' margin='10px 0px'>
            <Typography className={classes.label}>{t('login.forgotPasswordLabel')}</Typography>
            <Typography className={classes.instruction}>{t('login.forgotPasswordInstruction')}</Typography>
            <Input
              name="email"
              style={{textTransform: 'lowercase'}}
              placeholder={t('username')}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              width="100%"
              value={param.email}
              onChange={param.updateValue('email', param.setEmail)}
              onBlur={param.checkValidation}
              error={(param.validation.email.dirty && param.validation.email.error) || (param.validation.userError.length > 0)}
              required
            />
            {(param.validation.userError.length > 0) &&
              <ErrorMessage>{t(`login.errors.${param.validation.userError}`)}</ErrorMessage>
            }
            <Box display="flex" justifyContent="flex-end" width="100%" margin="15px 0px 5px">
              <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
                {t('Send')}
              </Button>
            </Box>
          </Box>
        </CSSTransition>
      </SwitchTransition>
    </form>
  )
};

export default ForgotPasswordForm;