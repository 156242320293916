import { instance, manageResponse, getHeader } from './ApiManager';


const requests = {
    getRackData : async () => {
        const response = await instance.get('/tgp-rack/rack-list', getHeader())
        return manageResponse(response)
    }
}

export default requests