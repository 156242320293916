import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';


const useStyles = makeStyles((t) => ({
      root : {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
            minWidth: 300,
      }
}));


const UploadDocumentPopup = (props: any) => {
      const classes = useStyles();
      const { t } = useTranslation();

      const [valid, setValid] = useState('');
      const [pipeList, setPipeList] = useState('');
      const [vsbOrder, setVsbOrder] = useState('');
      const [customerItem, setCustomerItem] = useState('');

      const [documentType, setDocumentType] = useState('QUALITY_CERTIFICATE');

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValid(event.target.value);
            setPipeList('');
            setVsbOrder('');
            setCustomerItem('');
      };

      const handleChangePipeList = (event: React.ChangeEvent<HTMLInputElement>) => {
            setPipeList(event.target.value);
            setValid('');
            setVsbOrder('');
            setCustomerItem('');
      };

      const handleChangeVsbOrder = (event: React.ChangeEvent<HTMLInputElement>) => {
            setVsbOrder(event.target.value);
            setValid('');
            setPipeList('');
      };

      const handleChangeCustomerItem = (event: React.ChangeEvent<HTMLInputElement>) => {
            setCustomerItem(event.target.value);
            setValid('');
            setPipeList('');
      };

      const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setDocumentType(event.target.value);
      };
   
      return (
            <div >
                  <Dialog open={ props.open }  aria-labelledby="form-dialog-title" >

                        <DialogContent className={classes.root}>
                              <Typography variant="h5" gutterBottom>
                                    {t('File to load')}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                    { props.filename }
                              </Typography>

                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('document_type')}
                              </Typography>
                              <RadioGroup
                                    aria-label="documentType"
                                    defaultValue="quality_certificate"
                                    name="radio-buttons-group"
                                    value= {documentType}
                                    onChange={handleRadioChange}
                              >
                                    <FormControlLabel value="QUALITY_CERTIFICATE" control={<Radio />} label={ t('document_type_quality_certificate')} labelPlacement="start" />
                                    <FormControlLabel value="PACKING_LIST" control={<Radio />} label={ t('document_type_packing_list')} labelPlacement="start" />
                                    <FormControlLabel value="PIPE_ENDS_MEASUREMENT_REPORT" control={<Radio />} label={ t('document_type_pipe_end_measurement_report')} labelPlacement="start" />
                              </RadioGroup>

                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('associated_vsb_id')}
                              </Typography>
                              <TextField
                                    id="vsbID-Text"
                                    label=""
                                    variant="outlined"
                                    value= {valid}
                                    onChange={ handleChange }
                              />
                              <div>
                                    <div style={{ display:'inline-block'}}>
                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('order')}
                              </Typography>
                              <TextField
                                  id="vsbOrder-Text"
                                  label=""
                                  variant="outlined"
                                  value= {vsbOrder}
                                  onChange={ handleChangeVsbOrder }
                              />
                                    </div>
                                    <div style={{ display:'inline-block', marginLeft: '10px'}}>
                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('item')}
                              </Typography>

                              <TextField
                                  id="customerItem-Text"
                                  label=""
                                  variant="outlined"
                                  value= {customerItem}
                                  onChange={ handleChangeCustomerItem }
                              />
                                    </div>
                              </div>
                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('pipe_list')}
                              </Typography>
                              <TextField
                                  id="pipeList-Text"
                                  label=""
                                  variant="outlined"
                                  value= {pipeList}
                                  onChange={ handleChangePipeList }
                              />
                        
                         </DialogContent>
                        <DialogActions style={{marginTop:'30px'}}>
                        <Button onClick={ () => {
                              props.handleCancel();
                              setValid('');
                              setPipeList('');
                              setVsbOrder('');
                              setCustomerItem('');
                              setDocumentType('QUALITY_CERTIFICATE');
                        } } color="primary">
                              { t('Cancel')}
                        </Button>
                        <Button onClick={ () => {
                              props.handleLoad(valid, vsbOrder, customerItem, pipeList, documentType);
                              setValid('');
                              setPipeList('');
                              setVsbOrder('');
                              setCustomerItem('');
                              setDocumentType('QUALITY_CERTIFICATE');
                         }
                              } disabled = {(valid.length === 0 && pipeList.length===0 && vsbOrder.length === 0 && customerItem.length === 0) || documentType === ''} color="primary">
                              { t('Load')}
                        </Button>
                        </DialogActions>
                  </Dialog>
            </div>
      );
}

export default UploadDocumentPopup;