/** @format */

import i18next from 'i18next'
import { toast, Zoom } from 'react-toastify'
import './i18n'
import Router from './Router'
import { AppContextProvider } from './stores/AppContextProvider'
import { UserProvider } from './stores/UserStore'

toast.configure({
	autoClose: 4000,
	draggable: false,
	transition: Zoom,
	hideProgressBar: true,
	closeButton: false
})

i18next.init({
	whitelist: ['fr', 'en'],
	nonExplicitWhitelist: true,
	load: 'languageOnly',
	fallbackLng: 'en'
})

const App = () => {
	return (
		<UserProvider>
			<AppContextProvider>
				<Router />
			</AppContextProvider>
		</UserProvider>
	)
}

export default App
