/** @format */

import React, { Dispatch, useReducer } from 'react'
import { OctgPipeModel } from '../models/OctgPipeModel'

type State = {
	allPipeData: OctgPipeModel[]
	displayedPipeData: OctgPipeModel[]
	pageNumber: number
	pageSize: number
	octgColumns: any[]
}

type Action =
	| { type: 'set_all_pipe_data'; allPipeData: OctgPipeModel[] }
	| { type: 'set_page_number'; pageNumber: number }
	| { type: 'set_page_size'; pageSize: number }
	| { type: 'init_table' }
	| { type: 'set_octg_columns'; octgColumns: any[] }
	| { type: 'set_octg_displayed_pipe_data'; displayedPipeData: OctgPipeModel[] }

const initialState: State = {
	allPipeData: [],
	displayedPipeData: [],
	pageNumber: 0,
	pageSize: 10,
	octgColumns: []
}

export const OctgContext = React.createContext<{
	octgState: State
	octgDispatch: Dispatch<Action>
}>({
	octgState: initialState,
	octgDispatch: () => null
})

const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case 'set_all_pipe_data':
			return {
				...state,
				allPipeData: action.allPipeData,
				displayedPipeData: action.allPipeData
			}
		case 'set_page_number':
			localStorage.setItem('page_number', JSON.stringify(action.pageNumber))
			return {
				...state,
				pageNumber: action.pageNumber
			}
		case 'set_page_size':
			localStorage.setItem('page_size', JSON.stringify(action.pageSize))
			return {
				...state,
				pageSize: action.pageSize
			}
		case 'init_table':
			return {
				...state,
				pageNumber: localStorage.getItem('page_number') ? JSON.parse(localStorage.getItem('page_number')!) : 0,
				pageSize: localStorage.getItem('page_size') ? JSON.parse(localStorage.getItem('page_size')!) : 10
			}
		case 'set_octg_columns':
			return {
				...state,
				octgColumns: action.octgColumns
			}
		case 'set_octg_displayed_pipe_data':
			return {
				...state,
				displayedPipeData: action.displayedPipeData
			}
	}
}

export const OctgProvider = (props: any) => {
	const [octgState, octgDispatch] = useReducer(reducer, initialState)

	return <OctgContext.Provider value={{ octgState, octgDispatch }}>{props.children}</OctgContext.Provider>
}
