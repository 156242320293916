/** @format */

import React from 'react'
import { useTranslation } from 'react-i18next'
import CardInfoVam from '../../components/common/CardVamInfo'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'

type AccessoryDetailsCardInfoProps = {
	accessory: RackItemInTableModel
}

const AccessoryDetailsCardInfo = (props: AccessoryDetailsCardInfoProps) => {
	const { t } = useTranslation()
	const key = 'vam-data.'

	const globalInfo = () => {
	    return [
            {
                label: t(key + 'accessoryType.name'),
                value: props.accessory.accessoryType ? props.accessory.accessoryType.toString() : ''
            },
            {
                label: t(key + 'end1OuterDiameter.name'),
                value: props.accessory.end1OuterDiameter ? props.accessory.end1OuterDiameter.toString() : ''
            },
            { label: t(key + 'end1weight.name'), value: props.accessory.end1weight ? props.accessory.end1weight.toString() : '' },
            { label: t(key + 'end1SteelGrade.name'), value: props.accessory.end1SteelGrade ? props.accessory.end1SteelGrade.toString() : '' },
            {
                label: t(key + 'end1ConnectionType.name'),
                value: props.accessory.end1ConnectionType ? props.accessory.end1ConnectionType.toString() : ''
            },
            {
                label: t(key + 'end1SurfaceTreatment.name'),
                value: props.accessory.end1SurfaceTreatment ? props.accessory.end1SurfaceTreatment.toString() : ''
            }
	    ]
	}

	const infoSide1 = []
	infoSide1.push(
		{
			label: t(key + 'accessoryType.name'),
			value: props.accessory.accessoryType ? props.accessory.accessoryType.toString() : ''
		},
		{
			label: t(key + 'end1OuterDiameter.name'),
			value: props.accessory.end1OuterDiameter ? props.accessory.end1OuterDiameter.toString() : ''
		},
		{ label: t(key + 'end1weight.name'), value: props.accessory.end1weight ? props.accessory.end1weight.toString() : '' },
		{ label: t(key + 'end1SteelGrade.name'), value: props.accessory.end1SteelGrade ? props.accessory.end1SteelGrade.toString() : '' },
		{
			label: t(key + 'end1ConnectionType.name'),
			value: props.accessory.end1ConnectionType ? props.accessory.end1ConnectionType.toString() : ''
		},
		{
			label: t(key + 'end1SurfaceTreatment.name'),
			value: props.accessory.end1SurfaceTreatment ? props.accessory.end1SurfaceTreatment.toString() : ''
		}
	)
	const infoSide2 = []
	if (props.accessory.accessoryType === 'CROSSOVER') {
		infoSide2.push(
			{
				label: t(key + 'accessoryType.name'),
				value: props.accessory.accessoryType ? props.accessory.accessoryType.toString() : ''
			},
			{
				label: t(key + 'end2OuterDiameter.name'),
				value: props.accessory.end2OuterDiameter ? props.accessory.end2OuterDiameter.toString() : ''
			},
			{ label: t(key + 'end2weight.name'), value: props.accessory.end2weight ? props.accessory.end2weight.toString() : '' },
			{ label: t(key + 'end2SteelGrade.name'), value: props.accessory.end2SteelGrade ? props.accessory.end2SteelGrade.toString() : '' },
			{
				label: t(key + 'end2ConnectionType.name'),
				value: props.accessory.end2ConnectionType ? props.accessory.end2ConnectionType.toString() : ''
			},
			{
				label: t(key + 'end2SurfaceTreatment.name'),
				value: props.accessory.end2SurfaceTreatment ? props.accessory.end2SurfaceTreatment.toString() : ''
			}
		)
	}

	return <CardInfoVam globalInfo={globalInfo()} infoSide1={infoSide1} infoSide2={infoSide2} />
}

export default AccessoryDetailsCardInfo
