/** @format */

import { Workbook } from 'exceljs'
import FileSaver from 'file-saver'
import { RackItemInTableModel } from '../models/RackItemInTableModel'
import { jsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'

export const exportDataTOExternalFile = async (
	rackData: RackItemInTableModel[],
	rackColumns: any[],
	typeExport: 'xls' | 'csv' | 'pdf',
	columnNames?: string[],
	user?: string
) => {
	if (typeExport === 'xls') {
		await toXls(rackData, rackColumns)
	} else if (typeExport === 'csv') {
		await toCsv(rackData, rackColumns)
	} else if (typeExport === 'pdf') {
		await toPdf(rackData, rackColumns, columnNames!, user!)
	}
}

const toXls = async (rackData: RackItemInTableModel[], rackColumns: any[]) => {
	const workbook = createWorkbook(rackColumns, rackData)

	const buffer = await workbook.xlsx.writeBuffer()
	const data: Blob = new Blob([buffer], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
	})
	FileSaver.saveAs(data, `data_vallourec.xlsx`)
}

const toCsv = async (rackData: RackItemInTableModel[], rackColumns: any[]) => {
	const workbook = createWorkbook(rackColumns, rackData)

	const buffer = await workbook.csv.writeBuffer()
	const data: Blob = new Blob([buffer], {
		type: 'text/csv;charset=utf-8'
	})
	FileSaver.saveAs(data, `data_vallourec.csv`)
}

const toPdf = async (rackData: RackItemInTableModel[], rackColumns: any[], columnNames: string[], user: string) => {
	const doc = new jsPDF({
		orientation: 'landscape'
	})

	const marge = 10
	const date = new Date().toLocaleDateString()
	doc.text(date, doc.internal.pageSize.width - 37, marge)

	doc.text(user, marge, marge)

	const tableRows = rearrangeData(rackData).map(item => {
		const row = []

		if (item.rackId) {
			// Fusionner les 10 premières cellules
			row.push({
				content: item.rackId,
				colSpan: 10
			})

			columnNames.slice(10).forEach(prop => {
				row.push(item[prop as keyof RackItemInTableModel])
			})
		} else {
			columnNames.forEach(prop => {
				row.push(item[prop as keyof RackItemInTableModel])
			})
		}

		return row
	})

	//supprimer la dernière colonne vide pour le bouton de detail d'un pipe
	rackColumns.pop()

	autoTable(doc, {
		head: [rackColumns],
		body: tableRows,
		startY: 20
	})

	doc.save('data_vallourec.pdf')
}

const createWorkbook = (rackColumns: any[], rackData: RackItemInTableModel[]) => {
	const workbook = new Workbook()
	const worksheetPipeView = workbook.addWorksheet('PIPES')
	worksheetPipeView.columns = rackColumns.map(column => ({
		header: column.title,
		key: column.field
	}))

	worksheetPipeView.addRows(rearrangeData(rackData))
	return workbook
}

const rearrangeData = (data: RackItemInTableModel[]): RackItemInTableModel[] => {
	let result: RackItemInTableModel[] = []

	data
		.filter(rackData => rackData.rackId)
		.forEach(rack => {
			result.push(rack)

			if (rack.tableData?.childRows) {
				result.push(...rack.tableData.childRows)
			}
		})

	return result
}
