/** @format */

import { TailRackContentStrategy } from './TailRackContentStrategy'
import { VamRackContentStrategy } from './VamRackContentStrategy'
import { WeldingLabRackContentStrategy } from './WeldingLabRackContentStrategy'

export const contextRackContentStrategy = (layout: string) => {
	switch (true) {
		case layout === 'YELLOWTAIL_ADMIN' || layout === 'WHIPTAIL':
			return new TailRackContentStrategy()
		case layout === 'VAM_licensees':
			return new VamRackContentStrategy()
		default:
			return new WeldingLabRackContentStrategy()
	}
}
