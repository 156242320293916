/** @format */

import { AbstractRackItemDto } from "../models/dto/AbstractRackItemDto"

export class RackUtils {
	static sortRacks(item1: AbstractRackItemDto<any>, item2: AbstractRackItemDto<any>): number {
		if (item1.deletable && !item2.deletable) {
			return 1
		}

		if (!item1.deletable && item2.deletable) {
			return -1
		}

		return item1.rackId.toUpperCase() > item2.rackId.toUpperCase() ? 1 : -1
	}
}
