import { Button as MaterialButton, makeStyles } from '@material-ui/core';
import theme from '../../utils/theme';

type ButtonProps = {
  elevation?: string;
  backgroundGradient?: string;
  borderRadius?: string;
  color?: string;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  background?: string;
  flexDirection?: string;
  flex?: string;
  alignItems?: string;
  justifyContent?: string;
  fontSize?: string;
  isLoading?: boolean;
  display?: string;
};

const useStyles = makeStyles({
  button: (props: ButtonProps) => ({
    webkitTapHighlightColor: 'transparent',
    fontWeight: 'bold',
    outline: 0,
    cursor: 'pointer',
    userSelect: 'none',
    textTransform: 'none',
    boxShadow: props.elevation ? props.elevation : 'none',
    borderRadius: props.borderRadius
      ? props.borderRadius
      : '4px',
    // ${({ disabled, isLoading }) => disabled && !isLoading && 'opacity: 0.4;'}
    padding: props.padding ? props.padding : '10px',
    margin: props.margin ? props.margin : '0px 15px 0px 0px',
    textAlign: 'center',
    border: 'none',
    background: props.background ? props.background : theme.gradients.main,
    height: props.height ? props.height : '32px',
    width: props.width ? props.width : '32px',
    display: 'flex',
    flex: props.flex ? props.flex : '',
    flexDirection: 'row',
    alignItems: props.alignItems ? props.alignItems : 'center',
    justifyContent: props.justifyContent ? props.justifyContent : 'center',
    fontSize: props.fontSize ? props.fontSize : theme.fontSizes.medium,
    color: props.color ? props.color : theme.colors.black,
    transition: 'opacity 0.1s linear',
    opacity: 1,
    minWidth: '0',
    // // ${({ disabled }) => disabled && 'opacity: 0.4; cursor: not-allowed;'}

    '&:active': {
      opacity: '0.6',
    },
  }),
});

export default function Button(props: any) {
  const classes = useStyles(props);

  return (
    <MaterialButton
      onClick={props.onClick}
      color='primary'
      className={classes.button}
      type={props.type}
    >
      {props.children}
    </MaterialButton>
  );
}