/** @format */

import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core'
import { FilesDownloadable } from '../../models/FilesDownloadable'
import SpinnerImage from '../../assets/icons/spinner.svg';
import theme from '../../utils/theme'

const useStyles = makeStyles({
	gridRow: {
		flexGrow: 1
	},
	gridCell: {
		flexGrow: 0,
		maxWidth: 'none',
		textAlign: 'center'
	},
	gridCellGray: {
		maxWidth: 'none',
		textAlign: 'center',
		opacity: '0.1',
		filter: 'grayscale(100)',
		cursor: 'not-allowed'
	},
	title: {
		color: theme.colors.black,
		fontSize: '14px',
		textAlign: 'center',
		fontWeight: 'bold',
		paddingBottom: '5px',
		minHeight: '40px'
	},
	button: {
		color: theme.colors.white,
		fontWeight: 'bold',
		textAlign: 'center',
		margin: '0px 16px'
	},
	boxStyle: {
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center'
	},
	circularProgressStyle: {
		backgroundImage: `url(${SpinnerImage})`
	},
	circle: {
		stroke: `url(${SpinnerImage})`
	},
})

export default function FilesDownload(props: any) {
	const classes = useStyles(props)

	const filesDownloadableLoader = (propsLoader: any) => {
		let loader = propsLoader.columns.map((filesDownload: FilesDownloadable) => filesDownload.downloadable)
		return loader.includes(undefined)
	}

	return (
		<div style={{ minHeight: '100px' }}>
			{filesDownloadableLoader(props) ? (
				<Grid className={classes.gridRow} justifyContent="space-around" container direction="row" spacing={1}>
					<Box className={classes.boxStyle} display={filesDownloadableLoader(props) && 'flex'}>
						<CircularProgress size={'4rem'} classes={{ circle: classes.circle }} className={classes.circularProgressStyle} />
					</Box>
					{props.columns.map((filesDownload: FilesDownloadable) => (
						<Grid key={filesDownload.id} className={classes.gridCell} item xs={2}>
							<div className={classes.gridCellGray}>
								<Box className={classes.title}>{filesDownload.title}</Box>
								<Box className={classes.button}>{filesDownload.render}</Box>
							</div>
						</Grid>
					))}
				</Grid>
			) : (
				<Grid className={classes.gridRow} justifyContent="space-around" container direction="row" spacing={1}>
					{props.columns.map((filesDownload: FilesDownloadable) => (
						<Grid key={filesDownload.id} className={classes.gridCell} item xs={2}>
							{filesDownload.downloadable ? (
								<div>
									<Box className={classes.title}>{filesDownload.title}</Box>
									<Box className={classes.button}>{filesDownload.render}</Box>
								</div>
							) : (
								<div className={classes.gridCellGray}>
									<Box className={classes.title}>{filesDownload.title}</Box>
									<Box className={classes.button}>{filesDownload.render}</Box>
								</div>
							)}
						</Grid>
					))}
				</Grid>
			)}
		</div>
	)
}
