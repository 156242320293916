import React, { useState, useEffect, SyntheticEvent, useContext } from 'react';
import Button from '../../components/common/Button';
import Input from '../../components/form/Input';
import ErrorMessage from '../../components/form/ErrorMessage';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ChangePasswordFormParam } from '../../models/ChangePasswordFormParam';
import LoginService from '../../api/LoginService';
import { UserContext } from '../../stores/UserStore';
import { Box, makeStyles, Typography } from '@material-ui/core';
import theme from '../../utils/theme';

const useStyles = makeStyles({
  label: {
    color: theme.colors.darkGray,
    fontSize: theme.fontSizes.big,
    margin: '0px 5px 16px',
  },
  instruction: {
    color: theme.colors.gray,
    fontSize: theme.fontSizes.medium,
    margin: '0px 5px 24px',
  },
});

const ChangePasswordForm = (param: ChangePasswordFormParam) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const [codeError, setCodeError] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const { userDispatch } = useContext(UserContext);

  useEffect(() => {
    param.setValidation({
      ...param.validation,
      userError: ''
    });

    return () => {
      param.setValidation({
        ...param.validation,
        userError: ''
      })
    };
  }, []); // eslint-disable-line

  const passwordError = newPassword && !(new RegExp("^(?=.*[0-9])(?=.*[a-zA-Z]).{8,}$").test(newPassword));
  const passwordMatchError = param.validation.confirmPassword.dirty && (confirmPassword !== newPassword);

  const onSubmit = (async (e: SyntheticEvent) => {
    e.preventDefault();
    if (!passwordError && !passwordMatchError) {
      param.setPageLoading(true);
      try {
        await LoginService.changePassword(oldPassword, newPassword);
        userDispatch({ type: 'reset_auth_state' });
        toast.success(t('login.messages.passwordChanged'));
        param.setPageLoading(false)
        param.setValidation({
          ...param.validation,
          userError: ''
        });
      }
      catch (e: any) {
        param.setPageLoading(false);
        console.log(e);
        if (e.resultCode === 'error.expired.token') {
          userDispatch({ type: 'sign_out' });
        } else {
          setCodeError(['error.user.bad.login'].includes(e.resultCode) ? t(`login.errors.${e.resultCode}`) : '');
          param.setValidation({
            ...param.validation,
            userError: e.resultCode || e
          });
        }
      }
    }
    else {
      param.setValidation({
        ...param.validation,
        email: {
          dirty: true,
          error: false
        },
        confirmPassword: {
          dirty: true,
          error: false
        }
      });
    }
  });

  return (
    <form data-cy="change-password-form" style={{ width: '100%'}} onSubmit={onSubmit} autoComplete="off">
      <Box display='flex' flexDirection='column' alignItems="flex-start" width='100%' margin='10px 0px'>
        <Typography className={classes.label}>{t('login.newPasswordLabel')}</Typography>
        <Typography className={classes.instruction}>{t('login.newPasswordInstruction')}</Typography>
        <Input
          name="oldPassword"
          placeholder={t('old password')}
          width="100%"
          autoCorrect="off"
          autoCapitalize="off"
          autoComplete="new-password"
          value={oldPassword}
          onChange={param.updateValue(null, setOldPassword)}
          error={codeError}
          required
        />
        <Input
          type="password"
          name="newPassword"
          placeholder={t('new password')}
          width="100%"
          autoComplete="new-password"
          onChange={param.updateValue(null, setNewPassword)}
          error={param.validation.userError || passwordError || passwordMatchError}
          required
        />
        {param.validation.userError && <ErrorMessage>{t(`login.errors.${param.validation.userError}`)}</ErrorMessage>}
        {passwordError && <ErrorMessage>{t('login.errors.InvalidPasswordException')}</ErrorMessage>}
        {passwordMatchError && <ErrorMessage>{t('login.errors.PasswordMatchError')}</ErrorMessage>}
        <Input
          type="password"
          name="confirmPassword"
          placeholder={t('confirm password')}
          width="100%"
          autoComplete="new-password"
          onChange={param.updateValue('confirmPassword', setConfirmPassword)}
          onBlur={param.checkValidation}
          error={passwordMatchError}
          required
        />
        <Box display="flex" justifyContent="flex-end" width="100%" margin="15px 0px 5px">
          <Button type="submit" elevation="medium" backgroundGradient="main" color="white" margin="0px" width="40%">
            {t('Change')}
          </Button>
        </Box>
      </Box>
    </form>
  )
};

export default ChangePasswordForm;