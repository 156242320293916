/** @format */

import { PageDetailStrategy, ColumnContent } from './PageDetailStrategy'
import { TFunction } from 'react-i18next'
import { Action, UserState } from '../../stores/UserStore'
import { buildLengthColumn } from './columnBuilder/buildLengthColumn'
import { buildNonSpecificColumn } from './columnBuilder/buildNonSpecificColumn'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import { buildWeightColumn } from './columnBuilder/buildWeightColumn'
import { FilesDownloadable } from '../../models/FilesDownloadable'
import { buildFilesDownloadColumn } from './columnBuilder/buildFilesDownloadColumn'
import { downloadPDFDocument } from '../DocumentBusiness'
import DocumentService from '../../api/DocumentService'
import YellowtailPipeDetailsCardInfo from '../../containers/common/YellowtailPipeDetailsCardInfo'
import DoFactory from '../../models/DoFactory'
import { GenericProductDto } from '../../models/dto/GenericProductDto'

export class TailPageDetailStrategy implements PageDetailStrategy {
	private readonly columnNames = [
		'identificationId',
		'tally',
		'ippn',
		'vsbOrder',
		'ytCustomerItem',
		'heatNumber',
		'weight',
		'length',
		'certificateName',
		'pipeEndsMeasurementReportName'
	]

	private readonly dataType = 'yellowtail'

	private downloadDocument = (
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		type: string,
		pipeDetails?: RackItemInTableModel
	) => {
		downloadPDFDocument(setPageLoading, pipeDetails?.identificationId, type, userDispatch, t)
	}

	private checkDownloadable = (
		setDownloadableState: (value: React.SetStateAction<undefined>) => void,
		pipeId: any,
		typeDoc: string
	) => {
		if (pipeId !== undefined) {
			DocumentService.checkDocumentExist(pipeId, typeDoc).then(data => {
				setDownloadableState(data.currentValue)
			})
		}
	}

	checkDownloadableLeft(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'QUALITY_CERTIFICATE')
	}

	checkDownloadableCenter(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'PIPE_ENDS_MEASUREMENT_REPORT')
	}

	checkDownloadableRight(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'PACKING_LIST')
	}

	formatSubtitle(layout?: string) {
		return layout?.includes('WHIPTAIL') ? 'whiptail_data' : 'yellowtail_data'
	}

	formatDataType() {
		return 'yellowtail-data'
	}

	getCardInfo(pipeDetails: RackItemInTableModel): JSX.Element {
		return <YellowtailPipeDetailsCardInfo yellowtailPipe={pipeDetails} />
	}

	getConvertFromGenericProductoDto(
		genericProduct: GenericProductDto,
		identificationId?: string
	): RackItemInTableModel {
		return DoFactory.convertFromGenericProductoDtoToYellowtailRackItemInTableModel(genericProduct, identificationId!)
	}

	isTail(): boolean {
		return true
	}

	isVam(): boolean {
		return false
	}

	getFilesDownloadColumns(
		className: string,
		pipeDetails: RackItemInTableModel,
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		downloadable1: any,
		downloadable2: any,
		downloadable3?: any
	): FilesDownloadable[] {
		return [
			buildFilesDownloadColumn(
				'Download Certificate',
				'certificate',
				downloadable1,
				className,
				'downloadCertificat',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'QUALITY_CERTIFICATE'
			),
			buildFilesDownloadColumn(
				'download_pem_report',
				'downloadPEMReport',
				downloadable2,
				className,
				'downloadPEMReport',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'PIPE_ENDS_MEASUREMENT_REPORT'
			),
			buildFilesDownloadColumn(
				'download_packing_list',
				'downloadPackingList',
				downloadable3,
				className,
				'downloadPackingList',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'PACKING_LIST'
			)
		]
	}

	buildColumns(
		t: TFunction<'translation'>,
		userState: UserState,
		chooseUnitLengthMmtoFt?: string,
		chooseUnitLengthMmtoInch?: string,
		chooseUnitWeight?: string
	): ColumnContent[] {
		return this.columnNames.map(columnName => {
			if (columnName === 'length') {
				return buildLengthColumn(this.dataType, t, userState, chooseUnitLengthMmtoFt)
			} else if (columnName === 'weight') {
				return buildWeightColumn(this.dataType, t, userState, chooseUnitWeight)
			}

			return buildNonSpecificColumn(this.dataType, columnName, t)
		})
	}
}
