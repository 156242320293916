/** @format */

import { Box, createStyles, IconButton, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PipeService from '../api/PipeService'
import Card from '../components/common/Card'
import FilterableTable from '../components/common/FilterableTable'
import { GenericProductDto } from '../models/dto/GenericProductDto'
import { RackItemInTableModel } from '../models/RackItemInTableModel'
import { AppContext } from '../stores/AppStore'
import { RackContext } from '../stores/RackStore'
import { UserContext } from '../stores/UserStore'
import theme from '../utils/theme'
import FilesDownload from '../components/common/FilesDownload'
import { FilesDownloadable } from '../models/FilesDownloadable'
import { useHistory } from 'react-router-dom'
import { contextPageDetailStrategy } from '../business/pageDetailStrategy/ContextpageDetailStrategy'

const useStyles = makeStyles(() =>
	createStyles({
		titleCardInfo: {
			borderLeftWidth: '8px',
			borderLeftStyle: 'solid',
			borderLeftColor: theme.colors.white,
			textAlign: 'left',
			padding: '8px 0px 8px 11px',
			color: theme.colors.white
		},
		titleFilesDownload: {
			borderLeftWidth: '8px',
			borderLeftStyle: 'solid',
			borderLeftColor: theme.colors.darkBlue,
			textAlign: 'left',
			padding: '8px 0px 8px 11px',
			color: theme.colors.darkBlue
		},
		pipeDetailsCardInfo: {
			padding: '16px 16px 0px 16px'
		},
		filesDownload: {
			background: '#FFFFFF 0% 0% no-repeat padding-box',
			boxShadow: '0px 2px 4px #03071C24',
			borderRadius: '4px'
		},
		divCardInfo: {
			flex: '1'
		},
		divCard: {
			display: 'flex',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
		},
		backArrowButton: {
			color: theme.colors.white,
			paddingRight: '5px'
		},
		iconButton: {
			letterSpacing: '0.03px',
			color: theme.colors.white,
			background: 'transparent linear-gradient(99deg, #009EDE 0%, #1A2981 100%) 0% 0% no-repeat padding-box',
			borderRadius: '4px',
			fontSize: '16px',
			fontWeight: 'bold',
			padding: '8px 24px',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
		}
	})
)

const PipeDetails = (props: any) => {
	const { t } = useTranslation()
	const { appState, appDispatch } = useContext(AppContext)
	const { rackState, rackDispatch } = useContext(RackContext)
	const { userState, userDispatch } = useContext(UserContext)
	const history = useHistory()

	const [pipeList] = useState(rackState.pipeDetails)
	const [pipeDetails, setPipeDetails] = useState(new RackItemInTableModel())

	const [downloadableLeft, setDownloadableLeft] = useState(undefined)
	const [downloadableCenter, setDownloadableCenter] = useState(undefined)
	const [downloadableRight, setDownloadableRight] = useState(undefined)

	const userLayout = userState.user.layout
	const contentColumnStrategy = contextPageDetailStrategy(userLayout)

	const classes = useStyles()

	const setPageLoading = (pageLoading: boolean) => {
		appDispatch({ type: 'set_page_loading', pageLoading })
	}

	useEffect(() => {
		appDispatch({ type: 'set_page_subtitle', pageSubTitle: t(contentColumnStrategy.formatSubtitle(userLayout)) })
		if (!rackState.pipeDetails || rackState.pipeDetails.length === 0) {
			const identificationId = props.match.params.identificationId
			PipeService.getPipeByIdentificationId(identificationId).then((genericProduct: GenericProductDto) => {
				const pipe = contentColumnStrategy.getConvertFromGenericProductoDto(genericProduct, identificationId)
				setPipeDetails(pipe)
			})

			// When reaching the page, search if documents exist
			contentColumnStrategy.checkDownloadableLeft(identificationId, setDownloadableLeft)
			contentColumnStrategy.checkDownloadableCenter(identificationId, setDownloadableCenter)
			contentColumnStrategy.checkDownloadableRight(identificationId, setDownloadableRight)
		} else {
			const identificationId = rackState.pipeDetails[0].identificationId

			// When reaching the page, search if documents exist
			contentColumnStrategy.checkDownloadableLeft(identificationId, setDownloadableLeft)
			contentColumnStrategy.checkDownloadableCenter(identificationId, setDownloadableCenter)
			contentColumnStrategy.checkDownloadableRight(identificationId, setDownloadableRight)

			setPipeDetails(rackState.pipeDetails[0])
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appDispatch, props.match.params.identificationId, rackState.pipeDetails, t])

	const chooseUnitLengthMmtoInch = userState.checkedLength ? t('inch') : t('mm')
	const chooseUnitLengthMmtoFt = userState.checkedLength ? t('ft') : t('mm')
	const chooseUnitWeight = userState.checkedWeight ? t('lb') : t('kg')

	const rackColumns = contentColumnStrategy.buildColumns(
		t,
		userState,
		chooseUnitLengthMmtoFt,
		chooseUnitLengthMmtoInch,
		chooseUnitWeight
	)

	const filesDownloadColumns: FilesDownloadable[] = contentColumnStrategy.getFilesDownloadColumns(
		classes.iconButton,
		pipeDetails,
		setPageLoading,
		userDispatch,
		t,
		downloadableLeft,
		downloadableCenter,
		downloadableRight
	)

	return (
		<>
			{appState.pageLoading ? (
				<div />
			) : (
				<Box flexWrap="wrap" width="calc(100% - 1rem)">
					<Box flexWrap="wrap" width="calc(100% - 1rem)">
						<IconButton
							id="back-icon"
							className={classes.iconButton}
							onClick={() => {
								history.goBack()
							}}
							data-testid="backButton"
							style={{ paddingLeft: '5px', paddingRight: '10px', margin: '10px 33px' }}
						>
							<div style={{ color: theme.colors.white, display: 'contents' }}>
								<ArrowBackIcon className={classes.backArrowButton} />
								{t('back')}
							</div>
						</IconButton>
						<div className={classes.divCard} id="CardPipeDetails">
							<Card
								className={classes.divCardInfo}
								id="ProductDescription"
								alignSelf="stretch"
								padding="0px 16px 16px 0px"
								margin="16px 0px 16px 33px"
								width="55%"
								background="transparent linear-gradient(100deg, #1A2981 0%, #030B42 100%) 0% 0% no-repeat padding-box"
								borderRadius="6px"
								boxShadow="0px 2px 4px #03071C24"
								color={theme.colors.white}
							>
								<div style={{ height: 'auto' }}>
									<h3 className={classes.titleCardInfo}> {t('Product Description')} </h3>
									<div className={classes.pipeDetailsCardInfo}>
										{contentColumnStrategy.getCardInfo(pipeDetails)}
									</div>
								</div>
							</Card>
							<Card
								className={[classes.filesDownload, classes.divCardInfo].join(' ')}
								id="FilesDownload"
								alignSelf="stretch"
								padding="0px 16px 16px 0px"
								margin="16px 0px 16px 33px"
								width="45%"
								borderRadius="6px"
							>
								<div style={{ height: 'auto' }}>
									<h3 className={classes.titleFilesDownload}> {t('files_download')} </h3>
									<div className={classes.pipeDetailsCardInfo}>
										<FilesDownload columns={filesDownloadColumns} />
									</div>
								</div>
							</Card>
						</div>
						<Card alignSelf="stretch" margin="23px 33px" width="calc(100% - 2rem)">
							<FilterableTable
								columns={rackColumns}
								data={pipeList}
								isPipeDetails
								vam={contentColumnStrategy.isVam()}
								yellowtail={contentColumnStrategy.isTail()}
								getTableName={() => 'pipe_details'}
								getCurrentPageSize={(reference: string) => {
									return rackState.pageSizeMap.get(reference) === undefined
										? 10
										: rackState.pageSizeMap.get(reference)
								}}
								setCurrentPageSize={(pageSize: number, reference: string) =>
									rackDispatch({ type: 'set_page_size', pageSize, reference })
								}
								title={t(contentColumnStrategy.formatSubtitle(userLayout))}
								activeRadioButton={pipeList.length > 1 ? true : false}
								displayPagination={false}
							/>
						</Card>
					</Box>
				</Box>
			)}
		</>
	)
}

export default PipeDetails
