/** @format */

import i18n from '../../../i18n'
import { RackItemInTableModel } from '../../../models/RackItemInTableModel'
import { MeasureUtils } from '../../../utils/MeasureUtils'
import PopoverFilter from '../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../stores/RackStore'
import { UserState } from '../../../stores/UserStore'
import { ColumnContent } from '../RackContentStrategy'

export const buildLengthTailColumn = (
	dataType: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	userState: UserState,
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
	chooseUnitLengthMmtoFt: string | undefined
): ColumnContent => {
	return {
		title:
			i18n.language === 'en'
				? t(dataType + '-data.length_en.name') + chooseUnitLengthMmtoFt
				: t(dataType + '-data.length.name') + chooseUnitLengthMmtoFt,
		field: 'length',
		render: (rowData: RackItemInTableModel) => (
			<span>
				{rowData && rowData.length && userState.checkedLength
					? MeasureUtils.convertValueFromMMToFt(rowData.length)
					: rowData.length}
			</span>
		),
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.length.filter') === 'alphanumeric'}
				onFilterChanged={onFilterChange('length')}
				columnField={'length'}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
				stateCheckedSettings={userState.checkedLength}
				nameOfColumnToConvert={'length'}
				currentPortal={dataType}
			/>
		)
	}
}
