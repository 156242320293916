/** @format */

import React, { useContext } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import LoadingOverlay from './components/common/LoadingOverlay'
import Header from './containers/layout/AppHeader'
import PrivateRoute from './containers/router/PrivateRoute'
import Login from './pages/Login'
import PipeDetails from './pages/PipeDetails'
import RackContent from './pages/RackContent'
import UploadCertificate from './pages/UploadCertificate'
import { AppContext } from './stores/AppStore'
import UploadDocument from './pages/UploadDocument'
import NaPipes from './pages/OctgContent'
import Slabs from './pages/SlabContent'
import DownloadValid from './pages/DownloadValid'
import UploadJointData from './pages/UploadJointData'
import UploadPackingList from './pages/UploadPackingList'

const Router = () => {
	const { appState } = useContext(AppContext)

	return (
		<BrowserRouter>
			<Header />
			<LoadingOverlay visible={appState.pageLoading} />
			<Switch>
				<Route exact path="/" component={Login} />
				<PrivateRoute path="/rack-content" component={RackContent} />
				<PrivateRoute path="/rack-content-vam" component={RackContent} />
				<PrivateRoute path="/rack-content-uaru" component={RackContent} />
				<PrivateRoute path="/rack-content-whiptail" component={RackContent} />
				<PrivateRoute path="/upload-certificate" component={UploadCertificate} />
				<PrivateRoute path="/pipe/:identificationId" component={PipeDetails} />
				<PrivateRoute path="/accessory/:identificationId" component={PipeDetails} />
				<PrivateRoute path="/uaruPipe/:identificationId" component={PipeDetails} />
				<PrivateRoute path="/whiptailPipe/:identificationId" component={PipeDetails} />
				<PrivateRoute path="/upload-document" component={UploadDocument} />
				<PrivateRoute path="/octg-content" component={NaPipes} />
				<PrivateRoute path="/slabs-content" component={Slabs} />
				<PrivateRoute path="/download-valid" component={DownloadValid} />
				<PrivateRoute path="/upload-joint-data" component={UploadJointData} />
				<PrivateRoute path="/upload-packing-list" component={UploadPackingList} />
				<Route>
					<Redirect to="/" />
				</Route>
			</Switch>
		</BrowserRouter>
	)
}

export default Router
