/** @format */

import { RackItemInTableModel } from '../../../../models/RackItemInTableModel'
import { MeasureUtils } from '../../../../utils/MeasureUtils'
import PopoverFilter from '../../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../../stores/RackStore'
import { UserState } from '../../../../stores/UserStore'
import { ColumnContent } from '../../RackContentStrategy'
import i18n from '../../../../i18n'

export const buildWeightTailColumn = (
	dataType: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	userState: UserState,
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
	chooseUnitWeight: string | undefined
): ColumnContent => {
	return {
		title:
			i18n.language === 'en'
				? t(dataType + '-data.weight_en.name') + chooseUnitWeight
				: t(dataType + '-data.weight.name') + chooseUnitWeight,
		field: 'weight',
		render: (rowData: RackItemInTableModel) => (
			<span>
				{rowData && rowData.weight && userState.checkedWeight
					? MeasureUtils.convertValueFromKgToLbs(rowData.weight)
					: rowData.weight}
			</span>
		),
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.weight.filter') === 'alphanumeric'}
				onFilterChanged={onFilterChange('weight')}
				columnField={'weight'}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
				stateCheckedSettings={userState.checkedWeight}
				nameOfColumnToConvert={'weight'}
			/>
		)
	}
}
