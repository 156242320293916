import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { makeStyles, Typography } from '@material-ui/core';


const useStyles = makeStyles((t) => ({
      root : {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
            minWidth: 300,
      }
}));


const UploadCertificatePopup = (props: any) => {
      const classes = useStyles();
      const { t } = useTranslation();

      const [marker, setMarker] = useState('');

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setMarker(event.target.value);
      };
   
      return (
            <div >
                  <Dialog open={ props.open }  aria-labelledby="form-dialog-title" >

                        <DialogContent className={classes.root}>
                              <Typography variant="h5" gutterBottom>
                                    {t('File to load')}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                    { props.filename }
                              </Typography>
                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('Associated SERIMAX ID')}
                              </Typography>
                        
                              <TextField
                                    id="serimaxID-Text"
                                    label=""
                                    variant="outlined"
                                    value= {marker}
                                    onChange={ handleChange }
                              />
                        
                         </DialogContent>
                        <DialogActions style={{marginTop:'30px'}}>
                        <Button onClick={ props.handleCancel } color="primary">
                              { t('Cancel')}
                        </Button>
                        <Button onClick={ () => props.handleLoad(marker) } disabled = {marker.length === 0 } color="primary">
                              { t('Load')}
                        </Button>
                        </DialogActions>
                  </Dialog>
            </div>
      );
}

export default UploadCertificatePopup;