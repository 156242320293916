import { instance, getHeader, manageResponse } from './ApiManager';



const requests = {

    getToken : async (mobileId: string, password: string, rememberMe: boolean, username: string) => {
        const response = await instance.post('/tgp-token', {
            mobileId, password, rememberMe, username,
        })
        return manageResponse(response)
    },
    signIn : async (login: string, password: string) => {
        const response = await instance.post('/tgp-users/auth', { login, password }, getHeader())
        return manageResponse(response)
    },
    sendTemporaryPassword : async (login: string) => {
        const response = await instance.post('/tgp-users/forgot-password', { login })
        return manageResponse(response)
    },
    changePassword : async (oldPassword: string, newPassword: string) => {
        const response = await instance.post('/tgp-users/change-password', { oldPassword, newPassword }, getHeader())
        return manageResponse(response)
    }
}

export default requests