/** @format */

export type SectionItem = {
	label: string
	keys?: SectionItem[]
}

export type Section = {
	label: string
	keys: SectionItem[]
}
export default class ColumnsOrdering {
	public static sections: Section[] = [
		{
			label: 'rack_content',
			keys: [
				{
					label: 'rackId'
				},
				{
					label: 'marker'
				},
				{
					label: 'identificationId'
				},
				{
					label: 'businessUnit'
				},
				{
					label: 'project'
				},
				{
					label: 'manufacturer'
				},
				{
					label: 'outerDiameter'
				},
				{
					label: 'outerDiameterInch'
				},
				{
					label: 'internalDiameter'
				},
				{
					label: 'wallThickness'
				},
				{
					label: 'wallThicknessClad'
				},
				{
					label: 'genericGrade'
				},
				{
					label: 'pipeCount'
				},
				{
					label: 'length'
				},
				{
					label: 'jointNumber'
				}
			]
		},
		{
			label: 'octg_content',
			keys: [
				{
					label: 'jointNumber'
				},
				{
					label: 'pipeIdentificationNumber'
				},
				{
					label: 'prepJob'
				},
				{
					label: 'contractNo'
				},
				{
					label: 'orderNumber'
				},
				{
					label: 'itemNumber'
				},
				{
					label: 'itemDescription'
				},
				{
					label: 'pipeOd'
				},
				{
					label: 'weight'
				},
				{
					label: 'grade'
				},
				{
					label: 'connectionType'
				},
				{
					label: 'pipeMul'
				},
				{
					label: 'fullLength'
				},
				{
					label: 'slnNumber'
				},
				{
					label: 'slnJoint'
				},
				{
					label: 'millJointNumber'
				},
				{
					label: 'jointStatus'
				},
				{
					label: 'jointStatusWho'
				},
				{
					label: 'jointStatusWhen'
				},
				{
					label: 'dopeWho'
				},
				{
					label: 'dopeWhen'
				},
				{
					label: 'dopeType'
				},
				{
					label: 'dateOfManufactureText'
				}
			]
		},
		{
			label: 'vamRack_content',
			keys: [
				{
					label: 'rackId'
				},
				{
					label: 'identificationId'
				},
				{
					label: 'serialNumber'
				},
				{
					label: 'accessoryType'
				},
				{
					label: 'customer'
				},
				{
					label: 'salesOrder'
				},
				{
					label: 'workOrder'
				},
				{
					label: 'end1OuterDiameter'
				},
				{
					label: 'end1weight'
				},
				{
					label: 'end1SteelGrade'
				},
				{
					label: 'end1ConnectionType'
				},
				{
					label: 'end1SurfaceTreatment'
				},
				{
					label: 'end2OuterDiameter'
				},
				{
					label: 'end2weight'
				},
				{
					label: 'end2SteelGrade'
				},
				{
					label: 'end2ConnectionType'
				},
				{
					label: 'end2SurfaceTreatment'
				},
				{
					label: 'length'
				},
				{
					label: 'heatNumber'
				},
				{
					label: 'accessoryCount'
				},
				{
					label: 'jointNumber'
				}
			]
		},
		{
			label: 'yellowtailRack_content',
			keys: [
				{
					label: 'rackId'
				},
				{
					label: 'tally'
				},
				{
					label: 'identificationId'
				},
				{
					label: 'ippn'
				},
				{
					label: 'vsbOrder'
				},
				{
					label: 'ytCustomerItem'
				},
				{
					label: 'outerDiameter'
				},
				{
					label: 'internalDiameter'
				},
				{
					label: 'wallThickness'
				},
				{
					label: 'genericGrade'
				},
				{
					label: 'length'
				},
				{
					label: 'weight'
				},
				{
					label: 'pipeCount'
				}
			]
		},
		{
			label: 'slab_content',
			keys: [
				{
					label: 'slabIdHeatPlusNumber'
				},
				{
					label: 'hkmPieceId'
				},
				{
					label: 'customerTagId'
				},
				{
					label: 'heat'
				},
				{
					label: 'customerGrade'
				},
				{
					label: 'orderNumber'
				},
				{
					label: 'lineNumber'
				},
				{
					label: 'nominalWidth'
				},
				{
					label: 'nominalThickness'
				},
				{
					label: 'nominalLength'
				},
				{
					label: 'nominalWeight'
				},
				{
					label: 'actualThickness'
				},
				{
					label: 'actualWidth'
				},
				{
					label: 'actualWeight'
				},
				{
					label: 'actualLength'
				},
				{
					label: 'blDate'
				},
				{
					label: 'standardGrade'
				},
				{
					label: 'qualityCheck'
				}
			]
		}
	]
}
