/** @format */

import { RackContentStrategy, ColumnContent } from './RackContentStrategy'
import { PipeItemDto } from '../../models/dto/PipeItemDto'
import { buildRackIdColumn } from './columnBuilder/buildRackIdColumn'
import { TFunction } from 'react-i18next'
import { RackState } from '../../stores/RackStore'
import { UserState } from '../../stores/UserStore'
import { buildLengthTailColumn } from './columnBuilder/buildLengthColumn'
import { buildWeightTailColumn } from './columnBuilder/tailColumn/buildWeightTailColumn'
import { buildNonSpecificColumn } from './columnBuilder/buildNonSpecificColumn'
import { buildMetricColumn } from './columnBuilder/tailColumn/buildMetricColumn'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'

export class TailRackContentStrategy implements RackContentStrategy {
	private readonly columnNames = [
		'rackId',
		'tally',
		'identificationId',
		'ippn',
		'vsbOrder',
		'ytCustomerItem',
		'outerDiameter',
		'internalDiameter',
		'wallThickness',
		'genericGrade',
		'length',
		'weight',
		'pipeCount'
	]
	private readonly dataType = 'yellowtail'

	private unitLenght = ''

	formatSubtitle(layout?: string) {
		return layout?.includes('WHIPTAIL') ? 'whiptail_data' : 'yellowtail_data'
	}

	formatDataType() {
		return 'yellowtail-data'
	}

	redirectionUrl(pipeId: string, layout?: string): string {
		return layout?.includes('WHIPTAIL') ? '/whiptailPipe/' + pipeId : '/uaruPipe/' + pipeId
	}

	getNumberOfItemsInRack(itemDataList: PipeItemDto[]): number {
		return itemDataList.length
	}

	getPipeCount(): number {
		return 1
	}

	createPipeDetail(rackState: RackState, pipeId: string): RackItemInTableModel[] {
		// Add the selected pipe
		let pipeDetails = rackState.rackData.filter(pipe => pipeId === pipe.identificationId)

		return pipeDetails
	}

	isTail(): boolean {
		return true
	}

	isVam(): boolean {
		return false
	}

	setunitlenght(lenght: string) {
		this.unitLenght = lenght
	}

	getColumnNames(): string[] {
		return this.columnNames
	}

	buildColumns(
		t: TFunction<'translation'>,
		rackState: RackState,
		userState: UserState,
		onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
		chooseUnitLengthMmtoInch: string,
		headerBackgroundColor: () => string,
		expandSide2?: boolean,
		chooseUnitLengthMmtoFt?: string,
		chooseUnitWeight?: string
	): ColumnContent[] {
		return this.columnNames.map(columnName => {
			if (columnName === 'rackId') {
				return buildRackIdColumn(this.dataType, t, rackState, onFilterChange)
			} else if (columnName === 'length') {
				return buildLengthTailColumn(this.dataType, t, rackState, userState, onFilterChange, chooseUnitLengthMmtoFt)
			} else if (columnName === 'weight') {
				return buildWeightTailColumn(this.dataType, t, rackState, userState, onFilterChange, chooseUnitWeight)
			} else if (['outerDiameter', 'internalDiameter', 'wallThickness'].includes(columnName)) {
				return buildMetricColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					userState,
					onFilterChange,
					chooseUnitLengthMmtoInch
				)
			}
			return buildNonSpecificColumn(this.dataType, columnName, t, rackState, onFilterChange)
		})
	}
}
