/** @format */

import { getHeader, instance, manageResponse, manageUploadResponse } from './ApiManager'

const requests = {
	getDocumentData: async (identificationId: string, type: string) => {
		const response = await instance.post('/tgp-document/get', { identificationId, type }, getHeader())
		return manageResponse(response)
	},
	uploadDocument: async (valid: string, filename: string, content: string, documentType: string) => {
		const response = await instance.post(
			'/tgp-document/upload',
			{ content, filename, valid, documentType },
			getHeader()
		)
		return manageUploadResponse(response)
	},
	checkDocumentExist: async (identificationId: string, type: string) => {
		const response = await instance.post(`/tgp-document/exist`, { identificationId, type }, getHeader())
		return manageResponse(response)
	}
}

export default requests
