/** @format */

import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CardInfo from '../../components/common/CardInfo'
import i18n from '../../i18n'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import { UserContext } from '../../stores/UserStore'
import { MeasureUtils } from '../../utils/MeasureUtils'

type PipeDetailsCardInfoProps = {
	pipe: RackItemInTableModel;
}

const PipeDetailsCardInfo = (props: PipeDetailsCardInfoProps) => {
	const { t } = useTranslation()
	const { userState } = useContext(UserContext)

	const key = 'serimax-details.'
	const chooseUnitLength = userState.checkedLength ? t('inch') : t('mm')

	const displayColumnValue = (nameOfColumn: any) => {
	    if(nameOfColumn){
	        return userState.checkedLength ? MeasureUtils.convertValueFromMMToInch(nameOfColumn) : MeasureUtils.toFixed(nameOfColumn, 2)
	    }
        return ''
	}

	const info = [
		{
			label: t(key + 'outerDiameter.name'),
			value: props.pipe.outerDiameter ? props.pipe.outerDiameter.toString() : ''
		},
		{
			label: t(key + 'outerDiameterInch.name'),
			value: props.pipe.outerDiameterInch ? props.pipe.outerDiameterInch.toString() : ''
		}
	]

	if (props.pipe.wallThicknessClad) {
		info.push(
			{
				label: i18n.language === 'en' ?
					(t(key + 'wallThicknessCarbonSteel_en.name') + chooseUnitLength) :
					(t(key + 'wallThicknessCarbonSteel.name') + chooseUnitLength),
				value: displayColumnValue(props.pipe.wallThickness)
			},
			{
				label: i18n.language === 'en' ?
					(t(key + 'wallThicknessClad_en.name') + chooseUnitLength) :
					(t(key + 'wallThicknessClad.name') + chooseUnitLength),
				value: displayColumnValue(props.pipe.wallThicknessClad)
			}
		)
	} else {
		info.push({
			label: i18n.language === 'en' ?
				(t(key + 'wallThickness_en.name') + chooseUnitLength) :
				(t(key + 'wallThickness.name') + chooseUnitLength),
			value: displayColumnValue(props.pipe.wallThickness)
		})
	}

	info.push(
		{
			label: i18n.language === 'en' ?
				(t(key + 'internalDiameter_en.name') + chooseUnitLength) :
				(t(key + 'internalDiameter.name') + chooseUnitLength),
			value: displayColumnValue(props.pipe.internalDiameter)
		},
		{ label: t(key + 'grade.name'), value: props.pipe.genericGrade ? props.pipe.genericGrade.toString() : '' }
	)

	return (
		<div>
			<CardInfo info={info} />
		</div>
	)
}

export default PipeDetailsCardInfo
