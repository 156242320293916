import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import { Box, makeStyles } from '@material-ui/core';
import theme from '../../utils/theme';

const useStyles = makeStyles({
  errorStyle: {
    fontSize: theme.fontSizes.small,
    color: theme.colors.metalic,
    margin: '5px 0px',
  },
  iconStyle: {
    marginRight: '8px',
  },
});

const ErrorMessage = (param: any) => {
  const classes = useStyles();

  return (
    <Box display='flex' flexDirection='row' alignItems='center' className={classes.errorStyle}>
      <ErrorIcon fontSize='small' className={classes.iconStyle}>Error</ErrorIcon>
      {param.children}
    </Box>
  );
};

export default ErrorMessage;
