/** @format */

import { GenericProductDto } from './dto/GenericProductDto'
import { RackItemInTableModel } from './RackItemInTableModel'
import { JointElementDto } from './dto/JointElementDto'
import { PipeDto } from './dto/PipeDto'

class DoFactory {
	public static MM_TO_INCH_FACTOR: number = 25.4

	/**
	 * @param genericProduct The GenericProductDto returned by the backend
	 * @param wantedIdentificationId The identificationId of the wanted pipe, in case generic product is a multi-joint
	 * @returns The wanted pipe, converted as a RackItemInTableModel
	 */
	static convertFromGenericProductoDtoToRackItemInTableModel = function (
		genericProduct: GenericProductDto,
		wantedIdentificationId: string
	): RackItemInTableModel {
		const rackItem: RackItemInTableModel = new RackItemInTableModel()

		let pipe: PipeDto | undefined = undefined

		if (genericProduct.joint) {
			// Multi joint case

			rackItem.jointNumber = genericProduct.joint.jointNumber.defaultValue

			// Find in the joint list the one corresponding to wantedPipe
			pipe = genericProduct.joint.jointElementData.find(
				(jed: JointElementDto) => jed.pipe.identificationId.defaultValue === wantedIdentificationId
			)?.pipe
		} else {
			// Single pipe case
			pipe = genericProduct.pipe
		}

		if (pipe) {
			// Set labPipe values if present
			if (pipe.labPipe) {
				rackItem.project = pipe.labPipe.project.defaultValue
				rackItem.marker = pipe.labPipe.marker.defaultValue
				rackItem.businessUnit = pipe.labPipe.businessUnit.defaultValue
				rackItem.manufacturer = pipe.labPipe.manufacturer.defaultValue
				rackItem.prematerial = pipe.labPipe.prematerial.defaultValue
			}

			// Set orderItem values if present
			if (pipe.orderItem) {
				const orderItem = pipe.orderItem
				rackItem.wallThickness = orderItem.wallThickness.defaultValue
				rackItem.outerDiameter = orderItem.outerDiameter.defaultValue
				rackItem.outerDiameterInch = orderItem.outerDiameter.defaultValue
					? DoFactory.getValueFromMMToInch(orderItem.outerDiameter.defaultValue)
					: undefined
				rackItem.internalDiameter = orderItem.internalDiameter.defaultValue
				rackItem.genericGrade = orderItem.genericGrade.defaultValue
			}

			rackItem.heatNumber = pipe.heat && pipe.heat.label.defaultValue
			rackItem.identificationId = pipe.identificationId.defaultValue
			rackItem.length = pipe.length.defaultValue
		}

		return rackItem
	}

	/**
	 *
	 * @param genericProduct The GenericProductDto returned by the backend
	 * @returns The wanted accessory, converted as a VamRackItemInTableModel
	 */
	static convertFromGenericProductoDtoToVamRackItemInTableModel = function (
		genericProduct: GenericProductDto
	): RackItemInTableModel {
		const vamRackItem: RackItemInTableModel = new RackItemInTableModel()

		let pipe: PipeDto | undefined = undefined

		if (genericProduct.joint) {
			// Single pipe case
			pipe = genericProduct.pipe
		}

		if (pipe) {
			// Set labPipe values if present
			if (pipe.accessory) {
				const accessory = pipe.accessory
				vamRackItem.serialNumber = accessory.serialNumber.defaultValue
				vamRackItem.accessoryType = accessory.accessoryType.defaultValue
				vamRackItem.workOrder = accessory.workOrder.defaultValue
				vamRackItem.end1OuterDiameter = accessory.accessoryEnd.outerDiameter.defaultValue
				vamRackItem.end1weight = accessory.accessoryEnd.weight.defaultValue
				vamRackItem.end1ConnectionType = accessory.accessoryEnd.connectionType.defaultValue
				vamRackItem.end1SteelGrade = accessory.accessoryEnd.steelGrade.defaultValue
			}

			// Set orderItem values if present
			if (pipe.accessoryCrossOver) {
				const accessoryCrossOver = pipe.accessoryCrossOver
				vamRackItem.serialNumber = accessoryCrossOver.serialNumber.defaultValue
				vamRackItem.accessoryType = 'Crossover'
				vamRackItem.workOrder = accessoryCrossOver.workOrder.defaultValue
				vamRackItem.end1OuterDiameter = accessoryCrossOver.accessoryEnd1.outerDiameter.defaultValue
				vamRackItem.end1weight = accessoryCrossOver.accessoryEnd1.weight.defaultValue
				vamRackItem.end1ConnectionType = accessoryCrossOver.accessoryEnd1.connectionType.defaultValue
				vamRackItem.end1SteelGrade = accessoryCrossOver.accessoryEnd1.steelGrade.defaultValue
				vamRackItem.end2OuterDiameter = accessoryCrossOver.accessoryEnd2.outerDiameter.defaultValue
				vamRackItem.end2weight = accessoryCrossOver.accessoryEnd2.weight.defaultValue
				vamRackItem.end2ConnectionType = accessoryCrossOver.accessoryEnd2.connectionType.defaultValue
				vamRackItem.end2SteelGrade = accessoryCrossOver.accessoryEnd2.steelGrade.defaultValue
			}

			vamRackItem.heatNumber = pipe.heat && pipe.heat.label.defaultValue
			vamRackItem.identificationId = pipe.identificationId.defaultValue
			vamRackItem.length = pipe.length.defaultValue
		}

		return vamRackItem
	}

	/**
	 *
	 * @param genericProduct The GenericProductDto returned by the backend
	 * @returns The wanted yellowtail pipe, converted as a YellowtailRackItemInTableModel
	 */
	static convertFromGenericProductoDtoToYellowtailRackItemInTableModel = function (
		genericProduct: GenericProductDto,
		identificationId: string
	): RackItemInTableModel {
		const yellowtailRackItem: RackItemInTableModel = new RackItemInTableModel()
		let pipe: PipeDto | undefined = undefined

		if (genericProduct.joint) {
			// Multi joint case

			yellowtailRackItem.jointNumber = genericProduct.joint.jointNumber.defaultValue

			// Find in the joint list the one corresponding to wantedPipe
			pipe = genericProduct.joint.jointElementData.find(
				(jed: JointElementDto) => jed.pipe.identificationId.defaultValue === identificationId
			)?.pipe
		} else {
			// Single pipe case
			pipe = genericProduct.pipe
		}

		if (pipe) {
			// Set orderItem values if present
			if (pipe.orderItem) {
				const orderItem = pipe.orderItem
				yellowtailRackItem.outerDiameter = orderItem.outerDiameter.defaultValue
				yellowtailRackItem.internalDiameter = orderItem.internalDiameter.defaultValue
				yellowtailRackItem.wallThickness = orderItem.wallThickness.defaultValue
				yellowtailRackItem.genericGrade = orderItem.genericGrade.defaultValue
			}

			yellowtailRackItem.vsbId = pipe.tally.defaultValue
			yellowtailRackItem.identificationId = pipe.identificationId.defaultValue
			yellowtailRackItem.ippn = pipe.ippn.defaultValue
			yellowtailRackItem.length = pipe.length.defaultValue
			yellowtailRackItem.weight = pipe.weight.defaultValue
		}

		return yellowtailRackItem
	}

	/**
	 *
	 * @param valueInMm The value in mm
	 * @returns The value in inch
	 */
	static getValueFromMMToInch = function (valueInMm: number | undefined): number | undefined {
		if (valueInMm) {
			return DoFactory.round(valueInMm / DoFactory.MM_TO_INCH_FACTOR)
		}
		return undefined
	}

	/**
	 *
	 * @param value The value to be rounded
	 * @returns The rounded value
	 */
	static round = function (value: number): number {
		return Math.round((value + Number.EPSILON) * 1000) / 1000
	}
}

export default DoFactory
