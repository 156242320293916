/** @format */

import { cloneDeep } from 'lodash'
import { MeasureUtils } from './MeasureUtils'

export class FilterUtils {
	/**
	 *
	 * @param nameOfColumn The name of the column
	 * @param paramFilter The filter to apply
	 * @param filteredColumnsMaps The filtered columns maps
	 */
	static getFilteredColumnMap(
		nameOfColumn: string,
		paramFilter: any,
		filteredColumnsMaps: any,
	): any {
		// clone the content of filtered column map from state
		const filteredColumnsMap = cloneDeep(filteredColumnsMaps)

		const min = paramFilter.min ? paramFilter.min : ''
		const max = paramFilter.max ? paramFilter.max : ''
		const value = paramFilter.value ? paramFilter.value : ''
		const selectedValues = paramFilter.selectedValues ? paramFilter.selectedValues.split('|') : ''

		// construct the filtered map column
		if (
			paramFilter.clear ||
			(min.length === 0 && max.length === 0 && value.length === 0 && selectedValues.length === 0)
		) {
			filteredColumnsMap.delete(nameOfColumn)
		} else {
			filteredColumnsMap.set(nameOfColumn, {
				value: value,
				min: min,
				max: max,
				selectedValues: selectedValues
			})
		}

		return filteredColumnsMap
	}

	/**
	 *
	 * @param tab The table to filter
	 * @param key The key of the column to filter
	 * @param selectedValues The selected values to filter
	 * @param value The value to filter
	 * @param checkedLength The checked length to filter
	 * @param checkedWeight The checked weight to filter
	 * @param min The min value to filter
	 * @param max The max value to filter
	 * @param columnsConvert The columns to convert
	 * @returns The filtered table
	 */
	static tableFilter(
		tab: any,
		key: string,
		selectedValues: any,
		value: any,
		checkedLength: boolean,
		checkedWeight: boolean,
		min: any,
		max: any,
		columnsConvert: Map<string, any>
	) {
		let manyValuesSelected: string[] = []
		let table = tab.filter((row: any) => {
			// Return the racks with their children filtered
			if (row['tableData']['childRows'] !== null && key !== 'rackId') {
				this.rackFilter(row, key, selectedValues, value, checkedLength, checkedWeight, min, max, columnsConvert)
				return row
			}

			let val = String(row[key])
			manyValuesSelected = selectedValues

			if (manyValuesSelected.length === 0) {
				manyValuesSelected = [value]
			}

			val = this.handleConversionColumns(columnsConvert, val, key, row, checkedLength, checkedWeight)

			if (val === 'undefined' && key === 'rackId') {
				val = String(row['parentId'])
				manyValuesSelected = manyValuesSelected.map((element: string) => {
					return 'rack_' + element
				})
			}

			return FilterUtils.compareValAgainstValuesAndMinAndMax(val, manyValuesSelected, min, max)
		})

		// Remove all rows with no children
		table = table.filter((row: any) => {
			if (row['tableData']['childRows'] !== null) {
				row.numberFilteredPipes = row['tableData']['childRows'].length
				if (row.rackId !== undefined) {
					row['tableData']['isTreeExpanded'] = row.numberFilteredPipes > 0
				}
				if (key !== 'pipeCount' && key !== 'rackId') {
					return row.numberFilteredPipes > 0
				} else {
					if (key === 'pipeCount') {
						if (manyValuesSelected.includes(String(row.pipeCount))) {
							return true
						} else {
							const childRows = row.tableData?.childRows
							for (let i = 0; i < childRows?.length; i++) {
								if (manyValuesSelected.includes(String(childRows[i].pipeCount))) {
									return true
								}
							}
						}
						return manyValuesSelected.includes(String(row.pipeCount))
					} else if (key === 'rackId') {
						return manyValuesSelected.includes('rack_' + row.rackId)
					} else {
						return manyValuesSelected.includes(row.rackId)
					}
				}
			}
			return true
		})

		table.forEach((row: any) => {
			if (row['tableData']['childRows']?.length) {
				table = table.concat(row['tableData']['childRows'])
			}
		})

		return table
	}

	/**
	 * Compare currentValue against : selected values and min value and max value
	 * @param val The current value
	 * @param manyValuesSelected The list of selected values
	 * @param min The min value
	 * @param max The max value
	 * @return true or false
	 */
	static compareValAgainstValuesAndMinAndMax(
		val: any,
		manyValuesSelected: any[],
		min: any,
		max: any
	) : boolean {
		if (manyValuesSelected.length > 0 && manyValuesSelected[0] !== '') {
			return manyValuesSelected.includes(val)
		} else {
			if (min.length > 0 && max.length > 0) {
				return +val >= +min && +val <= +max
			} else if (min.length > 0 && max.length === 0) {
				return +val >= +min
			} else if (min.length === 0 && max.length > 0) {
				return +val <= +max
			} else {
				return val
			}
		}
	}

	/**
	 *
	 * @param row The rack to filter
	 * @param key The key of the column to filter
	 * @param selectedValues The selected values to filter
	 * @param value The value to filter
	 * @param checkedLength The checked length to filter
	 * @param checkedWeight The checked weight to filter
	 * @param min The min value to filter
	 * @param max The max value to filter
	 * @param columnsConvert The columns to convert
	 * @returns The filtered rack
	 */
	static rackFilter(
		row: any,
		key: string,
		selectedValues: any,
		value: any,
		checkedLength: boolean,
		checkedWeight: boolean,
		min: any,
		max: any,
		columnsConvert: Map<string, any>
	) {
		row['tableData']['childRows'] = row['tableData']['childRows'].filter((childRow: any) => {
			let val = String(childRow[key])
			let manyValuesSelected = selectedValues

			if (manyValuesSelected.length === 0) {
				manyValuesSelected = [value]
			}

			val = this.handleConversionColumns(columnsConvert, val, key, childRow, checkedLength, checkedWeight)

			if (val === 'undefined' && key === 'rackId') {
				val = String(childRow['parentId'])
				manyValuesSelected = manyValuesSelected.map((element: string) => {
					return 'rack_' + element
				})
			}

			return FilterUtils.compareValAgainstValuesAndMinAndMax(val, manyValuesSelected, min, max)
		})
	}

	/**
	 *
	 * @param filteredColumnMap The filtered column map
	 * @param dataToDisplay The pipes to display
	 * @returns The filtered data
	 */
	static updateFilter(
		filteredColumnMap: Map<string, any>,
		dataToDisplay: any[]
	) {
		FilterUtils.updateFilterWithConvert(
			filteredColumnMap,
			dataToDisplay,
			false, // Arbitrary value, not used because columnsConvert is empty
			false, // Arbitrary value, not used because columnsConvert is empty
			new Map() //  give columnsConvert an empty map
		)
	}

	/**
	 *
	 * @param filteredColumnMap The filtered column map
	 * @param dataToDisplay The pipes to display
	 * @param checkedLength The checked length to filter
	 * @param checkedWeight The checked weight to filter
	 * @param columnsConvert The columns to convert
	 * @returns The filtered pipes
	 */
	static updateFilterWithConvert(
		filteredColumnMap: Map<string, any>,
		dataToDisplay: any[],
		checkedLength: boolean,
		checkedWeight: boolean,
		columnsConvert: Map<string, any>
	) {
		filteredColumnMap.forEach((value: any, key: string) => {
			if (value.selectedValues.length) {
				const allEligibleValuesForFilter = dataToDisplay.map(data => {
					let val = String(data[key])
					val = this.handleConversionColumns(columnsConvert, val, key, data, checkedLength, checkedWeight)
					return val
				})
				// Convert to a set in order to remove duplicates
				const allEligibleValuesForFilterSet: Set<string> = new Set(allEligibleValuesForFilter)

				value.selectedValues = value.selectedValues.filter((v: string) => {
					return allEligibleValuesForFilterSet.has(v)
				})

				if (value.selectedValues.length === 0) {
					filteredColumnMap.delete(key)
				}
			}
		})
	}

	/**
	 *
	 * @param val The value to convert
	 * @param columnsConvert The columns to convert
	 * @param checkedLength The checked length to filter
	 * @param key The key of the column to convert
	 * @param row The row to convert
	 * @returns The converted value mm to inch or not
	 */
	static convertMMToInch(val: any, columnsConvert: string[], checkedLength: boolean, key: any, row: any): any {
		if (this.needLengthAndKey(key, columnsConvert, checkedLength)) {
			val = MeasureUtils.convertValueFromMMToInch(row[key])
		}
		return val
	}

	/**
	 *
	 * @param val The value to convert
	 * @param columnsConvert The columns to convert
	 * @param checkedLength The checked length to filter
	 * @param key The key of the column to convert
	 * @param row The row to convert
	 * @returns The converted value mm to feet or not
	 */
	static convertMMToFeet(val: any, columnsConvert: string[], checkedLength: boolean, key: any, row: any): any {
		if (this.needLengthAndKey(key, columnsConvert, checkedLength)) {
			val = MeasureUtils.convertValueFromMMToFt(row[key])
		}
		return val
	}

	/**
	 *
	 * @param key The key of the column to convert
	 * @param columnsConvert The columns to convert
	 * @param checkedLength The checked length to filter
	 * @returns Whether the key is needed to convert and the checked length is true, imperial system
	 */
	static needLengthAndKey(key: string, columnsConvert: string[], checkedLength: boolean): boolean {
		return checkedLength && columnsConvert.includes(key)
	}

	/**
	 *
	 * @param val The value to convert
	 * @param columnsConvert The columns to convert
	 * @param checkedWeight The checked weight to filter
	 * @param key The key of the column to convert
	 * @param row The row to convert
	 * @returns The converted value or not
	 */
	static convertKgToLbs(val: any, columnsConvert: string[], checkedWeight: boolean, key: any, row: any): any {
		if (this.needWeightAndKey(key, columnsConvert, checkedWeight)) {
			val = MeasureUtils.convertValueFromKgToLbs(row[key])
		}
		return val
	}

	/**
	 *
	 * @param key The key of the column to convert
	 * @param columnsConvert The columns to convert
	 * @param checkedWeight The checked weight to filter
	 * @returns Whether the key is needed to convert and the checked wieght is true, imperial system
	 */
	static needWeightAndKey(key: string, columnsConvert: string[], checkedWeight: boolean): boolean {
		return checkedWeight && columnsConvert.includes(key)
	}

	/**
	 *
	 * @param columnsConvert The columns to convert
	 * @param val The value to convert
	 * @param key The key of the column to convert
	 * @param row The row to convert
	 * @param checkedLength The checked length to filter
	 * @param checkedWeight The checked weight to filter
	 * @returns The converted value or not
	 */
	static handleConversionColumns(
		columnsConvert: Map<string, any>,
		val: any,
		key: string,
		row: any,
		checkedLength: boolean,
		checkedWeight: boolean
	) {
		columnsConvert.forEach((column, portal) => {
			switch (portal) {
				case 'serimax':
					val = this.convertMMToInch(val, column[0], checkedLength, key, row)
					break
				case 'vam':
					val = this.convertMMToFeet(val, column[0], checkedLength, key, row)
					break
				case 'yellowtail':
					if (key === 'length') {
						val = this.convertMMToFeet(val, column[0], checkedLength, key, row)
						break
					} else {
						val = this.convertMMToInch(val, column[0], checkedLength, key, row)
						val = this.convertKgToLbs(val, column[1], checkedWeight, key, row)
						break
					}
				case 'octg':
					val = this.convertMMToFeet(val, column[0], checkedLength, key, row)
					break
			}
		})
		return val
	}
}
