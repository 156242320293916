/** @format */

import PopoverFilter from '../../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../../stores/RackStore'
import { ColumnContent } from '../../RackContentStrategy'

export const buildVamSpecificColumn = (
	dataType: string,
	columnName: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	expandSide2: boolean | undefined,
	end1: string,
	end2: string,
	headerStyle: {
		pointerEvents: string
		backgroundColor: string
		borderRight?: string
		borderLeft?: string
	},
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void
): ColumnContent => {
	return {
		title: t(dataType + '-data.' + columnName + '.name'),
		field: columnName,
		hidden: columnName === end1 ? false : expandSide2 && columnName === end2 ? false : true,
		headerStyle: headerStyle,
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.' + columnName + '.name') === 'alphanumeric'}
				onFilterChanged={onFilterChange(columnName)}
				columnField={columnName}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
			/>
		)
	}
}
