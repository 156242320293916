/** @format */

import DoFactory from '../models/DoFactory'

export class MeasureUtils {
	/**
	 *
	 * @param valueInMM The value to convert, in millimeters
	 * @returns The converted value in feet
	 */
	static convertValueFromMMToFt = (valueInMM: number): string => {
		return (valueInMM / 304.8).toFixed(2)
	}

	/**
	 * @param valueInFt The value to convert, in feet
	 * @returns The converted value in millimeters
	 */
	static convertValueFromFtToMM = (valueInFt: number): string => {
		return (valueInFt * 304.8).toFixed(2)
	}

	/**
	 *
	 * @param valueInMM The value to convert, in millimeters
	 * @returns The converted value in inch
	 */
	static convertValueFromMMToInch = (valueInMM: number): string => {
		return (valueInMM / DoFactory.MM_TO_INCH_FACTOR).toFixed(2)
	}

	/**
	 * 
	 * @param valueInInch The value to convert, in inches
	 * @returns The converted value in millimeters
	 */
	static convertValueFromInchToMM = (valueInInch: number): string => {
		return (valueInInch * DoFactory.MM_TO_INCH_FACTOR).toFixed(2)
	}

	/**
	 *
	 * @param valueInMM The value to convert, in kilograms
	 * @returns The converted value in pounds
	 */
	static convertValueFromKgToLbs = (valueInKg: number): string => {
		return (valueInKg * 2.20462).toFixed(0)
	}

	/**
	 * 
	 * @param value The value to round up
	 * @param precision The precision to round up to
	 * @returns The rounded up value
	 */
	static toFixed = (value: number, precision: number): string => {
		return value.toFixed(precision)
	}
}
