/** @format */

import { TFunction } from 'react-i18next'
import { FilesDownloadable } from '../../../models/FilesDownloadable'
import { IconButton } from '@material-ui/core'
import { GetAppRounded } from '@material-ui/icons'
import theme from '../../../utils/theme'
import { Action } from '../../../stores/UserStore'
import { RackItemInTableModel } from '../../../models/RackItemInTableModel'

export const buildFilesDownloadColumn = (
	title: string,
	id: string,
	downloadable: any,
	className: string,
	testid: string,
	pipeDetails: RackItemInTableModel,
	downloadDocument: (
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		type: string,
		pipeDetails?: RackItemInTableModel
	) => void,
	setPageLoading: (pageLoading: boolean) => void,
	userDispatch: React.Dispatch<Action>,
	t: TFunction<'translation'>,
	type: string
): FilesDownloadable => {
	return {
		title: t(title),
		id: id,
		downloadable: downloadable,
		render: (
			<IconButton
				className={className}
				onClick={() => {
					downloadDocument(setPageLoading, userDispatch, t, type, pipeDetails)
				}}
				data-testid={testid}
				disabled={downloadable ? false : true}
			>
				<div style={{ color: theme.colors.white, display: 'contents' }}>
					{t('download')}
					<GetAppRounded style={{ paddingLeft: '4px' }} />
				</div>
			</IconButton>
		)
	}
}
