import React, {SyntheticEvent, useContext, useEffect, useRef, useState} from 'react'
import Input from '../../components/form/Input'
import ErrorMessage from '../../components/form/ErrorMessage'
import Checkbox from '../../components/form/Checkbox'
import {useTranslation} from 'react-i18next'
import {LoginFormParam} from '../../models/LoginFormParam'
import {UserContext} from '../../stores/UserStore'
import LoginService from '../../api/LoginService'
import Cookies from 'js-cookie'
import BusinessErrorException from '../../models/exception/BusinessErrorException'
import Button from '../../components/common/Button'
import Link from '../../components/common/Link'
import {Box, makeStyles, Typography} from '@material-ui/core'
import theme from '../../utils/theme'
import i18next from "i18next"

const useStyles = makeStyles({
  label: {
    color: theme.colors.darkGray,
    fontSize: theme.fontSizes.big,
    margin: '0px 5px 16px',
  },
  instruction: {
    color: theme.colors.gray,
    fontSize: theme.fontSizes.medium,
    margin: '0px 5px 24px',
  },
});

const Login = (param: LoginFormParam) => {
  const classes = useStyles();

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const form = useRef(null);
  const { t } = useTranslation();
  const { userDispatch } = useContext(UserContext);
  const lastRememberMe = Cookies.get('rememberMe') === 'true'


  const onSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      await signIn(param.email.toLowerCase(), password, rememberMe);
    } catch (e: any) {
      param.setValidation({
        ...param.validation,
        userError: e.resultCode || e,
        extraData: e.extraData,
      });
      console.log(e);
    }
  };

  const forgotPassword = () => {
    userDispatch({ type: 'forgot_password' });
  };

  const signIn = (username: string, password: string, rememberMe: boolean) => {
    param.setPageLoading(true);
    return new Promise<boolean>((resolve, reject) => {
      LoginService.getToken('dAiaL1^(R5Yw', password, rememberMe, username)
        .then((tokenData) => {
          Cookies.set('token', tokenData.token);
          LoginService.signIn(username, password)
            .then((signInData) => {
              param.setPageLoading(false);
              if (signInData.hasToChangePassword) {
                userDispatch({ type: 'new_password_required' });
                resolve(true);
              } else {
                Cookies.set('token', tokenData.token);
                Cookies.set('rememberMe', rememberMe ? 'true' : 'false');
                if(signInData.user.layout.includes('OCTG')){
                  i18next.changeLanguage("en"); 
                }
                localStorage.setItem('user', JSON.stringify(signInData.user));
                localStorage.setItem('login', signInData.user.login);
                localStorage.setItem('signed_in', 'true');
                userDispatch({ type: 'signed_in', user: signInData.user });
                resolve(true);
              }
            })
            .catch((e: BusinessErrorException) => {
              if (e.resultCode === 'error.ip.blocked') {
                reject({
                  resultCode: e.resultCode,
                  extraData: [e.extraData['blockingTime']],
                });
              } else {
                console.log(e.resultCode);
                reject({ resultCode: e.resultCode, reason: e.message });
              }
            });
        })
        .catch((e: BusinessErrorException) => {
          param.setPageLoading(false);
          if (e.resultCode === 'error.ip.blocked') {
            reject({
              resultCode: e.resultCode,
              extraData: [e.extraData['blockingTime']],
            });
          } else {
            console.log(e);
            reject({ resultCode: e.resultCode, reason: e.message });
          }
        });
    });
  };

  useEffect(() => {
    if (lastRememberMe) {
      const login = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string).login : '';
      param.setEmail(login);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      data-cy='home-form'
      style={{ width: '100%' }}
      ref={form}
      onSubmit={onSubmit}
    >
      <Box display='flex' flexDirection='column' alignItems="flex-start" width='100%' margin='10px 0px'>
        <Typography className={classes.label}>{t('login.label')}</Typography>
        <Typography className={classes.instruction}>{t('login.instruction')}</Typography>
        <Input
          name='email'
          style={{ textTransform: 'lowercase' }}
          placeholder={t('username')}
          width='100%'
          value={param.email}
          onChange={param.updateValue('email', param.setEmail)}
          onBlur={param.checkValidation}
          error={
            (param.validation.email.dirty && param.validation.email.error) ||
            param.validation.userError.length > 0
          }
          required
        />
        <Input
          type='password'
          name='password'
          placeholder={t('password')}
          width='100%'
          onChange={param.updateValue('password', setPassword)}
          onBlur={param.checkValidation}
          error={
            param.validation.password.dirty || param.validation.password.error
            || param.validation.userError.length > 0
          }
          required
        />
      </Box>
          {param.validation.userError.length > 0 && (
          <ErrorMessage>
            {t(
              `login.errors.${param.validation.userError}`,
              param.validation.extraData &&
                param.validation.extraData.length > 0
                ? { 0: param.validation.extraData }
                : {}
            )}
          </ErrorMessage>
        )}
      <Checkbox checked={rememberMe} onChange={setRememberMe}>
        {t('Remember me')}
      </Checkbox>
      <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' width='100%' margin='10px 0px'>
        <Box fontWeight='fontWeightRegular'>
          <Link onClick={forgotPassword}>{t('login.forgotPassword')}</Link>
        </Box>
        <Button
          type='submit'
          elevation='medium'
          color='white'
          margin='0px'
          width='40%'
        >
          {t('Login')}
        </Button>
      </Box>
    </form>
  );
};

export default Login;
