/** @format */

export type Properties = {
	url: {
		FIELD_APP_BACKEND_URL: string
	}
}

const prod: Properties = {
	url: {
		FIELD_APP_BACKEND_URL: 'https://fieldapp.aws.smart.vallourec.com/'
	}
}

const preprod: Properties = {
	url: {
		FIELD_APP_BACKEND_URL: 'https://pp.fieldapp.aws.smart.vallourec.com/'
	}
}

const int: Properties = {
	url: {
		FIELD_APP_BACKEND_URL: 'https://int.fieldapp.aws.smartdev.vallourec.com/'
	}
}

const dev: Properties = {
	url: {
		FIELD_APP_BACKEND_URL: 'https://dev.fieldapp.aws.smartdev.vallourec.com/'
	}
}

export const config =
	process.env.REACT_APP_ENV === 'prod'
		? prod
		: process.env.REACT_APP_ENV === 'preprod'
			? preprod
			: process.env.REACT_APP_ENV === 'int'
				? int
				: dev
