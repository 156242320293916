/** @format */

import Cookies from 'js-cookie'
import React, { Dispatch, useEffect, useReducer } from 'react'

type OptionProps = {
	key?: string
	label?: string
	onClick?: () => void
}

export type UserState = {
	user: { login: string; layout: string }
	settings: Array<OptionProps>
	authState: string
	checkedLength: boolean
	checkedWeight: boolean
}

export type Action =
	| { type: 'set_settings'; settings: Array<OptionProps> }
	| { type: 'reset_auth_state' }
	| { type: 'forgot_password' }
	| { type: 'new_password_required' }
	| { type: 'signed_in'; user: { login: string; layout: string } }
	| { type: 'sign_out' }
	| { type: 'set_checked_length'; checkedLength: boolean }
	| { type: 'set_checked_weight'; checkedWeight: boolean }

const initialState: UserState = {
	user: {
		login: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}').login : '',
		layout: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}').layout : ''
	},
	settings: [],
	authState: '',
	checkedLength: false,
	checkedWeight: false
}

export const UserContext = React.createContext<{
	userState: UserState
	userDispatch: Dispatch<Action>
}>({
	userState: initialState,
	userDispatch: () => null
})

const reducer = (state: UserState, action: Action): UserState => {
	switch (action.type) {
		case 'set_settings':
			return {
				...state,
				settings: action.settings
			}
		case 'reset_auth_state':
			return {
				...state,
				authState: 'LOGIN',
				user: { login: '', layout: '' }
			}
		case 'sign_out':
			clearCookiesAndLocalStorage()
			return {
				...state,
				authState: 'LOGIN',
				user: { login: '', layout: '' },
				checkedLength: false,
				checkedWeight: false
			}
		case 'forgot_password':
			return {
				...state,
				authState: 'FORGOT_PASSWORD'
			}
		case 'new_password_required':
			return {
				...state,
				authState: 'NEW_PASSWORD_REQUIRED'
			}
		case 'signed_in':
			return {
				...state,
				authState: 'SIGNED_IN',
				user: action.user
			}
		case 'set_checked_length':
			return {
				...state,
				checkedLength: action.checkedLength
			}
		case 'set_checked_weight':
			return {
				...state,
				checkedWeight: action.checkedWeight
			}
	}
}

export const UserProvider = (param: any) => {
	const [userState, userDispatch] = useReducer(reducer, initialState)

	useEffect(() => {
		const hydrate = () =>
			new Promise((resolve, reject) => {
				const token = Cookies.get('token')

				if (token) {
					const jwt = JSON.parse(atob(token.split('.')[1]))
					const expirationDate = (jwt && jwt.exp && jwt.exp * 1000) || null

					if (isExpired(expirationDate)) {
						console.log('token is expired')
						reject(false)
					} else {
						console.log('token is valid, no need to reconnect')
						resolve(true)
						const loggedInUser = localStorage.getItem('user')
						if (loggedInUser) {
							userDispatch({
								type: 'signed_in',
								user: JSON.parse(loggedInUser)
							})
						}
					}
				} else {
					console.log('rememberMe is false')
					resolve(false)
				}
			})

		hydrate().catch(err => {
			console.log(err)
		})
	}, [])

	const isExpired = (exp?: number) => {
		if (!exp) {
			return false
		}

		return Date.now() > exp
	}

	return <UserContext.Provider value={{ userState, userDispatch }}>{param.children}</UserContext.Provider>
}

function clearCookiesAndLocalStorage() {
	Cookies.remove('token')
	localStorage.removeItem('signed_in')
	localStorage.removeItem('selected_racks')
	localStorage.removeItem('opened_racks')
	localStorage.removeItem('closed_racks')
	localStorage.removeItem('page_number')
}
