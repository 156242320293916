/** @format */

import React from 'react'
import { TFunction } from 'react-i18next'
import { toast } from 'react-toastify'
import DocumentService from '../api/DocumentService'
import { Action } from '../stores/UserStore'
import FileSaver from 'file-saver'
import CertificateService from '../api/CertificateService'
import { RackItemInTableModel } from '../models/RackItemInTableModel'
import DimensionalCheckService from '../api/DimensionalCheckService'

export const downloadPDFDocument = (
	setPageLoading: (pageLoading: boolean) => void,
	pipeId: string | undefined,
	documentType: string,
	userDispatch: React.Dispatch<Action>,
	t: TFunction<'translation'>
) => {
	setPageLoading(true)
	if (pipeId) {
		DocumentService.getDocumentData(pipeId, documentType)
			.then(data => {
				if (data.document && data.document.content) {
					const content = data.document.content
					const byteArray = new Uint8Array(
						atob(content)
							.split('')
							.map(char => char.charCodeAt(0))
					)
					const blob = new Blob([byteArray], { type: 'application/pdf' })
					const url = URL.createObjectURL(blob)
					window.open(url)
				}
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
	}
}

export const downloadPipeChecking = (
	setPageLoading: (pageLoading: boolean) => void,
	pipeId: string | undefined,
	documentType: string,
	userDispatch: React.Dispatch<Action>,
	t: TFunction<'translation'>
) => {
	setPageLoading(true)
	if (pipeId) {
		DocumentService.getDocumentData(pipeId, documentType)
			.then(data => {
				if (data.document && data.document.content) {
					const contentPipeChecking = data.document.content
					const byteArray = new Uint8Array(
						atob(contentPipeChecking)
							.split('')
							.map(char => char.charCodeAt(0))
					)
					const blob = new Blob([byteArray], {
						type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
					})
					FileSaver.saveAs(blob, data.document.fileName)
				}
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
	}
}

export const downloadDimensionalCheckReport = (
	setPageLoading: (pageLoading: boolean) => void,
	rowData: RackItemInTableModel,
	userDispatch: React.Dispatch<Action>,
	t: TFunction<'translation'>
) => {
	setPageLoading(true)
	if (rowData.identificationId) {
		DimensionalCheckService.getDimensionalCheck(rowData.identificationId)
			.then(data => {
				if (data.fileData && data.fileData.content) {
					const byteArray = new Uint8Array(
						atob(data.fileData.content)
							.split('')
							.map(char => char.charCodeAt(0))
					)
					const blob = new Blob([byteArray], {
						type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
					})
					let filename: string
					if (rowData.marker === null || rowData.marker === undefined) {
						filename = 'controle_dimensionnel_tube_' + rowData.identificationId + '.docx'
					} else {
						const dateEndIndex = 10
						const lastUpdateDate = rowData.pipeEndLastUpdateDate
							? rowData.pipeEndLastUpdateDate.substring(0, dateEndIndex)
							: ''
						filename = 'controle_dimensionnel_tube_' + rowData.marker + '_' + lastUpdateDate + '.docx'
					}
					FileSaver.saveAs(blob, filename)
				}
				setPageLoading(false)
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
	}
}

export const downloadCertificate = (
	setPageLoading: (pageLoading: boolean) => void,
	pipeId: string | undefined,
	userDispatch: React.Dispatch<Action>,
	t: TFunction<'translation'>
) => {
	setPageLoading(true)
	if (pipeId) {
		CertificateService.getCertificateData(pipeId)
			.then(data => {
				if (data.certificate && data.certificate.contentCertificate) {
					const contentCertificate = data.certificate.contentCertificate
					const byteArray = new Uint8Array(
						atob(atob(contentCertificate))
							.split('')
							.map(char => char.charCodeAt(0))
					)
					const blob = new Blob([byteArray], { type: 'application/pdf' })
					const url = URL.createObjectURL(blob)
					window.open(url)
				}
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
	}
}

