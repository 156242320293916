/** @format */

import { TailPageDetailStrategy } from './TailPageDetailStrategy'
import { VamPageDetailStrategy } from './VamPageDetailStrategy'
import { WeldingLabPageDetailStrategy } from './WeldingLabPageDetailStrategy'

export const contextPageDetailStrategy = (layout: string) => {
	switch (true) {
		case layout === 'YELLOWTAIL_ADMIN' || layout === 'WHIPTAIL':
			return new TailPageDetailStrategy()
		case layout === 'VAM_licensees':
			return new VamPageDetailStrategy()
		default:
			return new WeldingLabPageDetailStrategy()
	}
}
