import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import fr from './fr.json'
import en from './en.json'
import Cookies from "js-cookie";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fr: {
    translation: fr
  },
  en: {
    translation: en
  }
}

// passes i18n down to react-i18next
i18n.use(initReactI18next).use(LanguageDetector).init({
  lng: Cookies.get("language") || navigator.language,
  resources,
  fallbackLng: ['fr'],
  whitelist: ['fr', 'en'],
  interpolation: {
    escapeValue: false // react already safes from xss
  },
  detection: {
    order: ['cookie', 'localstorage', 'navigator'],
    lookupCookie: 'language',
    lookupLocalStorage: 'language',
    domain: process.env.REACT_APP_DOMAIN
  }
})

export default i18n