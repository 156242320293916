/** @format */

import { RackItemInTableModel } from '../../../../models/RackItemInTableModel'
import PopoverFilter from '../../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../../stores/RackStore'
import { ColumnContent } from '../../RackContentStrategy'

export const buildSurfaceTreatmentColumn = (
	dataType: string,
	columnName: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	expandSide2: boolean | undefined,
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
	headerBackgroundColor: () => string
): ColumnContent => {
	return {
		title: t(dataType + '-data.' + columnName + '.name'),
		field: columnName,
		hidden: !expandSide2,
		headerStyle: {
			pointerEvents: 'none',
			backgroundColor: headerBackgroundColor(),
			borderRight: 'solid 1px lightgrey'
		},
		render: (rowData: RackItemInTableModel) => (
			<span
				title={
					columnName === 'end1SurfaceTreatment'
						? rowData && rowData.end1SurfaceTreatment
							? rowData.end1SurfaceTreatment
							: ''
						: rowData && rowData.end2SurfaceTreatment
						? rowData.end2SurfaceTreatment
						: ''
				}
			>
				{columnName === 'end1SurfaceTreatment'
					? rowData && rowData.end1SurfaceTreatment
						? rowData.end1SurfaceTreatment.length > 15
							? rowData.end1SurfaceTreatment.substring(0, 15) + '...'
							: rowData.end1SurfaceTreatment
						: ''
					: rowData && rowData.end2SurfaceTreatment
					? rowData.end2SurfaceTreatment.length > 15
						? rowData.end2SurfaceTreatment.substring(0, 15) + '...'
						: rowData.end2SurfaceTreatment
					: ''}
			</span>
		),
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.' + columnName + '.name') === 'alphanumeric'}
				onFilterChanged={onFilterChange(columnName)}
				columnField={columnName}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
			/>
		)
	}
}
