/** @format */

import { PageDetailStrategy, ColumnContent } from './PageDetailStrategy'
import { TFunction } from 'react-i18next'
import { Action, UserState } from '../../stores/UserStore'
import { buildLengthColumn } from './columnBuilder/buildLengthColumn'
import { buildNonSpecificColumn } from './columnBuilder/buildNonSpecificColumn'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import { downloadCertificate, downloadPDFDocument } from '../DocumentBusiness'
import DocumentService from '../../api/DocumentService'
import CertificateService from '../../api/CertificateService'
import { FilesDownloadable } from '../../models/FilesDownloadable'
import { buildFilesDownloadColumn } from './columnBuilder/buildFilesDownloadColumn'
import { GenericProductDto } from '../../models/dto/GenericProductDto'
import DoFactory from '../../models/DoFactory'
import AccessoryDetailsCardInfo from '../../containers/common/AccessoryDetailsCardInfo'

export class VamPageDetailStrategy implements PageDetailStrategy {
	private readonly columnNames = ['customer', 'workOrder', 'salesOrder', 'length', 'heatNumber']
	private readonly dataType = 'vam'

	private downloadDocument = (
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		type: string,
		pipeDetails?: RackItemInTableModel
	) => {
		if (type === 'DIGITAL_LOG_SHEET') {
			downloadPDFDocument(setPageLoading, pipeDetails?.identificationId, type, userDispatch, t)
		} else {
			downloadCertificate(setPageLoading, pipeDetails?.identificationId, userDispatch, t)
		}
	}

	private checkDownloadable = (
		setDownloadableState: (value: React.SetStateAction<undefined>) => void,
		pipeId: any,
		typeCheck: string,
		typeDoc?: string
	) => {
		if (pipeId !== undefined) {
			if (typeCheck === 'checkDocument' && typeDoc) {
				DocumentService.checkDocumentExist(pipeId, typeDoc).then(data => {
					setDownloadableState(data.currentValue)
				})
			} else {
				CertificateService.checkCertificateExist(pipeId).then(data => {
					setDownloadableState(data.currentValue)
				})
			}
		}
	}

	checkDownloadableLeft(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'checkDocument', 'DIGITAL_LOG_SHEET')
	}

	checkDownloadableCenter(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {
		this.checkDownloadable(setDownloadableState, pipeId, 'checkCertificate')
	}

	checkDownloadableRight(
		pipeId: string | undefined,
		setDownloadableState: (value: React.SetStateAction<undefined>) => void
	): void {}
	formatSubtitle() {
		return 'vam_data'
	}

	formatDataType() {
		return 'vam-data'
	}

	getCardInfo(pipeDetails: RackItemInTableModel): JSX.Element {
		return <AccessoryDetailsCardInfo accessory={pipeDetails} />
	}

	getConvertFromGenericProductoDto(
		genericProduct: GenericProductDto,
		identificationId?: string
	): RackItemInTableModel {
		return DoFactory.convertFromGenericProductoDtoToVamRackItemInTableModel(genericProduct)
	}

	isTail(): boolean {
		return false
	}

	isVam(): boolean {
		return false
	}

	getFilesDownloadColumns(
		className: string,
		pipeDetails: RackItemInTableModel,
		setPageLoading: (pageLoading: boolean) => void,
		userDispatch: React.Dispatch<Action>,
		t: TFunction<'translation'>,
		downloadable1: any,
		downloadable2: any,
		downloadable3?: any
	): FilesDownloadable[] {
		return [
			buildFilesDownloadColumn(
				'Download Certificate',
				'downloadCertificate',
				downloadable1,
				className,
				'downloadCertificat',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'Certificate'
			),
			buildFilesDownloadColumn(
				'download_digital_log_sheet',
				'downloadDigitalLogSheet',
				downloadable2,
				className,
				'downloadDigitalLogSheet',
				pipeDetails,
				this.downloadDocument,
				setPageLoading,
				userDispatch,
				t,
				'DIGITAL_LOG_SHEET'
			)
		]
	}

	buildColumns(
		t: TFunction<'translation'>,
		userState: UserState,
		chooseUnitLengthMmtoFt?: string,
		chooseUnitLengthMmtoInch?: string,
		chooseUnitWeight?: string
	): ColumnContent[] {
		return this.columnNames.map(columnName => {
			if (columnName === 'length') {
				return buildLengthColumn(this.dataType, t, userState, chooseUnitLengthMmtoFt)
			}

			return buildNonSpecificColumn(this.dataType, columnName, t)
		})
	}
}
