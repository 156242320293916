/** @format */

import { getHeader, instance, manageResponse } from './ApiManager'

const requests = {
	uploadData: async (filename: string, content: string) => {
		const response = await instance.post('/tgp-data/upload', { content, filename }, getHeader())
		return manageResponse(response)
	},

	uploadDataSlabs: async (filename: string, content: string) => {
		const response = await instance.post('/tgp-data/upload-slabs', { content, filename }, getHeader())
		return manageResponse(response)
	}
}

export default requests
