import { Box, Grid, makeStyles } from '@material-ui/core';
import theme from '../../utils/theme';

const useStyles = makeStyles({
  gridRow: {
    flexGrow: 1,
    margin: 'auto'
  },
  gridCell: {
    flexGrow: 0,
    maxWidth: 'none',
    textAlign: 'center',
  },
  value: {
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'center'
  },
  label: {
    color: theme.colors.white,
    textAlign: 'center',
  },
});

type CardInfoProps = {
  info: Array<LabelValue>;
};

type LabelValue = { label: string, value: string }

const CardInfo = (props: CardInfoProps) => {
  const classes = useStyles(props);

  return (
    <Grid className={classes.gridRow} justifyContent='space-around' container direction='row' spacing={1} >
      {
        props.info.map((labelValue: LabelValue) => (
          <Grid key={labelValue.label} className={classes.gridCell} item xs={2}>
            <Box className={classes.value}>{labelValue.value}</Box>
            <Box className={classes.label}>{labelValue.label}</Box>
          </Grid>))
      }
    </Grid>
  );
}

export default CardInfo;
