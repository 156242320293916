/** @format */

import { RackItemInTableModel } from '../../../../models/RackItemInTableModel'
import PopoverFilter from '../../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../../stores/RackStore'
import { ColumnContent } from '../../RackContentStrategy'
import i18n from '../../../../i18n'
import { MeasureUtils } from '../../../../utils/MeasureUtils'
import { UserState } from '../../../../stores/UserStore'

function buildSpan(columnName: string, rowData: RackItemInTableModel, userState: UserState) {
	return columnName === 'length'
		? rowData && rowData.length && userState.checkedLength
			? MeasureUtils.convertValueFromMMToInch(rowData.length)
			: rowData.length
		: columnName === 'internalDiameter'
		? rowData && rowData.internalDiameter && userState.checkedLength
			? MeasureUtils.convertValueFromMMToInch(rowData.internalDiameter)
			: rowData.internalDiameter
		: columnName === 'wallThickness'
		? rowData && rowData.wallThickness && userState.checkedLength
			? MeasureUtils.convertValueFromMMToInch(rowData.wallThickness)
			: rowData.wallThickness
		: rowData && rowData.wallThicknessClad && userState.checkedLength
		? MeasureUtils.convertValueFromMMToInch(rowData.wallThicknessClad)
		: rowData.wallThicknessClad
}

export const buildMetricColumn = (
	dataType: string,
	columnName: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	userState: UserState,
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
	chooseUnitLengthMmtoInch: string | undefined
): ColumnContent => {
	return {
		title:
			i18n.language === 'en'
				? t(dataType + '-data.' + columnName + '_en.name') + chooseUnitLengthMmtoInch
				: t(dataType + '-data.' + columnName + '.name') + chooseUnitLengthMmtoInch,
		field: columnName,
		render: (rowData: RackItemInTableModel) => <span>{buildSpan(columnName, rowData, userState)}</span>,
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.' + columnName + '.name') === 'alphanumeric'}
				onFilterChanged={onFilterChange(columnName)}
				columnField={columnName}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
				stateCheckedSettings={userState.checkedLength}
				nameOfColumnToConvert={columnName}
			/>
		)
	}
}
