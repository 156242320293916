import React, { useReducer, Dispatch } from 'react';

type State = {
  pageTitle: string;
  pageSubTitle: string;
  pageLoading: boolean;
}

type Action =
  | { type: 'set_page_subtitle'; pageSubTitle: string }
  | { type: 'set_page_loading'; pageLoading: boolean }

const initialState: State = {
  pageTitle: 'Traceability',
  pageSubTitle: '',
  pageLoading: false,
};

export const AppContext = React.createContext<{
  appState: State;
  appDispatch: Dispatch<Action>;
}>({
  appState: initialState,
  appDispatch: () => null
});

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'set_page_subtitle':
      return {
        ...state,
        pageSubTitle: action.pageSubTitle,
      }
    case 'set_page_loading':
      return {
        ...state,
        pageLoading: action.pageLoading,
      }
  }
}

export const AppProvider = (props: any) => {
  const [appState, appDispatch] = useReducer(reducer, initialState);

  return <AppContext.Provider value={{appState, appDispatch}}>
    {props.children}
  </AppContext.Provider>;
}