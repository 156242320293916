/** @format */

import randomColor from 'randomcolor'

export class BackgroundColorUtils {
	static jointNumberCount = new Map<string, number>([])
	static colorMap = new Map<string, string>([])

	static defineBackgroundColor(jointNumber: string) {
		// Increment the amount of pipes with same jointNumber
		if (!this.jointNumberCount.has(jointNumber)) {
			this.jointNumberCount.set(jointNumber, 1)
		} else {
			this.jointNumberCount.set(jointNumber, this.jointNumberCount.get(jointNumber)! + 1)
		}

		// Check the amount of pipes with same jointNumber
		if (this.jointNumberCount.get(jointNumber)! >= 2) {
			// If color map does not already have an entry for that jointNumber
			if (!this.colorMap.has(jointNumber)) {
				this.colorMap.set(
					jointNumber,
					randomColor({
						hue: 'blue',
						format: 'rgba',
						alpha: 0.1,
						luminosity: 'light'
					})
				)
			}
		}
	}

	static getBackgroundColorMap(): Map<string, string> {
		return this.colorMap
	}
}
