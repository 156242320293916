/** @format */

import { useTranslation } from 'react-i18next'
import { Typography, makeStyles, Button, TextField, Box, Card, IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import theme from '../utils/theme'
import { useContext, useState } from 'react'
import { AppContext } from '../stores/AppStore'
import PipeService from '../api/PipeService'
import { toast } from 'react-toastify'
import { UserContext } from '../stores/UserStore'
import { useHistory } from 'react-router-dom'

const FileSaver = require('file-saver')

const useStyles = makeStyles(t => ({
	root: {
		margin: '10px'
	},
	input: {
		padding: '5px',
		width: '400px',
		marginLeft: '250px'
	},
	typo: {
		marginTop: '5px',
		marginLeft: '250px',
		width: '400px',
		fontStyle: 'italic'
	},
	button: {
		color: theme.colors.white,
		backgroundColor: theme.colors.primary,
		fontWeight: 'bold',
		marginLeft: '30px'
	},
	title: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		padding: '8px 0px 8px 11px',
		borderLeftWidth: '8px',
		borderLeftStyle: 'solid',
		borderLeftColor: theme.colors.darkBlue,
		textAlign: 'left',
		color: theme.colors.darkBlue,
		height: '16px',
		margin: '21px 0px',
		fontWeight: 'bold'
	},
	backArrowButton: {
		color: theme.colors.white,
		paddingRight: '5px'
	},
	iconButton: {
		letterSpacing: '0.03px',
		color: theme.colors.white,
		background: 'transparent linear-gradient(99deg, #009EDE 0%, #1A2981 100%) 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		fontSize: '16px',
		fontWeight: 'bold',
		padding: '8px 24px',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
	}
}))

const DownloadValid = (props: any) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { appDispatch } = useContext(AppContext)
	const [numberOfValid, setNumberOfValid] = useState('')
	const { userDispatch } = useContext(UserContext)
	const history = useHistory()

	const setPageLoading = (pageLoading: boolean) => {
		appDispatch({ type: 'set_page_loading', pageLoading })
	}

	const generateValidFile = () => {
		if (numberOfValid && Number(numberOfValid) > 0) {
			setPageLoading(true)
			PipeService.getValidList(Number(numberOfValid))
				.then(data => {
					if (data.document && data.document.content) {
						const contentFile = data.document.content
						const byteArray = new Uint8Array(
							atob(contentFile)
								.split('')
								.map(char => char.charCodeAt(0))
						)
						const blob = new Blob([byteArray], {
							type: 'application/vnd.ms-excel;charset=utf-8'
						})
						FileSaver.saveAs(blob, data.document.fileName)
						setNumberOfValid('')
					}
				})
				.finally(() => {
					setPageLoading(false)
				})
				.catch(e => {
					setPageLoading(false)
					if (e.resultCode === 'error.expired.token') {
						userDispatch({ type: 'sign_out' })
					} else {
						toast.error(t(e.message))
					}
				})
		} else {
			toast.error(t('valid_number'))
		}
	}

	const handleValueChange = (e: any) => {
		setNumberOfValid(e.target.value)
	}

	return (
		<div className={classes.root}>
			<IconButton
				id="back-icon"
				className={classes.iconButton}
				onClick={() => {
					history.goBack()
				}}
				data-testid="backButton"
				style={{ paddingLeft: '5px', paddingRight: '10px', margin: '10px 33px' }}
			>
				<div style={{ color: theme.colors.white, display: 'contents' }}>
					<ArrowBackIcon className={classes.backArrowButton} />
					{t('back')}
				</div>
			</IconButton>
			<Box width="calc(100% - 64px)" height="100%" margin="23px 33px" id="BoxDropZone">
				<Card style={{ borderRadius: '16px' }}>
					<div className={classes.title} id="title-dropzone">
						{t('generate_batch_valid')}
					</div>

					<TextField
						id="valid-quantity"
						inputProps={{ style: { textAlign: 'center', fontSize: '20px' } }}
						className={classes.input}
						type="number"
						value={numberOfValid}
						onChange={handleValueChange}
						maxRows={1}
						placeholder={t('fill_valid_quantity')}
					/>
					<Button className={classes.button} onClick={generateValidFile}>
						{t('generate')}
					</Button>
					<Typography variant="body1" gutterBottom className={classes.typo} align="center">
						{t('limited_batch')}
					</Typography>
				</Card>
			</Box>
		</div>
	)
}

export default DownloadValid
