/** @format */

import { Backdrop, Box, Card, CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import DocumentService from '../api/DocumentService'
import PipeService from '../api/PipeService'
import UploadDocumentPopup from '../containers/common/UploadDocumentPopup'
import UploadCertificateReportPackingListPopup from '../containers/common/UploadCertificateReportPackingListPopup'
import { UserContext } from '../stores/UserStore'
import { useHistory } from 'react-router-dom'
import { AppContext } from '../stores/AppStore'
import theme from '../utils/theme'

const useStyles = makeStyles({
	dropDownArea: {
		margin: '10px',
		borderRadius: '16px'
	},
	backdrop: {
		zIndex: 2,
		color: '#fff'
	},
	title: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		padding: '8px 0px 8px 11px',
		borderLeftWidth: '8px',
		borderLeftStyle: 'solid',
		borderLeftColor: theme.colors.darkBlue,
		textAlign: 'left',
		color: theme.colors.darkBlue,
		height: '16px',
		margin: '21px 0px',
		fontWeight: 'bold'
	},
	backArrowButton: {
		color: theme.colors.white,
		paddingRight: '5px'
	},
	iconButton: {
		letterSpacing: '0.03px',
		color: theme.colors.white,
		background: 'transparent linear-gradient(99deg, #009EDE 0%, #1A2981 100%) 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		fontSize: '16px',
		fontWeight: 'bold',
		padding: '8px 24px',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
	}
})

const UploadDocument = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { userState, userDispatch } = useContext(UserContext)
	const { appDispatch } = useContext(AppContext)

	const [file, setFile] = useState([])
	const [filename, setFilename] = useState('')
	const [openPopup, setOpenPopup] = useState(false)

	const [displayLoading, setDisplayLoading] = useState(false)

	const history = useHistory()

	const handlePopupCancel = () => {
		setOpenPopup(false)
		setFile([])
	}

	const handlePopupLoad = (marker: string, documentType: string) => {
		const content = (file[0] as any).data.replace('data:application/pdf;base64,', '')
		uploadDocument(marker, (file[0] as any).file.name, content, documentType)

		setOpenPopup(false)
		setFile([])
	}

	// @ts-ignore
	async function uploadDocumentByVdbId(vsbId: string, content, documentType: string) {
		const pipe = await PipeService.findYellowTailPipeByVsbId(vsbId).catch(e => {
			if (e.resultCode === undefined) {
				toast.error(t('errors.unknown'), { autoClose: 5000 })
			} else if (e.resultCode === 'error.expired.token') {
				userDispatch({ type: 'sign_out' })
			} else {
				toast.error(t(e.resultCode + 'yellowtail', { filename: filename, vsbId: vsbId }), { autoClose: 5000 })
			}
		})
		if (pipe !== null && pipe !== undefined) {
			uploadDocument(pipe.pipe.identificationId.defaultValue, (file[0] as any).file.name, content, documentType)
		} else {
			setDisplayLoading(false)
		}
	}

	const handlePopupYellowtailLoad = async (
		documentType: string,
		vsbId?: string,
		vsbOrder?: string,
		customerItem?: string,
		pipeList?: string
	) => {
		const content = (file[0] as any).data.replace('data:application/pdf;base64,', '')
		setOpenPopup(false)
		setDisplayLoading(true)
		if (vsbId) {
			await uploadDocumentByVdbId(vsbId, content, documentType)
		} else if (pipeList) {
			pipeList.split(',').map(vsbId => uploadDocumentByVdbId(vsbId.trim(), content, documentType))
		} else if (vsbOrder && customerItem) {
			const pipesList = await PipeService.findYellowTailPipeByOrderAndItem(vsbOrder, customerItem).catch(e => {
				if (e.resultCode === undefined) {
					toast.error(t('errors.unknown'), { autoClose: 5000 })
				} else if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(
						t(e.resultCode + 'yellowtailList', { filename: filename, couple: vsbOrder + '/' + customerItem }),
						{ autoClose: 5000 }
					)
				}
			})
			if (pipesList !== null && pipesList !== undefined) {
				// @ts-ignore
				pipesList.pipes.map(pipeId =>
					uploadDocumentByVdbId(pipeId.tally.values.DEFAULT.trim(), content, documentType)
				)
			} else {
				setDisplayLoading(false)
			}
		}

		setFile([])
	}

	const handleFileAddition = (file: any) => {
		setFile(file)
		setFilename(file[0].file.name)
		setOpenPopup(true)
	}

	const handleDropReject = () => {
		toast.error(t('Load pdf format'), { autoClose: 5000 })
	}

	const uploadDocument = (valid?: string, filename?: string, content?: string, documentType?: string) => {
		setDisplayLoading(true)
		if (valid && filename && content && documentType) {
			DocumentService.uploadDocument(valid, filename, content, documentType)
				.then(response => {
					console.log(response)
					if (userState.user.layout === 'YELLOWTAIL_ADMIN' || userState.user.layout === 'WHIPTAIL') {
						if (response.resultCode.includes('sons')) {
							toast.success(t(response.resultCode, { filename: filename, valid: valid }), {
								autoClose: 5000
							})
						} else {
							toast.success(
								t(response.resultCode + 'yellowtail', {
									filename: filename,
									valid: valid
								}),
								{
									autoClose: 5000
								}
							)
						}
					} else {
						toast.success(t(response.resultCode, { filename: filename, valid: valid }), { autoClose: 5000 })
					}
				})
				.finally(() => {
					setDisplayLoading(false)
				})
				.catch(e => {
					setDisplayLoading(false)
					if (e.resultCode === undefined) {
						toast.error(t('errors.unknown'), { autoClose: 5000 })
					} else if (e.resultCode === 'error.expired.token') {
						userDispatch({ type: 'sign_out' })
					} else {
						toast.error(t(e.resultCode, { filename: filename, valid: valid }), { autoClose: 5000 })
					}
				})
		} else {
			setDisplayLoading(false)
		}
	}

	useEffect(() => {
		appDispatch({ type: 'set_page_subtitle', pageSubTitle: t('upload_document') })
	}, [appDispatch, t])

	return (
		<div className={classes.dropDownArea}>
			<IconButton
				id="back-icon"
				className={classes.iconButton}
				onClick={() => {
					history.goBack()
				}}
				data-testid="backButton"
				style={{ paddingLeft: '5px', paddingRight: '10px', margin: '10px 33px' }}
			>
				<div style={{ color: theme.colors.white, display: 'contents' }}>
					<ArrowBackIcon className={classes.backArrowButton} />
					{t('back')}
				</div>
			</IconButton>
			<Box width="calc(100% - 64px)" height="100%" margin="23px 33px" id="BoxDropZone">
				<Card style={{ borderRadius: '16px' }}>
					<div className={classes.title} id="title-dropzone">
						{t('upload_document')}
					</div>
					<div style={{ padding: '0px 16px 16px 20px' }}>
						<DropzoneAreaBase
							fileObjects={file}
							dropzoneText={t('drag_drop_document')}
							showPreviews={false}
							filesLimit={1}
							showPreviewsInDropzone={false}
							acceptedFiles={['application/pdf']}
							maxFileSize={100000000}
							showAlerts={[]}
							onAdd={fileObjects => handleFileAddition(fileObjects)}
							onDropRejected={handleDropReject}
						/>
						{(userState.user.layout === 'YELLOWTAIL_ADMIN' || userState.user.layout === 'WHIPTAIL') && (
							<UploadCertificateReportPackingListPopup
								open={openPopup}
								filename={filename}
								handleCancel={handlePopupCancel}
								handleLoad={(
									vsbId: string,
									vsbOrder: string,
									customerItem: string,
									pipeList: string,
									documentType: string
								) => handlePopupYellowtailLoad(documentType, vsbId, vsbOrder, customerItem, pipeList)}
							/>
						)}
						{userState.user.layout !== 'YELLOWTAIL_ADMIN' && userState.user.layout !== 'WHIPTAIL' && (
							<UploadDocumentPopup
								open={openPopup}
								filename={filename}
								handleCancel={handlePopupCancel}
								handleLoad={(marker: string, documentType: string) => handlePopupLoad(marker, documentType)}
							/>
						)}

						<Backdrop className={classes.backdrop} open={displayLoading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					</div>
				</Card>
			</Box>
		</div>
	)
}

export default UploadDocument
