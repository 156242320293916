/** @format */

import PopoverFilter from '../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../stores/RackStore'
import { ColumnContent } from '../RackContentStrategy'

export const buildNonSpecificColumn = (
	dataType: string,
	columnName: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void
): ColumnContent => {
	return {
		title: t(dataType + '-data.' + columnName + '.name'),
		field: columnName,
		key: columnName,
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.' + columnName + '.filter') === 'alphanumeric'}
				onFilterChanged={onFilterChange(columnName)}
				columnField={columnName}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
				currentPortal={dataType}
			/>
		)
	}
}
