/** @format */

import {useTranslation} from 'react-i18next'
import {AppContext} from '../stores/AppStore'
import React, {useCallback, useContext, useEffect} from 'react'
import {UserContext} from '../stores/UserStore'
import {toast} from 'react-toastify'
import {Box} from '@material-ui/core'
import Card from '../components/common/Card'
import FilterableTable from '../components/common/FilterableTable'
import ColumnsOrdering, {SectionItem} from '../containers/common/manageColumns/ColumnsOrdering'
import {SlabContext} from "../stores/SlabStore"
import SlabService from "../api/SlabService"
import PopoverFilter from "../containers/common/PopoverFilter"
import ChipsFilters from "../components/common/ChipsFilters"
import {FilterUtils} from "../utils/FilterUtils"
import {SlabModel} from "../models/SlabModel";
import QCGreyImage from '../assets/images/quality-check-grey.png';
import QCBlackImage from '../assets/images/quality-check-black.png';


const SlabContent = (props: any) => {
	const { t } = useTranslation()
	const { appState, appDispatch } = useContext(AppContext)
	const { slabState, slabDispatch } = useContext(SlabContext)
	const { userState, userDispatch } = useContext(UserContext)

	useEffect(() => {
		const setPageLoading = (pageLoading: boolean) => {
			appDispatch({ type: 'set_page_loading', pageLoading })
		}

		// enable loader
		setPageLoading(true)

		// init
		appDispatch({
			type: 'set_page_subtitle',
			pageSubTitle:
				t('slab_content')
		})
		slabDispatch({ type: 'init_table' })

		// call service to retrieve pipes
		SlabService.getAllSlabs()
			.then(data => {
				slabDispatch({ type: 'set_all_slabs', allSlabs: data })
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
	}, [appDispatch, userDispatch, t, slabDispatch, userState.user.login])

	const onFilterChange = useCallback(
		(nameOfColumn: string) => {
			return (columnId: any, paramFilter: any) => {
				const filteredColumnsMap = FilterUtils.getFilteredColumnMap(
					nameOfColumn,
					paramFilter,
					slabState.filteredColumnsMap
					)

				// update state
				slabDispatch({
					type: 'set_filtered_columns_map',
					filteredColumnsMap
				})
			}
		},
		[slabDispatch, slabState.filteredColumnsMap]
	)

	useEffect(() => {
		function getPopoverFilter(props: any, key: SectionItem) {
			return <PopoverFilter
				{...props}
				searchedText={slabState.searchedText}
				isAlphanumeric={t('slab-data.' + key.label + '.filter') === 'alphanumeric'}
				onFilterChanged={onFilterChange(key.label)}
				columnField={key.label}
				stateData={slabState.displayedSlabs}
				stateFilteredColumnsMap={slabState.filteredColumnsMap}
				stateCheckedSettings={userState.checkedLength}
				nameOfColumnToConvert={key.label}
				currentPortal={'slab'}
			/>
		}

		const updateSlabColumns = () => {
			slabDispatch({
				type: 'set_slab_columns',
				slabColumns: ColumnsOrdering.sections.flatMap(section => {
					if (section.label === 'slab_content') {
						return section.keys.flatMap(key => {
							if (key.keys && key.keys.length > 0) {
								return key.keys
							} else {
								return key
							}
						}).map(key => {
							if(key.label === 'qualityCheck') {
								return {
									title: t('slab-data.' + key.label + '.name'),
									field: key.label,
									filterComponent: (props: any) => getPopoverFilter(props, key),
									render: (rowData: SlabModel) => (
										<Box alignItems="center" justifyContent="center" width="100%">
											{rowData.qualityCheck === 1 ? (
												<img src={QCGreyImage} id="QCGreyImage" alt="QCGreyImage" style={{margin: '0px 0px 0px 12px', height: '30px', width: '35px', borderRadius: '6px'}}/>
											) :
											rowData.qualityCheck && rowData.qualityCheck >= 2 ? (
												<img src={QCBlackImage} id="QCBlackImage" alt="QCBlackImage" style={{margin: '0px 0px 0px 12px', height: '30px', width: '35px', borderRadius: '6px'}}/>
											) : (
											''
											)}
										</Box>
									)
								}
							}
							return {
								title: t('slab-data.' + key.label + '.name'),
								field: key.label,
								filterComponent: (props: any) => getPopoverFilter(props, key)
							}
						})
					}
					return []
				})
			})
		}

		updateSlabColumns()
	}, [slabDispatch, t, slabState.displayedSlabs, onFilterChange, slabState.filteredColumnsMap, slabState.searchedText,userState.checkedLength])

	useEffect(() => {
		const updateDisplayData = () => {
			slabDispatch({
				type: 'set_displayed_slabs',
				displayedSlabs: slabState.allSlabs
			})
		}
		updateDisplayData()
	}, [slabDispatch, slabState.allSlabs])

	function getCurrentPageNumber() {
		return () => slabState.pageNumber
	}

	function setCurrentPageNumber() {
		return (pageNumber: number) =>
			slabDispatch({type: 'set_page_number', pageNumber})
	}

	function getTableName() {
		return () => 'main_table'
	}

	function getCurrentPageSize() {
		return () => slabState.pageSize
	}

	function setCurrentPageSize() {
		return (pageSize: number) => slabDispatch({type: 'set_page_size', pageSize})
	}

	return (
		<Box width="100%" height="100%">
			{appState.pageLoading ? (
				<div />
			) : (
				<div id="slab-content">
					<Card alignSelf="stretch" width="calc(100% - 64px)" margin="23px 33px">
						<FilterableTable
							columns={slabState.slabColumns}
							data={slabState.displayedSlabs}
							getCurrentPageNumber={getCurrentPageNumber()}
							setCurrentPageNumber={setCurrentPageNumber()							}
							getCurrentPageSize={getCurrentPageSize()}
							setCurrentPageSize={setCurrentPageSize()}
							vam={false}
							octg={false}
							slabs={true}
							title={appState.pageSubTitle}
							getTableName={getTableName()}
							subtitle={
								<ChipsFilters
									portail={'slab-data.'}
									filters={slabState.filteredColumnsMap}
									stateDispatch={slabDispatch}
								/>
							}
							displayPagination={true}
						/>
					</Card>
				</div>
			)}
		</Box>
	)
}

export default SlabContent
