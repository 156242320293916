import { getHeader, instance, manageResponse } from './ApiManager';


const requests = {
    getDimensionalCheck: async (identificationId: string) => {
        const response = await instance.post(`/tgp-dimensional-checks/download`, { identificationId: identificationId, locale: 'fr-FR' }, getHeader());
        return manageResponse(response);
    },
    checkDimensionalDocumentExist: async (identificationId: string) => {
        const response = await instance.post('/tgp-dimensional-checks/exist', { identificationId }, getHeader())
        return manageResponse(response)
    }
};

export default requests;