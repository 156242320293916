/** @format */

import { Button, Checkbox, FormControl, FormControlLabel, ListSubheader, Paper, TextField } from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import Autocomplete, { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ListChildComponentProps, VariableSizeList } from 'react-window'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		formControl: {
			margin: theme.spacing(1)
		},
		apply: {
			fontWeight: 600
		},
		button: {
			color: theme.palette.primary.main,
			marginRight: '15px',
			marginTop: '9px',
			marginBottom: '5px',
			float: 'right'
		},
		popperDisablePortal: {
			position: 'relative'
		},
		listbox: {
			boxSizing: 'border-box',
			'& ul': {
				padding: 0,
				margin: 0
			}
		},
		label: {
			color: theme.palette.primary.main
		},
		checkbox: {
			color: theme.palette.primary.main,
			marginRight: 8,
			'&$checked': {
				color: theme.palette.primary.main
			}
		},
		checked: { marginRight: 8 }
	})
)

const Multiselect = (props: any) => {
	const classes = useStyles()
	const [open, setOpen] = React.useState(false)
	const [selectAll, setSelectAll] = React.useState(false)
	const { t } = useTranslation()
	const [allCheckboxesStates, setAllCheckboxesStates] = React.useState<any[]>([])

	useEffect(() => {
		initSelectedOptions(props)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props])

	const initSelectedOptions = (props: any) => {
		const initState: string[] = []
		if (props.selectedOptions) {
			if (props.selectedOptions.length > 0) {
				props.selectedOptions.forEach((opt: any) => {
					initState.push(opt)
				})
				setAllCheckboxesStates(initState)
			} else {
				clearAllCheckboxesStates()
			}

			setSelectAll(props.selectedOptions.length > 0 && props.selectedOptions.length === props.allOptions.length)
		}
	}
	const clearAllCheckboxesStates = () => {
		while (allCheckboxesStates.length) {
			allCheckboxesStates.pop()
		}
	}

	const handleChangeClick = (option: any) => {
		clearAllCheckboxesStates()
		setAllCheckboxesStates(option)
		setSelectAll(allCheckboxesStates.length === props.allOptions.length)
	}

	const getDisplayTags = (selectedArray: any) => {
		if (selectedArray.length > 0) {
			let tempDisplayTags = selectedArray.map((option: any) => option.display).join(', ')
			let currentLength = 30
			while (getTextWidth(tempDisplayTags, null) > 260) {
				tempDisplayTags = tempDisplayTags.substring(0, currentLength) + '...'
				currentLength = currentLength - 3
			}
			return tempDisplayTags
		} else {
			return ''
		}
	}

	const handleSelectAll = () => {
		setSelectAll(allCheckboxesStates.length !== props.allOptions.length)
		if (allCheckboxesStates.length !== props.allOptions.length) {
			clearAllCheckboxesStates()
			allCheckboxesStates.push(...props.allOptions)
		} else {
			clearAllCheckboxesStates()
		}
	}

	const onApply = () => {
		props.handleApply(allCheckboxesStates)

		// hack to make handle apply works correctly
		setTimeout(function () {
			props.handleApply(allCheckboxesStates)
		}, 50)

		setOpen(false)
	}

	const onOpen = () => {
		setOpen(true)
	}

	// START: FOR THE VIRTUALIZATION
	const renderRow = (props: ListChildComponentProps) => {
		const { data, index, style } = props
		return React.cloneElement(data[index], {
			style: {
				...style,
				top: style.top as number
			}
		})
	}

	const OuterElementContext = React.createContext({})

	const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
		const outerProps = React.useContext(OuterElementContext)
		return <div ref={ref} {...props} {...outerProps} />
	})

	const useResetCache = (data: any) => {
		const ref = React.useRef<VariableSizeList>(null)
		React.useEffect(() => {
			if (ref.current != null) {
				ref.current.resetAfterIndex(0, true)
			}
		}, [data])
		return ref
	}

	// Adapter for react-window
	const ListboxComponent = React.forwardRef<HTMLDivElement>(function ListboxComponent(props, ref) {
		const { children, ...other } = props
		const itemData = React.Children.toArray(children)
		const itemCount = itemData.length
		const itemSize = 55

		const getChildSize = () => {
			return itemSize
		}

		const getHeight = () => {
			if (itemCount > 8) {
				return 8 * itemSize + 20
			}
			return itemData.length * itemSize + 20
		}

		const gridRef = useResetCache(itemCount)

		return (
			<div ref={ref}>
				<OuterElementContext.Provider value={other}>
					<VariableSizeList
						itemData={itemData}
						height={getHeight()}
						width="100%"
						ref={gridRef}
						outerElementType={OuterElementType}
						innerElementType="ul"
						itemSize={getChildSize}
						overscanCount={5}
						itemCount={itemCount}
					>
						{renderRow}
					</VariableSizeList>
				</OuterElementContext.Provider>
			</div>
		)
	})

	const renderGroup = (params: AutocompleteRenderGroupParams) => [
		<ListSubheader key={params.key} component="div">
			{params.group}
		</ListSubheader>,
		params.children
	]
	// END: FOR THE VIRTUALIZATION

	return (
		<FormControl className={classes.formControl}>
			<Autocomplete
				id="ms-autocomplete"
				options={props.allOptions}
				open={open}
				onOpen={onOpen}
				onClose={onApply}
				multiple
				getOptionLabel={(option: any) => option.display}
				getOptionSelected={(option, value) => {
					return value.id === option.id
				}}
				value={allCheckboxesStates}
				style={{ width: 250 }}
				disableClearable
				onChange={(event, value) => {
					handleChangeClick(value)
				}}
				disableCloseOnSelect
				noOptionsText="No labels"
				classes={{
					popupIndicator: props.iconStyle,
					popupIndicatorOpen: props.iconStyle,
					popperDisablePortal: classes.popperDisablePortal
				}}
				ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
				renderGroup={renderGroup}
				renderOption={(option, { selected }) => (
					<li {...props} style={{ listStyle: 'none' }}>
						<Checkbox
							classes={{ root: classes.checkbox, checked: classes.checked }}
							name={option.display}
							checked={
								allCheckboxesStates.findIndex((element: any) => element.display === option.display) !== -1
							}
						/>
						{option.display}
					</li>
				)}
				renderTags={() => {
					return getDisplayTags(allCheckboxesStates)
				}}
				renderInput={(option: any) => (
					<TextField {...option} label={props.inputLabel} variant="standard" fullWidth />
				)}
				PaperComponent={({ children }) => (
					<Paper>
						{children}

						<FormControlLabel
							style={{
								display: 'inline-block',
								marginTop: '5px',
								marginBottom: '5px',
								marginLeft: '16px'
							}}
							control={
								<Checkbox
									id="ms-autocomplete-selectAll"
									classes={{ root: classes.checkbox, checked: classes.checked }}
									checked={selectAll}
								/>
							}
							onClick={event => {
								event.preventDefault()
								handleSelectAll()
							}}
							onMouseDown={event => {
								event.preventDefault()
							}}
							label={<span className={classes.label} id='selectAllItems'> {selectAll ? t('ClearAll') : t('SelectAll')} </span>}
						/>
						<Button className={classes.button} onMouseDown={onApply}>
							{t('APPLY')}
						</Button>
					</Paper>
				)}
			/>
		</FormControl>
	)
}

export default Multiselect

function getTextWidth(text: any, font: any): any {
	const canvas = document.createElement('canvas')
	const context = canvas.getContext('2d')

	if (context) {
		context.font = font || getComputedStyle(document.body).font
		return context?.measureText(text).width
	}
	return -1
}
