/** @format */

import { TFunction } from 'react-i18next'
import { ColumnContent } from '../PageDetailStrategy'

export const buildNonSpecificColumn = (
	dataType: string,
	columnName: string,
	t: TFunction<'translation'>
): ColumnContent => {
	return {
		title: t(dataType + '-data.' + columnName + '.name'),
		field: columnName
	}
}
