import { instance, manageResponse, getHeader, manageUploadResponse } from './ApiManager';


const requests = {
    getCertificateData: async (identificationId: string) => {
        const response = await instance.get(`/tgp-certificates/${identificationId}`, getHeader());
        return manageResponse(response);
    },
    uploadCertificate: async (marker: string, filename: string, content: string) => {
        const response = await instance.post('/tgp-certificates/upload', { content, filename, marker }, getHeader())
        return manageUploadResponse(response)
    },
    checkCertificateExist: async (identificationId: string) => {
        const response = await instance.post(`/tgp-certificates/exist`, { identificationId }, getHeader())
        return manageResponse(response)
    }
};

export default requests;