/** @format */

import {Button, ClickAwayListener, makeStyles, MenuItem, MenuList, Paper, Popper} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import _ from 'lodash'
import React from 'react'
import theme from '../../../utils/theme'

const useStyles = makeStyles(t => ({
	button: {
		color: theme.colors.gray,
		textTransform: 'none'
	}
}))

type UserOptionsProps = {
	username?: string
	settings?: Array<OptionProps>
}

type OptionProps = {
	key?: string
	label?: string
	onClick?: () => void
}

const UserOptions = (props: UserOptionsProps) => {
	const classes = useStyles()

	const [toggleOpen, setToggleOpen] = React.useState<boolean>(false)
	const anchorRef = React.useRef<HTMLButtonElement>(null)


	const handleOpen = () => {
		setToggleOpen(true)
	}

	const handleClose = () => {
		setToggleOpen(false)
	}

	const onOptionClick = (onClick: any) => () => {
		handleClose()
		onClick()
	}

	return (
		<>
			<Button
				ref={anchorRef}
				aria-controls="fade-menu"
				aria-haspopup="true"
				onClick={handleOpen}
				className={classes.button}>
					{props.username}
					<ArrowDropDownIcon />
			</Button>
			<Popper
				open={toggleOpen}
				anchorEl={anchorRef.current}
				transition
				disablePortal
				style={{ zIndex: 99 }}
			>
				<Paper>
					<ClickAwayListener onClickAway={handleClose}>
						<MenuList id="fade-menu" autoFocusItem={toggleOpen} >
							{_.map(props.settings, (option: OptionProps) => (
								<MenuItem onClick={onOptionClick(option.onClick)} key={option.label}>
									{option.label}
								</MenuItem>
							))}
						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	)
}

export default UserOptions
