/** @format */

import { Box, IconButton } from '@material-ui/core'
import { VisibilityOutlined } from '@material-ui/icons'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import RackService from '../api/RackService'
import Card from '../components/common/Card'
import ChipsFilters from '../components/common/ChipsFilters'
import FilterableTable from '../components/common/FilterableTable'
import { PipeItemDto } from '../models/dto/PipeItemDto'
import { RackItemDto } from '../models/dto/RackItemDto'
import { RackItemInTableModel } from '../models/RackItemInTableModel'
import { AppContext } from '../stores/AppStore'
import { RackContext } from '../stores/RackStore'
import { UserContext } from '../stores/UserStore'
import { BackgroundColorUtils } from '../utils/BackgroundColorUtils'
import { FilterUtils } from '../utils/FilterUtils'
import { RackUtils } from '../utils/RackUtils'
import theme from '../utils/theme'
import { contextRackContentStrategy } from '../business/rackContentStrategy/ContextRackContentStrategy'
import { ColumnContent } from '../business/rackContentStrategy/RackContentStrategy'

const RackContent = () => {
	const { t } = useTranslation()

	const { appState, appDispatch } = useContext(AppContext)
	const { rackState, rackDispatch } = useContext(RackContext)
	const { userState, userDispatch } = useContext(UserContext)
	const [expandSide2, setExpandSide2] = useState(false)

	const userLayout = userState.user.layout
	const contentColumnStrategy = contextRackContentStrategy(userLayout)

	let history = useHistory()

	const columnsConvert = useMemo(() => new Map(), [])

	const setPageLoading = useCallback(
		(pageLoading: boolean) => {
			appDispatch({ type: 'set_page_loading', pageLoading })
		},
		[appDispatch]
	)

	const getRackData = useCallback(() => {
		RackService.getRackData()
			.then(data => {
				const array: any = data.rackDataList
					.sort((item1: RackItemDto, item2: RackItemDto) => RackUtils.sortRacks(item1, item2))
					.map((element: RackItemDto) => {
						const parentId = 'rack_' + element.rackId

						return [
							{
								id: parentId,
								rackId: t(element.rackId),
								pipeCount: contentColumnStrategy.getNumberOfItemsInRack(element.itemDataList),
								isDeletable: element.deletable,
								numberFilteredPipes: 0,
								tableData: {
									childRows: element.itemDataList.map((item: PipeItemDto) => ({
										...item,
										pipeCount: contentColumnStrategy.getPipeCount(item.jointNumber),
										parentId,
										identificationId: item.identificationId,
										id: 'pipe_' + item.identificationId
									}))
								}
							},
							...element.itemDataList.map((item: PipeItemDto) => ({
								...item,
								pipeCount: contentColumnStrategy.getPipeCount(item.jointNumber),
								parentId,
								identificationId: item.identificationId,
								id: 'pipe_' + item.identificationId
							}))
						]
					})
					.flat()

				array.forEach((item: any) => {
					if (item.jointNumber) {
						BackgroundColorUtils.defineBackgroundColor(item.jointNumber)
					}
				})
				if (!rackState.filteredColumnsMap?.size) {
					rackDispatch({ type: 'set_rack_data', rackData: array })
				}
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rackDispatch, setPageLoading, t, userDispatch])

	useEffect(() => {
		setPageLoading(true)
		appDispatch({ type: 'set_page_subtitle', pageSubTitle: t(contentColumnStrategy.formatSubtitle(userLayout)) })
		rackDispatch({ type: 'init_rack_properties' })
		if (!rackState.filteredColumnsMap?.size) {
			rackDispatch({ type: 'remove_all_filters' })
		}

		getRackData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [appDispatch, getRackData, rackDispatch, setPageLoading, t])

	const onFilterChange = useCallback(
		(nameOfColumn: string) => {
			return (columnId: any, paramFilter: any) => {
				const filteredColumnsMap = FilterUtils.getFilteredColumnMap(
					nameOfColumn,
					paramFilter,
					rackState.filteredColumnsMap
				)
				rackDispatch({
					type: 'set_filtered_columns_map',
					filteredColumnsMap,
					checkedLength: userState.checkedLength,
					checkedWeight: userState.checkedWeight,
					columnsConvert
				})
			}
		},
		[rackDispatch, rackState.filteredColumnsMap, userState.checkedLength, userState.checkedWeight, columnsConvert]
	)

	const headerBackgroundColor = useCallback(() => {
		return expandSide2 ? '#F2FAFD' : '#FFFFFF'
	}, [expandSide2])

	const chooseUnitLengthMmtoInch = userState.checkedLength ? t('inch') : t('mm')
	const chooseUnitLengthMmtoFt = userState.checkedLength ? t('ft') : t('mm')
	const chooseUnitWeight = userState.checkedWeight ? t('lb') : t('kg')

	useEffect(() => {
		const displayPipeDetails = (pipeId?: string) => {
			if (pipeId) {
				let pipeDetails = contentColumnStrategy.createPipeDetail(rackState, pipeId)

				rackDispatch({ type: 'set_pipe_id', pipeId })
				rackDispatch({ type: 'set_pipe_details', pipeDetails })

				history.push(contentColumnStrategy.redirectionUrl(pipeId, userLayout))
			}
		}

		const rackColumns: ColumnContent[] = contentColumnStrategy.buildColumns(
			t,
			rackState,
			userState,
			onFilterChange,
			chooseUnitLengthMmtoInch,
			headerBackgroundColor,
			expandSide2,
			chooseUnitLengthMmtoFt,
			chooseUnitWeight
		)
		rackColumns.push({
			render: (rowData: RackItemInTableModel) => (
				<Box alignItems="center" justifyContent="center" width="100%">
					{rowData.identificationId ? (
						<IconButton
							color="primary"
							aria-label="show-detail"
							component="span"
							onClick={() => {
								displayPipeDetails(rowData.identificationId)
							}}
							style={{ boxShadow: '0px 2px 4px #00000029', borderRadius: '4px' }}
						>
							<VisibilityOutlined style={{ color: theme.colors.darkBlue }} />
						</IconButton>
					) : (
						''
					)}
				</Box>
			)
		})

		rackDispatch({
			type: 'set_rack_columns',
			rackColumns
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		history,
		onFilterChange,
		rackDispatch,
		rackState.rackData,
		rackState.displayedPipeData,
		rackState.filteredColumnsMap,
		t,
		rackState.searchedText,
		userState.checkedLength,
		userState.checkedWeight,
		columnsConvert,
		chooseUnitLengthMmtoFt,
		chooseUnitLengthMmtoInch,
		chooseUnitWeight,
		expandSide2,
		headerBackgroundColor
	])

	const setExpandRow = (row: any) => {
		let currentSelectedRacks = rackState.selectedRacks
		if (row.tableData.isTreeExpanded) {
			currentSelectedRacks.push(row.id)
		} else {
			currentSelectedRacks = currentSelectedRacks.filter(rack => rack !== row.id)
		}
		rackDispatch({
			type: 'set_selected_racks',
			selectedRacks: currentSelectedRacks
		})
	}

	return (
		<Box width="100%" height="100%">
			{appState.pageLoading ? (
				<div />
			) : (
				<div id="rack-content">
					<Card alignSelf="stretch" width="calc(100% - 64px)" margin="23px 33px">
						<FilterableTable
							columns={rackState.rackColumns}
							columnNames={contentColumnStrategy.getColumnNames()}
							data={rackState.displayedPipeData}
							allData={rackState.rackData}
							vam={contentColumnStrategy.isVam}
							yellowtail={contentColumnStrategy.isTail}
							getTableName={() => 'main_table'}
							getCurrentPageNumber={(reference: string) => {
								return rackState.pageNumberMap.get(reference) === undefined
									? 0
									: rackState.pageNumberMap.get(reference)
							}}
							setCurrentPageNumber={(pageNumber: number, reference: string) => {
								rackDispatch({ type: 'set_page_number', pageNumber, reference })
							}}
							setSearchedText={(searchedText: string) =>
								rackDispatch({ type: 'set_searched_text', searchedText })
							}
							searchedText={rackState.searchedText}
							setOpenedRacks={(racks: string[]) => rackDispatch({ type: 'set_opened_racks', racks: racks })}
							setClosedRacks={(racks: string[]) => rackDispatch({ type: 'set_closed_racks', racks: racks })}
							openedRacks={rackState.openedRacks}
							closedRacks={rackState.closedRacks}
							getCurrentPageSize={(reference: string) => {
								return rackState.pageSizeMap.get(reference) || 10
							}}
							setCurrentPageSize={(pageSize: number, reference: string) => {
								rackDispatch({ type: 'set_page_size', pageSize, reference })
							}}
							expandSide2={expandSide2}
							setExpandSide2={(value: boolean) => {
								setExpandSide2(value)
							}}
							setExpandRow={setExpandRow}
							pipesBackgroundColor={BackgroundColorUtils.getBackgroundColorMap()}
							title={t(contentColumnStrategy.formatSubtitle(userLayout))}
							subtitle={
								<ChipsFilters
									isPipeView={true}
									portail={contentColumnStrategy.formatDataType() + '.'}
									filters={rackState.filteredColumnsMap}
									stateDispatch={rackDispatch}
									columnsConvert={columnsConvert}
									checkedLength={userState.checkedLength}
									checkedWeight={userState.checkedWeight}
								/>
							}
							displayPagination={true}
						/>
					</Card>
				</div>
			)}
		</Box>
	)
}

export default RackContent
