/** @format */

import { ComponentProps, FC } from 'react'
import { AppProvider } from './AppStore'
import { OctgProvider } from './OctgStore'
import { RackProvider } from './RackStore'
import { VamRackProvider } from './VamRackStore'
import { YellowtailRackProvider } from './YellowtailRackStore'
import {SlabProvider} from "./SlabStore"

const providers = [AppProvider, RackProvider, YellowtailRackProvider, VamRackProvider, OctgProvider, SlabProvider]

export const combineComponents = (...components: FC[]): FC => {
	return components.reduce(
		(AccumulatedComponents, CurrentComponent) => {
			return ({ children }: ComponentProps<FC>): JSX.Element => {
				return (
					<AccumulatedComponents>
						<CurrentComponent>{children}</CurrentComponent>
					</AccumulatedComponents>
				)
			}
		},
		({ children }) => <>{children}</>
	)
}

export const AppContextProvider = combineComponents(...providers)
