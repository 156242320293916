import { makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React from 'react';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((t) => ({
      root: {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
            minWidth: 300,
      }
}));


const UploadJointDataPopup = (props: any) => {
      const classes = useStyles();
      const { t } = useTranslation();

      return (
            <div >
                  <Dialog open={props.open} aria-labelledby="form-dialog-title" >

                        <DialogContent className={classes.root}>
                              <Typography variant="h5" gutterBottom>
                                    {t('File to load')}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                    {props.filename}
                              </Typography>
                        </DialogContent>
                        <DialogActions style={{ marginTop: '30px' }}>
                              <Button onClick={props.handleCancel} color="primary">
                                    {t('Cancel')}
                              </Button>
                              <Button onClick={props.handleLoad} color="primary">
                                    {t('Load')}
                              </Button>
                        </DialogActions>
                  </Dialog>
            </div>
      );
}

export default UploadJointDataPopup;