/** @format */

import { instance, manageResponse, getHeader } from './ApiManager'

const requests = {
	getPipeByIdentificationId: async (identificationId: string) => {
		const response = await instance.get('/tgp-generic-product/' + identificationId, getHeader())
		return manageResponse(response)
	},

	findPipeByTallyNumberAndOrderNumberAndItemNumber: async (
		tallyNumber: string,
		orderNumber: string,
		itemNumber: number
	) => {
		const response = await instance.post('/tgp-pipes/plp', { tallyNumber, orderNumber, itemNumber }, getHeader())
		return manageResponse(response)
	},

	findYellowTailPipeByVsbId: async (vsbId: string) => {
		const response = await instance.get('/tgp-pipes/yellowtail-pipe/' + vsbId, getHeader())
		return manageResponse(response)
	},

	findYellowTailPipeByOrderAndItem: async (vsbOrder: string, customerItem: string) => {
		const response = await instance.get('/tgp-pipes/list-pipes-by-order-item?vsbOrder=' + vsbOrder +'&customerItem=' +customerItem, getHeader())
		return manageResponse(response)
	},

	getOCTGPipes: async () => {
		const response = await instance.get('/tgp-pipes/octg-pipe-list', getHeader())
		return manageResponse(response)
	},

	getValidList: async (numberOfValid: number) => {
		const response = await instance.get('/tgp-pipes/valid-list/' + numberOfValid, getHeader())
		return manageResponse(response)
	}
}

export default requests
