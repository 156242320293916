/** @format */

import { RackItemInTableModel } from '../../../models/RackItemInTableModel'
import PopoverFilter from '../../../containers/common/PopoverFilter'
import { TFunction } from 'react-i18next'
import { RackState } from '../../../stores/RackStore'
import { ColumnContent } from '../RackContentStrategy'

export const buildRackIdColumn = (
	dataType: string,
	t: TFunction<'translation'>,
	rackState: RackState,
	onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void
): ColumnContent => {
	return {
		title: t(dataType + '-data.rackId.name'),
		field: 'rackId',
		render: (rowData: RackItemInTableModel) => <span>{rowData.rackId ? t(`${rowData.rackId}`) : ''}</span>,
		filterComponent: (props: any) => (
			<PopoverFilter
				{...props}
				searchedText={rackState.searchedText}
				isAlphanumeric={t(dataType + '-data.rackId.filter') === 'alphanumeric'}
				onFilterChanged={onFilterChange('rackId')}
				columnField={'rackId'}
				stateData={rackState.displayedPipeData}
				stateFilteredColumnsMap={rackState.filteredColumnsMap}
			/>
		)
	}
}
