import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, makeStyles, Radio, RadioGroup, Typography } from '@material-ui/core';
import { UserContext } from '../../stores/UserStore'


const useStyles = makeStyles((t) => ({
      root : {
            display: 'flex',
            flexDirection: 'column',
            margin: 'auto',
            width: 'fit-content',
            minWidth: 300,
      }
}));


const UploadDocumentPopup = (props: any) => {
      const classes = useStyles();
      const { t } = useTranslation();
	const { userState } = useContext(UserContext)

      const [valid, setValid] = useState('');
      const [documentType, setDocumentType] = useState('QUALITY_CERTIFICATE');

      const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setValid(event.target.value);
      };

      const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            setDocumentType(event.target.value);
          };
   
      return (
            <div >
                  <Dialog open={ props.open }  aria-labelledby="form-dialog-title" >

                        <DialogContent className={classes.root}>
                              <Typography variant="h5" gutterBottom>
                                    {t('File to load')}
                              </Typography>
                              <Typography variant="body1" gutterBottom>
                                    { props.filename }
                              </Typography>

                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    {t('document_type')}
                              </Typography>
                              <RadioGroup
                                    aria-label="documentType"
                                    defaultValue="quality_certificate"
                                    name="radio-buttons-group"
                                    value= {documentType}
                                    onChange={handleRadioChange}
                              >
                                    <FormControlLabel value="QUALITY_CERTIFICATE" control={<Radio />} label={ t('document_type_quality_certificate')} labelPlacement="start" />
                                    <FormControlLabel value="DIGITAL_LOG_SHEET" control={<Radio />} label={ t('document_type_digital_log_sheet')} labelPlacement="start" />
                              </RadioGroup>

                              <Typography variant="h5" gutterBottom style={{marginTop:'30px'}}>
                                    				{userState.user.layout.includes('OCTG') ? (
                                    					t('associated_valid_pipe')
                                    				) : (
                                    				    t('associated_valid_accessories')
                                    				)}
                              </Typography>
                              <TextField
                                    id="valid-text"
                                    label=""
                                    variant="outlined"
                                    value= {valid}
                                    onChange={ handleChange }
                              />
                        
                         </DialogContent>
                        <DialogActions style={{marginTop:'30px'}}>
                        <Button onClick={ () => {
                              props.handleCancel();
                              setValid('');
                              setDocumentType('QUALITY_CERTIFICATE');
                        } } color="primary">
                              { t('Cancel')}
                        </Button>
                        <Button onClick={ () => {
                              props.handleLoad(valid, documentType);
                              setValid('');
                              setDocumentType('QUALITY_CERTIFICATE');
                         }
                              } disabled = {valid.length === 0 || documentType === ''} color="primary">
                              { t('Load')}
                        </Button>
                        </DialogActions>
                  </Dialog>
            </div>
      );
}

export default UploadDocumentPopup;