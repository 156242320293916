/** @format */

import { getHeader, instance, manageResponse } from './ApiManager'
import { SlabModel } from '../models/SlabModel'
import { StateFieldDto } from '../models/dto/StateFieldDto'

function getQualityCheck(slab: {
	customerTagId?: StateFieldDto<String>
	hkmPieceId?: StateFieldDto<String>
	slabIdHeatPlusNumber?: StateFieldDto<String>
	customerGrade?: StateFieldDto<String>
	heat?: any
	blDate?: StateFieldDto<String>
	actualThickness?: StateFieldDto<number>
	actualLength?: StateFieldDto<number>
	actualWidth?: StateFieldDto<number>
	actualWeight?: StateFieldDto<number>
	standardGrade?: StateFieldDto<String>
	orderItem: any
	qualityCheck?: any
}): number {
	let count: number = 0
	if (slab.qualityCheck && slab.qualityCheck.datamatrix && slab.qualityCheck.datamatrix.defaultValue === true) {
		count++
	}

	if (slab.qualityCheck && slab.qualityCheck.barcode && slab.qualityCheck.barcode.defaultValue === true) {
		count++
	}

	if (slab.qualityCheck && slab.qualityCheck.rfid && slab.qualityCheck.rfid.defaultValue === true) {
		count++
	}

	return count
}
const requests = {
	getAllSlabs: async (): Promise<SlabModel[]> => {
		const response = await instance.get('/tgp-slabs/slabs', getHeader())
		const data = manageResponse(response)

		return data.slabList.map(
			(slab: {
				customerTagId?: StateFieldDto<String>
				hkmPieceId?: StateFieldDto<String>
				slabIdHeatPlusNumber?: StateFieldDto<String>
				customerGrade?: StateFieldDto<String>
				heat?: any
				blDate?: StateFieldDto<String>
				actualThickness?: StateFieldDto<number>
				actualLength?: StateFieldDto<number>
				actualWidth?: StateFieldDto<number>
				actualWeight?: StateFieldDto<number>
				standardGrade?: StateFieldDto<String>
				orderItem: any
				qualityCheck?: any
			}) => {
				// Convert from objects containing StateFieldDTO to objects containing simple attributes
				return {
					customerTagId: slab.customerTagId && slab.customerTagId.defaultValue,
					hkmPieceId: slab.hkmPieceId && slab.hkmPieceId.defaultValue,
					slabIdHeatPlusNumber: slab.slabIdHeatPlusNumber && slab.slabIdHeatPlusNumber.defaultValue,
					customerGrade: slab.customerGrade && slab.customerGrade.defaultValue,
					heat: slab.heat && slab.heat.label.defaultValue,
					blDate: slab.blDate && slab.blDate.defaultValue,
					actualThickness: slab.actualThickness && slab.actualThickness.defaultValue,
					actualLength: slab.actualLength && slab.actualLength.defaultValue,
					actualWidth: slab.actualWidth && slab.actualWidth.defaultValue,
					actualWeight: slab.actualWeight && slab.actualWeight.defaultValue,
					standardGrade: slab.standardGrade && slab.standardGrade.defaultValue,
					nominalThickness:
						slab.orderItem && slab.orderItem.wallThickness && slab.orderItem.wallThickness.defaultValue,
					nominalWidth:
						slab.orderItem && slab.orderItem.outerDiameter && slab.orderItem.outerDiameter.defaultValue,
					nominalLength:
						slab.orderItem && slab.orderItem.lengthDisplay && slab.orderItem.lengthDisplay.defaultValue,
					nominalWeight:
						slab.orderItem && slab.orderItem.nominalWeight && slab.orderItem.nominalWeight.defaultValue,
					orderNumber: slab.orderItem && slab.orderItem.orderNumber && slab.orderItem.orderNumber.defaultValue,
					lineNumber: slab.orderItem && slab.orderItem.lineNumber && slab.orderItem.lineNumber.defaultValue,
					qualityCheck: getQualityCheck(slab)
				}
			}
		)
	}
}

export default requests
