/** @format */

import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import CardInfo from '../../components/common/CardInfo'
import i18n from '../../i18n'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import { UserContext } from '../../stores/UserStore'
import { MeasureUtils } from '../../utils/MeasureUtils'

type YellowtailPipeDetailsCardInfoProps = {
	yellowtailPipe: RackItemInTableModel
}

const YellowtailPipeDetailsCardInfo = (props: YellowtailPipeDetailsCardInfoProps) => {
	const { t } = useTranslation()
	const { userState } = useContext(UserContext)

	const key = 'yellowtail-data.'
	const chooseUnitLengthMmtoInch = userState.checkedLength ? t('inch') : t('mm')

	const displayColumnValue = (nameOfColumn: any) => {
		if (nameOfColumn) {
			return userState.checkedLength
				? MeasureUtils.convertValueFromMMToInch(nameOfColumn)
				: MeasureUtils.toFixed(nameOfColumn, 2)
		}
		return ''
	}

	const info = [
		{
			label:
				i18n.language === 'en'
					? t(key + 'outerDiameter_en.name') + chooseUnitLengthMmtoInch
					: t(key + 'outerDiameter.name') + chooseUnitLengthMmtoInch,
			value: displayColumnValue(props.yellowtailPipe.outerDiameter)
		},
		{
			label:
				i18n.language === 'en'
					? t(key + 'internalDiameter_en.name') + chooseUnitLengthMmtoInch
					: t(key + 'internalDiameter.name') + chooseUnitLengthMmtoInch,
			value: displayColumnValue(props.yellowtailPipe.internalDiameter)
		},
		{
			label:
				i18n.language === 'en'
					? t(key + 'wallThickness_en.name') + chooseUnitLengthMmtoInch
					: t(key + 'wallThickness.name') + chooseUnitLengthMmtoInch,
			value: displayColumnValue(props.yellowtailPipe.wallThickness)
		},
		{
			label: t(key + 'genericGrade.name'),
			value: props.yellowtailPipe.genericGrade ? props.yellowtailPipe.genericGrade : ''
		}
	]

	return <CardInfo info={info} />
}

export default YellowtailPipeDetailsCardInfo
