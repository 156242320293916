/** @format */

import React, {Dispatch, useReducer} from 'react'
import {SlabModel} from "../models/SlabModel"
import {cloneDeep} from "lodash"
import {FilterUtils} from "../utils/FilterUtils"

type State = {
	allSlabs: SlabModel[]
	displayedSlabs: SlabModel[]
	pageNumber: number
	pageSize: number
	slabColumns: any[]
	searchedText: string
	filteredColumnsMap: Map<string, any>
}

type Action =
	| { type: 'set_all_slabs'; allSlabs: SlabModel[] }
	| { type: 'set_page_number'; pageNumber: number }
	| { type: 'set_page_size'; pageSize: number }
	| { type: 'init_table' }
	| { type: 'set_slab_columns'; slabColumns: any[] }
	| { type: 'set_displayed_slabs'; displayedSlabs: SlabModel[] }
	| { type: 'set_searched_text'; searchedText: string }
	| {
	type: 'set_filtered_columns_map'
	filteredColumnsMap: Map<string, any>
	}
		| {
		type: 'remove_filter_from_columns_map'
		nameOfColumns: any
		checkedLength: boolean
		checkedWeight: boolean
		columnsConvert: Map<string, any>
	}
	| { type: 'remove_all_filters' }

const initialState: State = {
	allSlabs: [],
	displayedSlabs: [],
	pageNumber: 0,
	pageSize: 10,
	slabColumns: [],
	searchedText: '',
	filteredColumnsMap: new Map(),
}

export const SlabContext = React.createContext<{
	slabState: State
	slabDispatch: Dispatch<Action>
}>({
	slabState: initialState,
	slabDispatch: () => null
})

const reducer = (state: State, action: Action): State => {
	let filteredDataToDisplay: any

	switch (action.type) {
		case 'set_all_slabs':
			return {
				...state,
				allSlabs: action.allSlabs,
				displayedSlabs: action.allSlabs
			}
		case 'set_page_number':
			localStorage.setItem('page_number', JSON.stringify(action.pageNumber))
			return {
				...state,
				pageNumber: action.pageNumber
			}
		case 'set_page_size':
			localStorage.setItem('page_size', JSON.stringify(action.pageSize))
			return {
				...state,
				pageSize: action.pageSize
			}
		case 'init_table':
			return {
				...state,
				pageNumber: localStorage.getItem('page_number') ? JSON.parse(localStorage.getItem('page_number')!) : 0,
				pageSize: localStorage.getItem('page_size') ? JSON.parse(localStorage.getItem('page_size')!) : 10
			}
		case 'set_slab_columns':
			return {
				...state,
				slabColumns: action.slabColumns
			}
		case 'set_displayed_slabs':
			return {
				...state,
				displayedSlabs: action.displayedSlabs
			}
		case 'set_searched_text':
			return {
				...state,
				searchedText: action.searchedText
			}
		case 'set_filtered_columns_map':
			// apply all filtered to a buffer
			filteredDataToDisplay = updateFiltersForDisplayed(
				action.filteredColumnsMap,
				cloneDeep(state.allSlabs)
			)
			return {
				...state,
				displayedSlabs: filteredDataToDisplay,
				filteredColumnsMap: action.filteredColumnsMap
			}
		case 'remove_filter_from_columns_map':
			const filteredColumnsMap = cloneDeep(state.filteredColumnsMap)
			filteredColumnsMap.delete(action.nameOfColumns)

			filteredDataToDisplay = updateFiltersForDisplayed(
				filteredColumnsMap,
				cloneDeep(state.allSlabs)
			)
			return {
				...state,
				displayedSlabs: filteredDataToDisplay,
				filteredColumnsMap: filteredColumnsMap
			}
		case 'remove_all_filters':
			return {
				...state,
				displayedSlabs: state.allSlabs,
				filteredColumnsMap: new Map()
			}
	}
}

const updateFiltersForDisplayed = (
	filteredColumnMap: Map<string, any>,
	dataToDisplay: any[]
) => {
	updateFilters(filteredColumnMap, dataToDisplay)
	filteredColumnMap.forEach((value: any, key: string) => {
		dataToDisplay = applyFilter(
			dataToDisplay,
			value.value,
			value.min,
			value.max,
			value.selectedValues,
			key
		)
	})
	return dataToDisplay
}

const updateFilters = (
	filteredColumnMap: Map<string, any>,
	dataToDisplay: any[],
) => {
	if (filteredColumnMap !== undefined) {
		FilterUtils.updateFilter(
			filteredColumnMap,
			dataToDisplay
		)
	}
}

const applyFilter = (
	tab: any,
	value: any,
	min: any,
	max: any,
	selectedValues: any,
	key: string
) => {
	tab.forEach((row: any) => {
		row['tableData']['childRows'] = tab.filter((child: any) => row.id === child.parentId)
	})
	let manyValuesSelected: string[] = []
	return tab.filter((row: any) => {
		let val = String(row[key])
		manyValuesSelected = selectedValues

		if (manyValuesSelected.length === 0) {
			manyValuesSelected = [value]
		}

		return FilterUtils.compareValAgainstValuesAndMinAndMax(
			val,
			manyValuesSelected,
			min,
			max
		)
	})
}


export const SlabProvider = (props: any) => {
	const [slabState, slabDispatch] = useReducer(reducer, initialState)

	return <SlabContext.Provider value={{ slabState, slabDispatch }}>{props.children}</SlabContext.Provider>
}
