/** @format */

import { RackItemInTableModel } from '../../../models/RackItemInTableModel'
import { MeasureUtils } from '../../../utils/MeasureUtils'
import { TFunction } from 'react-i18next'
import { UserState } from '../../../stores/UserStore'
import { ColumnContent } from '../PageDetailStrategy'
import i18n from '../../../i18n'
import { toInteger } from 'lodash'

export const buildWeightColumn = (
	dataType: string,
	t: TFunction<'translation'>,
	userState: UserState,
	chooseUnitWeight: string | undefined
): ColumnContent => {
	return {
		title:
			i18n.language === 'en'
				? t(dataType + '-data.weight_en.name') + chooseUnitWeight
				: t(dataType + '-data.weight.name') + chooseUnitWeight,
		field: 'weight',
		render: (rowData: RackItemInTableModel) => (
			<span>
				{rowData && rowData.weight && userState.checkedWeight
					? MeasureUtils.toFixed(toInteger(MeasureUtils.convertValueFromKgToLbs(rowData.weight)), 0)
					: rowData.weight}
			</span>
		)
	}
}
