/** @format */

import React, { useContext, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect } from 'react-router'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import BackgroundImage from '../assets/images/background.jpg'
import LogoImage from '../assets/images/logo.png'
import LoadingOverlay from '../components/common/LoadingOverlay'
import ForgotPasswordForm from '../containers/user/ForgotPasswordForm'
import LoginForm from '../containers/user/LoginForm'
import { UserContext } from '../stores/UserStore'
import { Validation } from '../models/Validation'
import { DirtyError } from '../models/DirtyError'
import ChangePasswordForm from '../containers/user/ChangePasswordForm'
import { AppContext } from '../stores/AppStore'
import Card from '../components/common/Card'
import { Box, makeStyles } from '@material-ui/core'
import theme from '../utils/theme'

const useStyles = makeStyles({
	logo: {
		width: '300px',
		margin: '20px auto 30px'
	},
	version: {
		margin: '10px 0px -5px',
		fontSize: theme.fontSizes.small,
		alignSelf: 'flex-end',
		color: theme.colors.lightGray,
		fontWeight: 'bold'
	},
	background: {
		background: `url(${BackgroundImage}) no-repeat center center fixed`,
		backgroundSize: 'cover',
		backgroundColor: theme.colors.background,
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		fontSize: theme.fontSizes.medium,
		color: theme.colors.gray,
		fontWeight: 'bold'
	}
})

const Login = (props: any) => {
	const { t } = useTranslation()
	const { appState, appDispatch } = useContext(AppContext)
	const { userState, userDispatch } = useContext(UserContext)
	const [email, setEmail] = useState('')

	const [validation, setValidation] = useState<Validation>({
		email: {
			dirty: false,
			error: false
		},
		password: {
			dirty: false,
			error: false
		},
		confirmPassword: {
			dirty: false,
			error: false
		},
		userError: ''
	})

	const redirectIfAuthenticated = () => {
		let redirect = null
		if (userState.authState === 'SIGNED_IN') {
			let redirectRouteToGo = '/rack-content'
			if (userState.user.layout === 'VAM_licensees') {
				redirectRouteToGo = '/rack-content-vam'
			} else if (userState.user.layout === 'YELLOWTAIL_ADMIN') {
				redirectRouteToGo = '/rack-content-uaru'
			} else if (userState.user.layout === 'WHIPTAIL') {
				redirectRouteToGo = '/rack-content-whiptail'
			} else if (userState.user.layout.includes('OCTG')) {
				redirectRouteToGo = '/upload-joint-data'
			} else if (userState.user.layout === 'SLABS') {
				redirectRouteToGo = '/slabs-content'
			}
			const redirectPath =
				props.location.state && props.location.state.from ? props.location.state.from : redirectRouteToGo
			redirect = <Redirect to={`${redirectPath}${props.location.search}`} />
		}
		return redirect
	}

	const checkValidation = (e: ChangeEvent) => {
		const htmlInputElement = e.target as HTMLInputElement
		setValidation({
			...validation,
			[htmlInputElement.name]: {
				dirty: true,
				error: htmlInputElement.validationMessage.length > 0
			}
		})
	}

	const updateValue = (fieldName: keyof Validation | null, setFunction: Function) => (e: ChangeEvent) => {
		fieldName && (validation[fieldName] as DirtyError).dirty && checkValidation(e)
		if (e !== null && e.target !== null) {
			setFunction((e.target as HTMLTextAreaElement).value)
		}
	}

	const setPageLoading = (pageLoading: boolean) => {
		appDispatch({ type: 'set_page_loading', pageLoading })
	}

	const renderForm = () => {
		switch (userState.authState) {
			case 'FORGOT_PASSWORD':
				return (
					<ForgotPasswordForm
						{...{
							setPageLoading,
							email,
							setEmail,
							validation,
							setValidation,
							checkValidation,
							updateValue
						}}
					/>
				)
			case 'NEW_PASSWORD_REQUIRED':
				return (
					<ChangePasswordForm
						{...{
							setPageLoading,
							validation,
							setValidation,
							checkValidation,
							updateValue
						}}
					/>
				)
			default:
				return (
					<LoginForm
						{...{
							setPageLoading,
							email,
							setEmail,
							validation,
							setValidation,
							checkValidation,
							updateValue
						}}
					/>
				)
		}
	}

	const classes = useStyles()
	return (
		<Box className={classes.background}>
			{redirectIfAuthenticated()}
			<CSSTransition
				in={['FORGOT_PASSWORD', 'NEW_PASSWORD_REQUIRED'].includes(userState.authState)}
				timeout={300}
				unmountOnExit
				classNames="fade"
			>
				<Box
					display="flex"
					flexDirection="row"
					alignItems="center"
					margin="20px 0px"
					style={{ cursor: 'pointer' }}
					onClick={() => {
						userDispatch({ type: 'reset_auth_state' })
					}}
				>
					<ArrowBackIcon />
					{t('Go Back')}
				</Box>
			</CSSTransition>
			<Card width="80vw" maxWidth="460px" justifyContent="center" alignItems="center" padding="24px 32px">
				<LoadingOverlay borderRadius="card" visible={appState.pageLoading} />
				<img src={LogoImage} alt="Application Logo" className={classes.logo} />
				<SwitchTransition>
					<CSSTransition key={userState.authState} timeout={300} classNames="fade">
						{renderForm()}
					</CSSTransition>
				</SwitchTransition>
				<Box className={classes.version}>{userState.settings[0] && userState.settings[0].label}</Box>
			</Card>
		</Box>
	)
}

export default Login
