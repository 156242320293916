/** @format */

import { RackContentStrategy, ColumnContent } from './RackContentStrategy'
import { PipeItemDto } from '../../models/dto/PipeItemDto'
import { buildRackIdColumn } from './columnBuilder/buildRackIdColumn'
import { TFunction } from 'react-i18next'
import { RackState } from '../../stores/RackStore'
import { UserState } from '../../stores/UserStore'
import { buildLengthTailColumn } from './columnBuilder/buildLengthColumn'
import { buildNonSpecificColumn } from './columnBuilder/buildNonSpecificColumn'
import { RackItemInTableModel } from '../../models/RackItemInTableModel'
import { buildSurfaceTreatmentColumn } from './columnBuilder/vamColumn/buildSurfaceTreatmentColumn'
import { buildVamSpecificColumn } from './columnBuilder/vamColumn/buildVamSpecificColumn'

export class VamRackContentStrategy implements RackContentStrategy {
	private readonly columnNames = [
		'rackId',
		'identificationId',
		'serialNumber',
		'accessoryType',
		'customer',
		'salesOrder',
		'workOrder',
		'end1OuterDiameter',
		'end1weight',
		'end1SteelGrade',
		'end1ConnectionType',
		'end1SurfaceTreatment',
		'end2OuterDiameter',
		'end2weight',
		'end2SteelGrade',
		'end2ConnectionType',
		'end2SurfaceTreatment',
		'length',
		'heatNumber',
		'pipeCount',
		'jointNumber'
	]
	private readonly dataType = 'vam'

	formatSubtitle() {
		return 'vam_data'
	}

	formatDataType() {
		return 'vam-data'
	}

	redirectionUrl(pipeId: string): string {
		return '/accessory/' + pipeId
	}

	getNumberOfItemsInRack(itemDataList: PipeItemDto[]): number {
		return itemDataList.length
	}

	getPipeCount(): number {
		return 1
	}

	createPipeDetail(rackState: RackState, pipeId: string): RackItemInTableModel[] {
		// Add the selected pipe
		let pipeDetails = rackState.rackData.filter(pipe => pipeId === pipe.identificationId)

		return pipeDetails
	}

	isTail(): boolean {
		return false
	}

	isVam(): boolean {
		return true
	}

	getColumnNames(): string[] {
		return this.columnNames
	}

	buildColumns(
		t: TFunction<'translation'>,
		rackState: RackState,
		userState: UserState,
		onFilterChange: (nameOfColumn: string) => (columnId: any, paramFilter: any) => void,
		chooseUnitLengthMmtoInch: string,
		headerBackgroundColor: () => string,
		expandSide2?: boolean,
		chooseUnitLengthMmtoFt?: string,
		chooseUnitWeight?: string
	): ColumnContent[] {
		return this.columnNames.map(columnName => {
			if (columnName === 'rackId') {
				return buildRackIdColumn(this.dataType, t, rackState, onFilterChange)
			} else if (columnName === 'length') {
				return buildLengthTailColumn(this.dataType, t, rackState, userState, onFilterChange, chooseUnitLengthMmtoFt)
			} else if (['end1weight', 'end2weight'].includes(columnName)) {
				const headerStyle = {
					pointerEvents: 'none',
					backgroundColor: headerBackgroundColor()
				}
				return buildVamSpecificColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					expandSide2,
					'end1weight',
					'end2weight',
					headerStyle,
					onFilterChange
				)
			} else if (['end1ConnectionType', 'end2ConnectionType'].includes(columnName)) {
				const headerStyle = {
					pointerEvents: 'none',
					backgroundColor: headerBackgroundColor(),
					borderRight: columnName === 'end1ConnectionType' && !expandSide2 ? 'solid 1px lightgrey' : ''
				}
				return buildVamSpecificColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					expandSide2,
					'end1ConnectionType',
					'end2ConnectionType',
					headerStyle,
					onFilterChange
				)
			} else if (['end1OuterDiameter', 'end2OuterDiameter'].includes(columnName)) {
				const headerStyle = {
					pointerEvents: 'none',
					backgroundColor: headerBackgroundColor(),
					borderLeft: 'solid 1px lightgrey'
				}
				return buildVamSpecificColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					expandSide2,
					'end1OuterDiameter',
					'end2OuterDiameter',
					headerStyle,
					onFilterChange
				)
			} else if (['end1SteelGrade', 'end2SteelGrade'].includes(columnName)) {
				const headerStyle = {
					pointerEvents: 'none',
					backgroundColor: headerBackgroundColor()
				}
				return buildVamSpecificColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					expandSide2,
					'end1SteelGrade',
					'end2SteelGrade',
					headerStyle,
					onFilterChange
				)
			} else if (['end1SurfaceTreatment', 'end2SurfaceTreatment'].includes(columnName)) {
				return buildSurfaceTreatmentColumn(
					this.dataType,
					columnName,
					t,
					rackState,
					expandSide2,
					onFilterChange,
					headerBackgroundColor!
				)
			}
			return buildNonSpecificColumn(this.dataType, columnName, t, rackState, onFilterChange)
		})
	}
}
