/** @format */

import i18n from '../../../i18n'
import { RackItemInTableModel } from '../../../models/RackItemInTableModel'
import { MeasureUtils } from '../../../utils/MeasureUtils'
import { TFunction } from 'react-i18next'
import { UserState } from '../../../stores/UserStore'
import { ColumnContent } from '../PageDetailStrategy'

export const buildLengthColumn = (
	dataType: string,
	t: TFunction<'translation'>,
	userState: UserState,
	chooseUnitLengthMmtoFt: string | undefined
): ColumnContent => {
	return {
		title:
			i18n.language === 'en'
				? t(dataType + '-data.length_en.name') + chooseUnitLengthMmtoFt
				: t(dataType + '-data.length.name') + chooseUnitLengthMmtoFt,
		field: 'length',
		render: (rowData: RackItemInTableModel) => (
			<span>
				{rowData && rowData.length && userState.checkedLength
					? MeasureUtils.convertValueFromMMToFt(rowData.length)
					: rowData.length}
			</span>
		)
	}
}
