
export default class BusinessErrorException extends Error {

    private _resultCode: string;
    private _extraData : any;

    public get resultCode():string {
        return this._resultCode;
    }

    public get extraData():any {
        return this._extraData;
    }

    constructor(resultCode :string, extraData: string) {
        super(resultCode)

        this._resultCode = resultCode;
        this._extraData = extraData
        Object.setPrototypeOf(this, BusinessErrorException.prototype);
    }
}
