/* https://mui.com/material-ui/react-tabs/ */

import { Box, createStyles, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import React from 'react';
import theme from '../../utils/theme';

const useStyles = makeStyles(() =>
  createStyles({
    buttonSide: {
      textAlign: 'center',
      letterSpacing: '0.01px',
      color: theme.colors.white,
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: '16px',
    },
    tabs: {
      float: 'right',
      '& .MuiTabs-indicator': {
        backgroundColor: theme.colors.white,
      },
    },
    box: {
      paddingTop: '16px',
      width: '100%'
    },
    gridCrossover: {
      flex: 1,
      paddingTop: '3%',
    },
    grid: {
      flex: 1,
      paddingTop: '8%',
    }
  })
);


type CardInfoVamProps = {
  globalInfo: Array<LabelValue>;
  infoSide1: Array<LabelValue>;
  infoSide2: Array<LabelValue>;
};

type LabelValue = { label: string, value: string }

const RenderGrid = (labelValue: LabelValue) => {
  return (
    <Grid key={labelValue.label} item>
      <Box fontSize={18} fontWeight='bold' textAlign='center' title={labelValue.value}>{labelValue.value.length > 15 ? labelValue.value.substring(0, 15) + ' ...' : labelValue.value}</Box>
      <Box color='white' textAlign='center'>{labelValue.label}</Box>
    </Grid>
  )
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function generateTabAttribute(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    'opacity': '1'
  };
}

const CardInfoVam = (props: CardInfoVamProps) => {
  const [value, setValue] = React.useState(0);
  const classes = useStyles();

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.box}>
      {props.globalInfo[0].value !== 'CROSSOVER' ? (
        <div>
          <Grid className={classes.grid} justifyContent='space-around' container direction='row' spacing={1}>
            {
              props.globalInfo.map((labelValue: LabelValue) => (
                RenderGrid(labelValue)
              ))}
          </Grid>
        </div>
      ) : (
        <div>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} id='box-tabs'>
            <Tabs className={classes.tabs} value={value} onChange={handleChange} aria-label='basic tabs example'>
              <Tab className={classes.buttonSide} label='Side 1' {...generateTabAttribute(0)} id='SIDE1' />
              <Tab className={classes.buttonSide} label='Side 2' {...generateTabAttribute(1)} id='SIDE2' />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Grid className={classes.gridCrossover} justifyContent='space-around' container direction='row' spacing={1} >
              {
                props.infoSide1.map((labelValue: LabelValue) => (
                  RenderGrid(labelValue)
                ))}
            </Grid>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Grid className={classes.gridCrossover} justifyContent='space-around' container direction='row' spacing={1} >
              {
                props.infoSide2.map((labelValue: LabelValue) => (
                  RenderGrid(labelValue)
                ))}
            </Grid>
          </TabPanel>
        </div>
      )}
    </Box>
  );
}

export default CardInfoVam;



