/** @format */

import { useTranslation } from 'react-i18next'
import { AppContext } from '../stores/AppStore'
import { useContext, useEffect } from 'react'
import { UserContext } from '../stores/UserStore'
import PipeService from '../api/PipeService'
import { toast } from 'react-toastify'
import { OctgPipeModel } from '../models/OctgPipeModel'
import { OctgContext } from '../stores/OctgStore'
import { Box } from '@material-ui/core'
import Card from '../components/common/Card'
import FilterableTable from '../components/common/FilterableTable'
import ColumnsOrdering from '../containers/common/manageColumns/ColumnsOrdering'
import { MeasureUtils } from '../utils/MeasureUtils'

const OctgContent = (props: any) => {
	const { t } = useTranslation()
	const { appState, appDispatch } = useContext(AppContext)
	const { octgState, octgDispatch } = useContext(OctgContext)
	const { userState, userDispatch } = useContext(UserContext)

	useEffect(() => {
		const setPageLoading = (pageLoading: boolean) => {
			appDispatch({ type: 'set_page_loading', pageLoading })
		}

		// enable loader
		setPageLoading(true)

		// init
		appDispatch({
			type: 'set_page_subtitle',
			pageSubTitle:
				userState.user.login.toLocaleLowerCase() === 'pathfinder' ? t('pathfinder_content') : t('OCTG Content')
		})
		octgDispatch({ type: 'init_table' })

		// call service to retrieve pipes
		PipeService.getOCTGPipes()
			.then(data => {
				const array: OctgPipeModel[] = data.traceabilityNAPipeDataList
				octgDispatch({ type: 'set_all_pipe_data', allPipeData: array })
			})
			.finally(() => {
				setPageLoading(false)
			})
			.catch(e => {
				setPageLoading(false)
				if (e.resultCode === 'error.expired.token') {
					userDispatch({ type: 'sign_out' })
				} else {
					toast.error(t(e.message))
				}
			})
	}, [appDispatch, userDispatch, t, octgDispatch, userState.user.login])

	const chooseUnitLengthFtToMm = userState.checkedLength ? t('mm') : t('ft')
	const chooseUnitLengthInchToMm = userState.checkedLength ? t('mm') : t('inch')

	useEffect(() => {
		const updateRackColumns = () => {
			const allKeys = ColumnsOrdering.sections.flatMap(section => {
				if (section.label === 'octg_content') {
					return section.keys.flatMap(key => {
						if (key.keys && key.keys.length > 0) {
							return key.keys
						} else {
							return key
						}
					})
				}
				return []
			})

			const octgColumns: any[] = allKeys.map(key => {
				if (key.label === 'fullLength') {
					return {
						title: t('octg-data.' + key.label + '.name') + chooseUnitLengthFtToMm,
						field: key.label
					}
				} else if (key.label === 'pipeMul') {
					return {
						title: t('octg-data.' + key.label + '.name') + chooseUnitLengthInchToMm,
						field: key.label
					}
				} else {
					return {
						title: t('octg-data.' + key.label + '.name'),
						field: key.label
					}
				}
			})

			octgDispatch({
				type: 'set_octg_columns',
				octgColumns
			})
		}

		updateRackColumns()
	}, [octgDispatch, t, chooseUnitLengthFtToMm, chooseUnitLengthInchToMm])

	useEffect(() => {
		const updataDisplayData = () => {
			const displayedPipeData: any[] = octgState.allPipeData.map(pipe => {
				return {
					...pipe,
					fullLength: userState.checkedLength
						? pipe && pipe.fullLength && MeasureUtils.convertValueFromFtToMM(pipe.fullLength)
						: pipe.fullLength,
					pipeMul: userState.checkedLength
						? pipe && pipe.pipeMul && MeasureUtils.convertValueFromInchToMM(pipe.pipeMul)
						: pipe.pipeMul
				}
			})
			octgDispatch({
				type: 'set_octg_displayed_pipe_data',
				displayedPipeData
			})
		}
		updataDisplayData()
	}, [octgDispatch, octgState.allPipeData, userState.checkedLength])

	return (
		<Box width="100%" height="100%">
			{appState.pageLoading ? (
				<div />
			) : (
				<div id="rack-content">
					<Card alignSelf="stretch" width="calc(100% - 64px)" margin="23px 33px">
						<FilterableTable
							columns={octgState.octgColumns}
							data={octgState.displayedPipeData}
							getCurrentPageNumber={() => octgState.pageNumber}
							setCurrentPageNumber={(pageNumber: number) =>
								octgDispatch({ type: 'set_page_number', pageNumber })
							}
							getCurrentPageSize={() => octgState.pageSize}
							setCurrentPageSize={(pageSize: number) => octgDispatch({ type: 'set_page_size', pageSize })}
							vam={false}
							octg={true}
							slabs={false}
							title={appState.pageSubTitle}
							getTableName={() => 'main_table'}
							displayPagination={true}
						/>
					</Card>
				</div>
			)}
		</Box>
	)
}

export default OctgContent
