/** @format */

import {
	Backdrop,
	Button,
	CircularProgress,
	makeStyles,
	Typography,
	Paper,
	List,
	Grid,
	Card,
	Box
} from '@material-ui/core'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import DataService from '../api/DataService'
import UploadJointDataPopup from '../containers/common/UploadJointDataPopup'
import { UserContext } from '../stores/UserStore'
import { getSocketHeader } from '../api/ApiManager'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import { config } from '../Properties'
import theme from '../utils/theme'
import { AppContext } from '../stores/AppStore'

const FileSaver = require('file-saver')

const useStyles = makeStyles(t => ({
	dropDownArea: {
		margin: '10px'
	},
	backdrop: {
		zIndex: 2,
		color: '#fff'
	},
	button: {
		color: theme.colors.white,
		backgroundColor: theme.colors.primary,
		fontWeight: 'bold',
		marginLeft: '30px'
	},
	title: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		padding: '8px 0px 8px 11px',
		borderLeftWidth: '8px',
		borderLeftStyle: 'solid',
		borderLeftColor: theme.colors.darkBlue,
		textAlign: 'left',
		color: theme.colors.darkBlue,
		height: '16px',
		margin: '21px 0px',
		fontWeight: 'bold'
	}
}))

const UploadJointData = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { userDispatch } = useContext(UserContext)
	const { appDispatch } = useContext(AppContext)

	const [file, setFile] = useState([])
	const [filename, setFilename] = useState('')
	const [openPopup, setOpenPopup] = useState(false)

	const [displayLoading, setDisplayLoading] = useState(false)
	const [displayLogs, setDisplayLogs] = useState(false)
	const [logs, setLogs] = useState('')
	const [fileProcessing, setFileProcessing] = useState(false)

	let stompClient: Stomp.Client

	const handlePopupCancel = () => {
		setOpenPopup(false)
		setFile([])
	}

	const handlePopupLoad = () => {
		uploadJointData((file[0] as any).file.name, (file[0] as any).data)

		setOpenPopup(false)
		setFile([])
	}

	const handleFileAddition = (file: any) => {
		setFile(file)
		setFilename(file[0].file.name)
		setOpenPopup(true)
	}

	const handleDropReject = () => {
		toast.error(t('load_csv_format'), { autoClose: 5000 })
	}

	const uploadJointData = (filename?: string, content?: string) => {
		setDisplayLoading(true)
		setDisplayLogs(false)
		if (filename && content) {
			DataService.uploadData(filename, content)
				.then(response => {
					connectWS(response.socketKey, filename)
					setFileProcessing(true)
				})
				.finally(() => {
					setDisplayLoading(false)
				})
				.catch(e => {
					setDisplayLoading(false)
					if (e.resultCode === undefined) {
						toast.error(t('errors.unknown'), { autoClose: 5000 })
					} else if (e.resultCode === 'error.expired.token') {
						userDispatch({ type: 'sign_out' })
					} else {
						toast.error(t(e.resultCode, { filename: filename }), { autoClose: 5000 })
					}
				})
		} else {
			setDisplayLoading(false)
		}
	}

	const connectWS = (socketKey: string, filename: string) => {
		stompClient = Stomp.over(new SockJS(config.url.FIELD_APP_BACKEND_URL + 'socket/'))
		stompClient.debug = () => {
			// This is intentional
		}
		stompClient.connect(
			getSocketHeader(),
			() => {
				stompClient?.subscribe(
					'/topic/' + socketKey,
					message => {
						console.log(message.body)
						if (message.body === 'SUCCESS') {
							toast.success(t('upload_joint_data_success', { filename: filename }), { autoClose: 5000 })
						} else {
							toast.error(t('upload_joint_data_error', { filename: filename }), { autoClose: 5000 })
							setLogs(message.body)
							setDisplayLogs(true)
						}
						setFileProcessing(false)

						// disconnect web socket
						stompClient.disconnect(() => {
							// This is intentional
						})
					},
					getSocketHeader()
				)
			},
			error => {
				console.log(error)
				setTimeout(connectWS, 2000)
			}
		)
	}

	const resetLogs = () => {
		setDisplayLogs(false)
	}

	const exportLogs = () => {
		const blob = new Blob([logs], { type: 'text/plain' })
		FileSaver.saveAs(blob, 'error_log_report_file_' + filename + '.txt')
	}

	useEffect(() => {
		appDispatch({ type: 'set_page_subtitle', pageSubTitle: t('upload_joint_data') })
	}, [appDispatch, t])

	return (
		<div className={classes.dropDownArea}>
			<Box width="calc(100% - 64px)" height="100%" margin="23px 33px" id="BoxDropZone">
				<Card style={{ borderRadius: '16px' }}>
					<div className={classes.title} id="title-dropzone">
						{t('upload_joint_data')}
					</div>
					<div style={{ padding: '0px 16px 16px 20px' }}>
						<DropzoneAreaBase
							fileObjects={file}
							dropzoneText={t('drag_drop_data_file_to_load')}
							showPreviews={false}
							filesLimit={1}
							showPreviewsInDropzone={false}
							acceptedFiles={['text/csv', 'application/vnd.ms-excel']}
							maxFileSize={100000000}
							showAlerts={[]}
							onAdd={fileObjects => handleFileAddition(fileObjects)}
							onDropRejected={handleDropReject}
						/>

						<UploadJointDataPopup
							open={openPopup}
							filename={filename}
							handleCancel={handlePopupCancel}
							handleLoad={handlePopupLoad}
						/>

						<Backdrop className={classes.backdrop} open={displayLoading}>
							<CircularProgress color="inherit" />
						</Backdrop>

						{fileProcessing && (
							<div style={{ display: 'flex', alignItems: 'center' }}>
								<CircularProgress />
								<Typography variant="h6" gutterBottom style={{ margin: '20px' }}>
									{t('upload_joint_data_file_being_processed')}
								</Typography>
							</div>
						)}

						{displayLogs && (
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								alignItems="flex-start"
								style={{ maxWidth: '100%', marginTop: '30px' }}
								spacing={2}
							>
								<Grid item md={6}>
									<Typography variant="h6" gutterBottom style={{ margin: '20px' }}>
										{t('upload_joint_data_issues', { filename: filename })}
									</Typography>
									<Paper style={{ maxHeight: '45vh', overflow: 'auto' }}>
										<List>
											<Typography variant="h6" gutterBottom style={{ margin: '20px' }}>
												{logs.split('\n').map(line => {
													return <p>{line}</p>
												})}
											</Typography>
										</List>
									</Paper>
								</Grid>

								<Grid item md={3}>
									<Button className={classes.button} onClick={exportLogs} style={{ marginRight: '10px' }}>
										EXPORT LOG REPORT
									</Button>

									<Button className={classes.button} onClick={resetLogs}>
										RESET LOGS
									</Button>
								</Grid>
							</Grid>
						)}
					</div>
				</Card>
			</Box>
		</div>
	)
}

export default UploadJointData
