/** @format */

import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { version } from '../../../package.json'
import Header from '../../components/layout/header/Header'
import { AppContext } from '../../stores/AppStore'
import { UserContext } from '../../stores/UserStore'
import { RackContext as RackStore } from './../../stores/RackStore'
import { RackContext as VamRackStore } from './../../stores/VamRackStore'
import { RackContext as YellowtailRackStore } from './../../stores/YellowtailRackStore'

const AppHeader = () => {
	const { t } = useTranslation()
	const { appState } = useContext(AppContext)
	const { userState, userDispatch } = useContext(UserContext)
	const { rackDispatch } = useContext(RackStore)
	const { vamRackDispatch } = useContext(VamRackStore)
	const { yellowtailRackDispatch } = useContext(YellowtailRackStore)

	useEffect(() => {
		const signOut = () => {
			userDispatch({ type: 'sign_out' })
			rackDispatch({ type: 'log_out' })
			vamRackDispatch({ type: 'log_out' })
			yellowtailRackDispatch({ type: 'log_out' })
		}

		const options = [
			{ key: 'version', label: 'v' + version, onClick: () => {} },
			{
				key: 'sign-out',
				label: t('Sign Out'),
				onClick: signOut
			}
		]
		userDispatch({ type: 'set_settings', settings: options })
	}, [userDispatch, t, rackDispatch, vamRackDispatch, yellowtailRackDispatch])
	return userState.authState === 'SIGNED_IN' ? (
		<Header
			title={appState.pageTitle}
			subtitle={appState.pageSubTitle}
			username={userState.user.login}
			settings={userState.settings}
		/>
	) : null
}

export default AppHeader
