/** @format */

import { Backdrop, Box, Card, CircularProgress, IconButton, makeStyles } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { DropzoneAreaBase } from 'material-ui-dropzone'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import CertificateService from '../api/CertificateService'
import UploadCertificatePopup from '../containers/common/UploadCertificatePopup'
import { AppContext } from '../stores/AppStore'
import { UserContext } from '../stores/UserStore'
import theme from '../utils/theme'

const useStyles = makeStyles({
	dropDownArea: {
		margin: '10px',
		borderRadius: '16px'
	},
	backdrop: {
		zIndex: 2,
		color: '#fff'
	},
	title: {
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		padding: '8px 0px 8px 11px',
		borderLeftWidth: '8px',
		borderLeftStyle: 'solid',
		borderLeftColor: theme.colors.darkBlue,
		textAlign: 'left',
		color: theme.colors.darkBlue,
		height: '16px',
		margin: '21px 0px',
		fontWeight: 'bold'
	},
	backArrowButton: {
		color: theme.colors.white,
		paddingRight: '5px'
	},
	iconButton: {
		letterSpacing: '0.03px',
		color: theme.colors.white,
		background: 'transparent linear-gradient(99deg, #009EDE 0%, #1A2981 100%) 0% 0% no-repeat padding-box',
		borderRadius: '4px',
		fontSize: '16px',
		fontWeight: 'bold',
		padding: '8px 24px',
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
	}
})

const UploadCertificate = () => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { userDispatch } = useContext(UserContext)
	const { appDispatch } = useContext(AppContext)

	const [file, setFile] = useState([])
	const [filename, setFilename] = useState('')
	const [openPopup, setOpenPopup] = useState(false)

	const [displayLoading, setDisplayLoading] = useState(false)

	const history = useHistory();

	const handlePopupCancel = () => {
		setOpenPopup(false)
		setFile([])
	}

	const handlePopupLoad = (marker: string) => {
		const content = (file[0] as any).data.replace('data:application/pdf;base64,', '')
		uploadCertificate(marker, (file[0] as any).file.name, content)

		setOpenPopup(false)
		setFile([])
	}

	const handleFileAddition = (file: any) => {
		setFile(file)
		setFilename(file[0].file.name)
		setOpenPopup(true)
	}

	const handleDropReject = () => {
		toast.error(t('Load pdf format'), { autoClose: 5000 })
	}

	const uploadCertificate = (marker?: string, filename?: string, content?: string) => {
		setDisplayLoading(true)
		if (marker && filename && content) {
			CertificateService.uploadCertificate(marker, filename, content)
				.then(response => {
					console.log(response)
					toast.success(t(response.resultCode, { filename: filename, marker: marker }), { autoClose: 5000 })
				})
				.finally(() => {
					setDisplayLoading(false)
				})
				.catch(e => {
					setDisplayLoading(false)
					if (e.resultCode === 'error.expired.token') {
						userDispatch({ type: 'sign_out' })
					} else {
						toast.error(t(e.resultCode, { filename: filename, marker: marker }), { autoClose: 5000 })
					}
				})
		}
	}

	useEffect(() => {
		appDispatch({ type: 'set_page_subtitle', pageSubTitle: t('Loading of certificate') });
	}, [
		appDispatch,
		t,
	]);

	return (
		<div className={classes.dropDownArea}>
			<IconButton
				id="back-icon"
				className={classes.iconButton}
				onClick={() => {
					history.goBack()
				}}
				data-testid="backButton"
				style={{ paddingLeft: '5px', paddingRight: '10px', margin: '10px 33px' }}
			>
				<div style={{ color: theme.colors.white, display: 'contents' }}>
					<ArrowBackIcon className={classes.backArrowButton} />
					{t('back')}
				</div>
			</IconButton>
			<Box width="calc(100% - 64px)" height="100%" margin="23px 33px" id="BoxDropZone">
				<Card style={{ borderRadius: '16px' }}>
					<div className={classes.title} id="title-dropzone">
						{t('Load certificate')}
					</div>
					<div style={{ padding: '0px 16px 16px 20px' }}>
						<DropzoneAreaBase
							fileObjects={file}
							dropzoneText={t('Drag and drop certificate')}
							showPreviews={false}
							filesLimit={1}
							showPreviewsInDropzone={false}
							acceptedFiles={['application/pdf']}
							maxFileSize={100000000}
							showAlerts={[]}
							onAdd={fileObjects => handleFileAddition(fileObjects)}
							onDropRejected={handleDropReject}
						/>

						<UploadCertificatePopup
							open={openPopup}
							filename={filename}
							handleCancel={handlePopupCancel}
							handleLoad={(marker: string) => handlePopupLoad(marker)}
						/>

						<Backdrop className={classes.backdrop} open={displayLoading}>
							<CircularProgress color="inherit" />
						</Backdrop>
					</div>
				</Card>
			</Box>
		</div>
	)
}

export default UploadCertificate
