import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SpinnerImage from '../../assets/icons/spinner.svg';

const useStyles = makeStyles({
  boxStyle: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  circularProgressStyle: {
    backgroundImage: `url(${SpinnerImage})`,
  },
  circle: {
    stroke: `url(${SpinnerImage})`,
  },
});

export default function LoadingOverlay(props: any) {
  const classes = useStyles();

  return <Box className={classes.boxStyle} display={props.visible ? 'flex' : 'none'}>
    <CircularProgress size={'4rem'} classes={{ circle: classes.circle }} className={classes.circularProgressStyle} />
  </Box>;
}
