/** @format */

import {Button, ClickAwayListener, Divider, Grid, IconButton, Popper, SvgIcon, TextField} from '@material-ui/core'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Clear, FilterList, KeyboardArrowUp } from '@material-ui/icons'
import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'
import Multiselect from '../../components/common/Multiselect'
import { MeasureUtils } from '../../utils/MeasureUtils'
import { TableUtils } from '../../utils/TableUtils'
import theme from '../../utils/theme'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		typography: {
			padding: theme.spacing(2)
		},
		button: {
			color: theme.palette.primary.main
		},
		root: {
			width: 'fit-content',
			border: `2px solid ${theme.palette.divider}`,
			borderRadius: theme.shape.borderRadius,
			backgroundColor: theme.palette.background.paper,
			color: theme.palette.text.secondary,
			'& svg': {
				margin: theme.spacing(1.5)
			},
			'& hr': {
				margin: theme.spacing(0, 0.5)
			}
		},
		multiselects: {
			marginLeft: theme.spacing(1)
		},
		icon: {
			right: 0,
			position: 'absolute',
			transform: 'translateY(-25%)',
			color: '#172983'
		},
		'& .MuiFormControl-root .MuiIconButton-root:hover': {
			backgroundColor: 'white'
		}
	})
)

type SelectedValues = {
	min: string
	max: string
	value: string
	selectedItemValues: string[]
}

const PopoverFilter = (props: any) => {
	const classes = useStyles()

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [selectedValues, setSelectedValues] = useState<SelectedValues>({min: '', max: '', value: '', selectedItemValues: []})
	const { t } = useTranslation()

	const handleOpenPopup = (event: React.MouseEvent<HTMLButtonElement>) => {
		const filteredColumnsMap = props.stateFilteredColumnsMap

		const filteredValue = filteredColumnsMap.get(props.columnField)
		if (filteredValue) {
			setSelectedValues({
				min: filteredValue.min,
				max: filteredValue.max,
				value: filteredValue.value,
				selectedItemValues: filteredValue.selectedValues && filteredValue.selectedValues.length > 0
					? filteredValue.selectedValues.map((val: any) => {
						return {id: val, display: val}
					})
					: []
			})
		} else {
			// If there is not filter for current column in state, then use default values
			setSelectedValues({min: '', max: '', value: '', selectedItemValues: []})
		}
		setAnchorEl(event.currentTarget)
	}

	const handleClosePopup = () => {
		let sign = 0
		sign = props.currentPortal === 'vam' ? -1 : 1
		if(document.getElementsByTagName('th')[props.columnDef.tableData.id + sign] !== null && document.getElementsByTagName('th')[props.columnDef.tableData.id + sign] !== undefined){
			document.getElementsByTagName('th')[props.columnDef.tableData.id + sign].style.color = '#000000'
		}
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? 'simple-popover' : undefined

	const handleClearWithoutApply = () => {
		setSelectedValues({min: '', max: '', value: '', selectedItemValues: []})
	}

	const handleClear = () => {
		handleClearWithoutApply()
		props.onFilterChanged(props.columnDef.tableData.id, { clear: true })
		handleClosePopup()
	}

	const handleApply = () => {
		const {min, max, value, selectedItemValues} = selectedValues
		if (selectedItemValues) {
			const formattedSelectedValues = selectedItemValues.map((select: any) => select.display).join('|')
			props.onFilterChanged(props.columnDef.tableData.id, {
				value: value.length > 0 ? value : '',
				min: min,
				max: max,
				selectedValues: selectedItemValues.length > 0 ? formattedSelectedValues : ''
			})
			handleClosePopup()
		}
	}

	const handleMultiSelectApply = (selectedItemValues: string[]) => {
		setSelectedValues({...selectedValues, selectedItemValues})
	}

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: '100%',
				height: '2vh'
			}}
		>
			<IconButton aria-describedby={id} color="primary" onClick={handleOpenPopup} style={{ color: 'white' }}>
				{open ? (
					<KeyboardArrowUp fontSize="large" style={{ color: theme.colors.darkBlue }} />
				) : props.stateFilteredColumnsMap.get(props.columnField) ? (
					<FilteredIcon style={{ marginTop: '5px', marginBottom: '6px', color: theme.colors.darkBlue }} />
				) : (
					<FilterList style={{ marginTop: '5px', marginBottom: '6px', color: theme.colors.darkBlue }} />
				)}
			</IconButton>
			<Popper
				open={open}
				anchorEl={anchorEl}
				transition
				style={{ zIndex: 99 }}
			>
				<ClickAwayListener onClickAway={handleClosePopup}>
					<Grid container alignItems="center" className={classes.root} style={{ padding: '20px' }}>
						<TextField
							value={selectedValues.value}
							onChange={e => {
								const value = e.target.value
								setSelectedValues({min: '', max: '', value, selectedItemValues: []})
							}}
							id="value"
							label={t('enterValue')}
							style={{ marginBottom: '10px', width: '15vh' }}
						/>

						<IconButton size="small" onClick={handleClearWithoutApply}>
							<Clear />
						</IconButton>

						{!props.isAlphanumeric && <Divider orientation="vertical" flexItem />}

						{!props.isAlphanumeric && (
							<div>
								<TextField
									value={selectedValues.min}
									onChange={e => {
										const min = e.target.value
										setSelectedValues({min, max: selectedValues.max, value: '', selectedItemValues: []})
									}}
									type="number"
									id="min"
									label="Min"
									style={{ marginLeft: '12px', width: '9vh' }}
								/>

								<IconButton
									size="small"
									onClick={() => {
										setSelectedValues({min: '', max: selectedValues.max, value: '', selectedItemValues: []})
									}}
								>
									<Clear />
								</IconButton>

								<TextField
									value={selectedValues.max}
									onChange={e => {
										const max = e.target.value
										setSelectedValues({min: selectedValues.min, max, value: '', selectedItemValues: []})
									}}
									type="number"
									id="max"
									label="Max"
									style={{ marginLeft: '10px', width: '9vh' }}
								/>

								<IconButton
									size="small"
									onClick={() => {
										setSelectedValues({min: selectedValues.min, max: '', value: '', selectedItemValues: []})
									}}
								>
									<Clear />
								</IconButton>
							</div>
						)}

						<Divider orientation="vertical" flexItem />

						<div className={classes.multiselects}>
							<Multiselect
								iconStyle={classes.icon}
								handleApply={handleMultiSelectApply}
								inputLabel={t('selectValues')}
								selectedOptions={selectedValues.selectedItemValues}
								allOptions={
									open
										? getAllOptions(props.stateData, props, props.stateCheckedSettings, props.nameOfColumnToConvert).sort((a: any, b: any) =>
											props.isAlphanumeric ?
												a.display.localeCompare(b.display)
												: parseFloat(a.display) > parseFloat(b.display) ? 1 : -1
										)
										: []
								}
							/>
						</div>

						<IconButton id="clearMultiSelect" size="small" onClick={handleClearWithoutApply}>
							<Clear />
						</IconButton>

						<Divider orientation="vertical" flexItem />

						<Button
							className={classes.button}
							style={{ marginLeft: '10px', marginRight: '10px', fontWeight: 'bold' }}
							onClick={handleClear}
							id='popOver-clear'
						>
							{t('CLEAR')}
						</Button>

						<Button
							className={classes.button}
							style={{ marginRight: '10px', fontWeight: 'bold' }}
							onClick={handleApply}
							id='popOver-apply'
						>
							{t('APPLY')}
						</Button>
					</Grid>
				</ClickAwayListener>
			</Popper>
		</div>
	)
}

function getAllOptions(stateDisplayPipeData: any, props: any, stateCheckedSettings: boolean, nameOfColumnToConvert: string) {
	let data = stateDisplayPipeData.filter((row: any) => TableUtils.isInRack(props.searchedText, row))
	return data
		.map((row: any) => {
			let val = retrieveValueFromStringKey(props.columnDef.field, row)
			if (stateCheckedSettings && nameOfColumnToConvert.includes(props.columnDef.field)) {
				if(props.currentPortal === 'vam') {
					val = MeasureUtils.convertValueFromMMToFt(val)
				} else if (props.currentPortal === 'yellowtail' && nameOfColumnToConvert.includes('length')) {
					val = MeasureUtils.convertValueFromMMToFt(val)
				} else if (nameOfColumnToConvert.includes('weight')) {
					val = MeasureUtils.convertValueFromKgToLbs(val)
				} else {
					val = MeasureUtils.convertValueFromMMToInch(val)
				}
			} else {
				val = String(val)
			}
			return {
				id: val,
				display: val
			}
		})
		.filter(
			(elem: any, index: number, self: any) =>
				index === self.findIndex((t: any) => t.display === elem.display) &&
				elem.display !== 'undefined' &&
				elem.display !== 'null' &&
				elem.display !== '' &&
				elem.display !== 'NaN'
		)
}

export function retrieveValueFromStringKey(key: any, row: any) {
	return row[key]
}

function FilteredIcon(props: any) {
	return (
		<SvgIcon {...props}>
			<path d="M14,12V19.88C14.04,20.18 13.94,20.5 13.71,20.71C13.32,21.1 12.69,21.1 12.3,20.71L10.29,18.7C10.06,18.47 9.96,18.16 10,17.87V12H9.97L4.21,4.62C3.87,4.19 3.95,3.56 4.38,3.22C4.57,3.08 4.78,3 5,3V3H19V3C19.22,3 19.43,3.08 19.62,3.22C20.05,3.56 20.13,4.19 19.79,4.62L14.03,12H14Z" />
		</SvgIcon>
	)
}

export default React.memo(PopoverFilter)
